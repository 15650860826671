import { sortBy } from 'lodash';
import {
  setFirmList,
  setFirmListSize,
  setFirmProfile,
  setFirmsTotalList,
} from '../actions/firmActions';
import {
  getFirmsList,
  getFirmsTotalListRequest,
  getOneFirmByName,
} from '../requests/firmRequests.js';

// eslint-disable-next-line import/prefer-default-export
export const getFirmList = ({ page = 0, size = 5, name = '', clearStore = false }) => {
  return async (dispatch, getState) => {
    const {
      defaultReducer: { firmList },
    } = getState();
    if (firmList[page] && !clearStore) return;

    const { firms, totalSize } = await getFirmsList({ page, size, name });

    let newFirmList = [];

    if (!clearStore) {
      newFirmList = [...firmList];
    }
    newFirmList[page] = firms;
    dispatch(setFirmListSize(totalSize));
    dispatch(setFirmList(newFirmList));
  };
};

export const getFirmByName = (name = '') => {
  return async (dispatch) => {
    const { firm } = await getOneFirmByName(name);
    if (!firm) {
      dispatch(setFirmProfile({}));
      return;
    }
    dispatch(setFirmProfile(firm));
  };
};

export const getFirmsTotalList = () => {
  return async (dispatch) => {
    const { firmsTotalList } = await getFirmsTotalListRequest();
    const sortedFirmsTotalList = sortBy(firmsTotalList, 'orderNum');
    dispatch(setFirmsTotalList(sortedFirmsTotalList));
  };
};
