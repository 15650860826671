import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import Description from '../../reusableComponents/Profile/Description';
import useStyleHelpers from '../../../styles/styleHelpers.js';
import ProfileTextField from '../../reusableComponents/Profile/ProfileTextField';
import Chips from '../../reusableComponents/Profile/Chips';

const Education = ({ profile }) => {
  const { education, lawSchool, graduationYear, advanced_degree } = profile;

  const { padding, margin } = useStyleHelpers();

  return (
    <div className={padding}>
      <Grid container spacing={3} className={margin}>
        <Grid item lg={4} xl={4}>
          <ProfileTextField value={lawSchool} label="Law School" />
        </Grid>
        <Grid item lg={4} xl={4}>
          <ProfileTextField value={graduationYear} label="Graduation year" />
        </Grid>
        <Grid item lg={12} xl={12}>
          <Chips
            title="Advanced Degree"
            arrOfChips={advanced_degree && advanced_degree.split(',')}
          />
        </Grid>
        <Grid item lg={12} xl={12}>
          <Description value={education} title="Education" />
        </Grid>
      </Grid>
    </div>
  );
};

Education.propTypes = {
  profile: PropTypes.objectOf(PropTypes.any),
};

Education.defaultProps = {
  profile: {},
};

export default Education;
