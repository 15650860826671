import React, { useEffect, useState } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { useSelector } from 'react-redux';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import useStyles from '../../../../styles/styles';
import Search from '../../../reusableComponents/Search';
import ChooseFilterType from './ChooseFilterType';
import FiltersList from './FiltersList';
import useStyleHelpers from '../../../../styles/styleHelpers';
import { useStableDispatch } from '../../../../utils/hooks';
import TableLoader from '../../../reusableComponents/TableLoader';
import TableHeader from '../../../reusableComponents/TableHeader';
import { deleteFilter, getFilterList } from '../../../../thunks/settingsThunks';
import MyTablePagination from '../../../reusableComponents/MyTablePagination';
import ConfirmationModal from '../../../reusableComponents/ConfirmationModal';
import notification from '../../../reusableComponents/notification';

const Filters = () => {
  const { toolbar, paper } = useStyles();
  const { justifyContent } = useStyleHelpers();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const dispatch = useStableDispatch();
  const { filterList } = useSelector((store) => store.defaultReducer);
  const [filterType, setFilterType] = useState('all');
  const [open, setOpen] = useState(false);
  const [filterId, setFilterId] = useState(null);

  const [renderFilterList, setRenderFilterList] = useState([]);

  const filterTypeList = [
    {
      id: 1,
      type: 'Jobs',
    },
    {
      id: 2,
      type: 'Attorneys',
    },
  ];

  const handleClickOpen = (id) => {
    setOpen(true);
    setFilterId(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    (async () => {
      if (!filterList.length) {
        setLoading(true);
        await dispatch(getFilterList());
        setLoading(false);
      }
    })();
  }, [dispatch, filterList.length]);

  useEffect(() => {
    setRenderFilterList(filterList);
  }, [filterList]);

  const onSearch = (value) => {
    if (!value) {
      setRenderFilterList(filterList);
    }
    const searchList = filterList.filter(({ name = '' }) =>
      name.toLowerCase().includes(value.toLowerCase())
    );
    if (filterType !== 'all') {
      const filteredSearchList = searchList.filter(({ type }) => {
        return type === filterType;
      });
      return setRenderFilterList(filteredSearchList);
    }
    return setRenderFilterList(searchList);
  };

  const onTypeFiltersLoad = (type) => {
    setFilterType(type);
    if (type === 'all') {
      return setRenderFilterList(filterList);
    }
    const newList = filterList.filter((item) => {
      return item.type === type;
    });
    return setRenderFilterList(newList);
  };

  const onFilterDelete = async () => {
    try {
      await dispatch(deleteFilter(filterId));
      handleClose();
      notification({ message: 'The filter was deleted!', type: 'success' });
    } catch (e) {
      notification({ message: 'Something went wrong!', type: 'error' });
    }
  };

  return (
    <div>
      <Toolbar className={`${toolbar} ${justifyContent}`}>
        <ChooseFilterType onTypeFiltersLoad={onTypeFiltersLoad} filterTypeList={filterTypeList} />
        <Search name="Search by name" onChange={onSearch} />
      </Toolbar>
      <div className={paper}>
        <TableContainer component={Paper}>
          <Table>
            <TableHeader headers={['Filter name', 'Jobs/Attorneys', '', 'Actions']} />
            {loading ? (
              <TableLoader />
            ) : (
              <FiltersList
                handleClickOpen={handleClickOpen}
                page={page}
                rowsPerPage={rowsPerPage}
                filterList={renderFilterList || filterList}
                filterTypeList={filterTypeList}
              />
            )}
            {!!renderFilterList && !!renderFilterList.length && (
              <MyTablePagination
                colSpan={5}
                count={renderFilterList.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onChangePage={setPage}
                onChangeRowsPerPage={(newRowPerPage) => {
                  setRowsPerPage(newRowPerPage);
                  setPage(0);
                }}
              />
            )}
          </Table>
        </TableContainer>
      </div>
      <ConfirmationModal
        title="Are you sure you want to delete this filter?"
        description="The action cannot be canceled"
        cancelButtonText="Cancel"
        confirmButtonText="Ok"
        state={open}
        onCancelAction={handleClose}
        onConfirmAction={onFilterDelete}
      />
    </div>
  );
};

export default Filters;
