import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../styles/theme.js';

const useSearchStyles = makeStyles({
  searchInput: {
    marginTop: -16,
    minWidth: 320,
    '& .Mui-focused': { color: theme.palette.main },
    '& .MuiInput-underline:after': { borderColor: theme.palette.main },
  },
  searchButton: { color: 'inherit' },
});

export default useSearchStyles;
