import React from 'react';
import { TableRow } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import Skeleton from '@material-ui/lab/Skeleton';
import TableBody from '@material-ui/core/TableBody';
import PropTypes from 'prop-types';

const TableLoader = ({ rowsPerPage, cols }) => {
  const rowLength = [];
  rowLength.length = rowsPerPage;
  rowLength.fill(null);

  const colsLength = [];
  colsLength.length = cols;
  colsLength.fill(null);
  return (
    <TableBody>
      {rowLength.map((_, index) => (
        <TableRow key={index}>
          {colsLength.map((__, index2) => (
            <TableCell key={index2}>
              <Skeleton />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};

TableLoader.propTypes = {
  rowsPerPage: PropTypes.number,
  cols: PropTypes.number,
};

TableLoader.defaultProps = {
  rowsPerPage: 5,
  cols: 1,
};

export default TableLoader;
