import { getRequest, postRequest, deleteRequest } from '../services/fetchUtils';

export const getQueueListRequest = (id, phase) => {
  return id && phase && getRequest(`/queueLists/users/${id}/phase/${phase}`);
};

export const getCallsRequest = (listId, userId) => {
  return listId && userId && getRequest(`/calls?list_id=${listId}&userId=${userId}`);
};

export const getCallsPerTodayRequest = (userId) => {
  return userId && getRequest(`/calls/logs/users/${userId}/today/count`);
};
export const getCallsPerWeekRequest = (userId) => {
  return userId && getRequest(`/calls/logs/users/${userId}/week/count`);
};
export const getCallsToAttorneyRequest = (fpId) => {
  return fpId && getRequest(`/calls/logs/attorneys/${fpId}/last7day/count`);
};

export const getLeftCallsRequest = (listId) => {
  return listId && getRequest(`/calls/logs/lists/${listId}/left/count`);
};

export const getCallsStatsByUserRequest = (userId, listId) => {
  return userId && getRequest(`/calls/logs/users/${userId}/stats/${listId}`);
};

export const getInterestedAttorneysListRequest = ({ listId, page = 0, size = 5, name = '' }) => {
  const params = new URLSearchParams();
  // eslint-disable-next-line no-unused-expressions
  name && params.append('name', name);
  return (
    listId &&
    getRequest(`/calls/queues?listId=${listId}&page=${page}&size=${size}&${params.toString()}`)
  );
};

export const getCreatedCallListRequest = ({
  condition,
  queryId,
  page = 0,
  size = 5,
  name = '',
}) => {
  const params = new URLSearchParams();
  // eslint-disable-next-line no-unused-expressions
  name && params.append('name', name);
  return getRequest(
    `/calls/createlist?condition=${condition}&page=${page}&queryId=${queryId}&size=${size}&${params.toString()}`
  );
};

export const getCallListStatsRequest = (listId) => {
  return getRequest(`/calls/createlist/quantity?queryId=${listId}`);
};

export const getQueueListsRequest = (listIsActive, userIsActive, phaseNo) => {
  return getRequest(
    `/queueLists?listIsActive=${listIsActive}&phaseNo=${phaseNo}&userIsActive=${userIsActive}`
  );
};

export const getAttorneyCallsLogRequest = (fpId) => {
  return getRequest(`/calls/logs/attorneys/${fpId}`);
};

export const getNextCallRequest = (fpId) => {
  return getRequest(`calls/nextcallinfo?fpId=${fpId}`);
};

export const saveCallRequest = (body) => {
  return postRequest(`/calls`, body);
};

export const insertQueryRequest = (queryId, queryList) => {
  const params = new URLSearchParams();
  // eslint-disable-next-line no-unused-expressions
  queryId && params.append('queryId', queryId);
  // eslint-disable-next-line no-unused-expressions
  queryList && params.append('queryList', queryList);

  return postRequest(`/calls/createlist/insertquery?${params.toString()}`);
};

export const createCallListRequest = (condition) => {
  const params = new URLSearchParams();
  // eslint-disable-next-line no-unused-expressions
  condition && params.append('where', condition);
  return postRequest(`/calls/createlist/addquery?${params.toString()}`);
};

export const updateCreatedCall = ({ fpId, queryId, value }) => {
  return (
    fpId &&
    queryId &&
    postRequest(`/calls/createlist/updatequery?fpId=${fpId}&queryId=${queryId}&value=${value}`)
  );
};

export const getColdCallersRequest = () => {
  return getRequest(`/calls/coldcallers`);
};

export const deleteColdCallersQueue = (listId) => {
  return deleteRequest(`/calls/queues/{listId}?listId=${listId}`);
};
