import React from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import useStyleHelpers from '../../../../styles/styleHelpers.js';
import useStyles from '../../../../styles/styles.js';

const ProfileTextField = ({ label, value }) => {
  const { marginBottomInput } = useStyleHelpers();
  const { inputFocused } = useStyles();

  return (
    <TextField
      label={label}
      value={value}
      variant="filled"
      fullWidth
      InputProps={{
        readOnly: true,
      }}
      className={`${marginBottomInput} ${inputFocused}`}
    />
  );
};

ProfileTextField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

ProfileTextField.defaultProps = {
  label: '',
  value: '',
};

export default ProfileTextField;
