import React from 'react';
import { TableRow } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';
import NoData from '../../../assets/NoData.svg';
import useStyles from '../../../styles/styles.js';
import useTableStyles from '../../../styles/tableStyles.js';

const TableNoData = ({ colSpan, withError }) => {
  const { grayTitle, errorTitle } = useStyles();
  const { noData, image } = useTableStyles();
  return (
    <TableRow>
      <TableCell colSpan={colSpan} className={noData}>
        <div className={withError?errorTitle:grayTitle}>{withError?"There was an error processing the request":"Sorry, there no data found"}</div>
        <img src={NoData} alt="No Data" className={image} />
      </TableCell>
    </TableRow>
  );
};

TableNoData.propTypes = {
  colSpan: PropTypes.number,
  withError: PropTypes.bool
};

TableNoData.defaultProps = {
  colSpan: 1,
  withError: false
};

export default TableNoData;
