import React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { FormControl } from '@material-ui/core';
import PropTypes from 'prop-types';
import useSearchStyles from '../../../styles/searchStyles';
import useStyles from '../../../styles/styles';

const ChooseQueue = ({ queueList, currentQueueName, handleChange }) => {
  const { fileSelect } = useSearchStyles();
  const { inputFocused } = useStyles();

  const queueListHasOneItem = queueList && queueList.length === 1;

  return (
    <FormControl variant="filled" className={`${fileSelect} ${inputFocused}`}>
      {!!queueList && !!queueList.length && (
        <TextField
          select
          id="queueListSelect"
          disabled={queueListHasOneItem}
          value={currentQueueName}
          onChange={handleChange}
          variant="filled"
        >
          {queueList.map(({ queueName }) => (
            <MenuItem key={queueName} value={queueName}>
              {queueName}
            </MenuItem>
          ))}
        </TextField>
      )}
    </FormControl>
  );
};

ChooseQueue.propTypes = {
  queueList: PropTypes.string,
  currentQueueName: PropTypes.string,
  handleChange: PropTypes.func,
};

ChooseQueue.defaultProps = {
  queueList: [],
  currentQueueName: '',
  handleChange: () => {},
};

export default ChooseQueue;
