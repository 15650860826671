import React from 'react';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import Cross from '../../../assets/icons/buttonIcons/Cross.svg';
import useStyles from '../../../styles/styles.js';
import useStyleHelpers from '../../../styles/styleHelpers.js';

const SaveCancelButtons = ({ onCancel, onSubmit, disabled }) => {
  const { linkButton, button, buttonHeight } = useStyles();
  const { mediumMarginLeft } = useStyleHelpers();

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        className={button}
        onClick={onSubmit}
        disabled={disabled}
      >
        Save
      </Button>
      <Button onClick={onCancel} className={`${linkButton} ${buttonHeight} ${mediumMarginLeft}`}>
        <img src={Cross} alt="cross" />
      </Button>
    </>
  );
};

SaveCancelButtons.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
};

SaveCancelButtons.defaultProps = {
  onCancel: () => {},
  onSubmit: () => {},
  disabled: false,
};

export default SaveCancelButtons;
