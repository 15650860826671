import React from 'react';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import useStyles from '../../../styles/styles';

const ActionsOutlinedButton = ({ title, ...buttonProps }) => {
  const { outlinedButton } = useStyles();

  return (
    <Button
      className={`${outlinedButton}`}
      fullWidth
      variant="outlined"
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...buttonProps}
    >
      {title}
    </Button>
  );
};

ActionsOutlinedButton.propTypes = {
  title: PropTypes.string,
};

ActionsOutlinedButton.defaultProps = {
  title: '',
};

export default ActionsOutlinedButton;
