import React from 'react';
import { TableBody, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';

const FirmsSummaryList = ({ firmList, page }) => {
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  return (
    <TableBody>
      {!!firmList &&
        !!firmList.length &&
        firmList[page] &&
        firmList[page].map(
          ({
            firmName = '',
            city = '',
            compensationAvg = null,
            grossRevenue = null,
            lawyersCnt = null,
            netIncome = null,
            partnersCnt = null,
            profitMargin = null,
            profitPerPartner = null,
            revenuePerLayer = null,
            // Todo
            /* state = null,
            usedInJob = null,
            used_in_att = null,
            headCount = null,
            isActive = 0,
            practiceScope = null, */
          }) => (
            <TableRow key={firmName}>
              <TableCell>{firmName}</TableCell>
              <TableCell>{lawyersCnt}</TableCell>
              <TableCell>{partnersCnt}</TableCell>
              <TableCell>{city}</TableCell>
              <TableCell>{currencyFormatter.format(profitPerPartner)}</TableCell>
              <TableCell>{currencyFormatter.format(grossRevenue)}</TableCell>
              <TableCell />
              <TableCell>{currencyFormatter.format(revenuePerLayer)}</TableCell>
              <TableCell />
              <TableCell>{currencyFormatter.format(compensationAvg)}</TableCell>
              <TableCell>{currencyFormatter.format(netIncome)}</TableCell>
              <TableCell>{`${Math.round(profitMargin * 100)}%`}</TableCell>
            </TableRow>
          )
        )}
    </TableBody>
  );
};

FirmsSummaryList.propTypes = {
  firmList: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
  page: PropTypes.number,
};

FirmsSummaryList.defaultProps = {
  firmList: [],
  page: 0,
};

export default FirmsSummaryList;
