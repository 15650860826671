import React, { useEffect, useState } from 'react';
import { Paper, Table, TableContainer } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import TableHeader from '../../../reusableComponents/TableHeader';
import { useStableDispatch } from '../../../../utils/hooks';
import { getAttorneyCallsLog } from '../../../../thunks/callThunks';
import TableLoader from '../../../reusableComponents/TableLoader';
import CallsInfoTableBody from './CallsInfoTableBody';
import useStyles from '../../../../styles/styles';
import MyTablePagination from '../../../reusableComponents/MyTablePagination';
import NextCall from './NextCall';

const CallsInfo = ({ id }) => {
  const { paper } = useStyles();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const dispatch = useStableDispatch();
  const { attorneyCallsLog } = useSelector((store) => store.callsReducer);

  useEffect(() => {
    (async () => {
      if (!id) return;
      setLoading(true);
      await dispatch(getAttorneyCallsLog(id));
      setLoading(false);
    })();
  }, [dispatch, id]);

  return (
    <div>
      <NextCall fpId={id} />
      <Paper className={paper}>
        <TableContainer component={Paper}>
          <Table>
            <TableHeader headers={['Caller Name', 'Call Time', 'Process Time', 'Result', 'Note']} />
            {loading ? (
              <TableLoader rowsPerPage={rowsPerPage} cols={7} />
            ) : (
              <CallsInfoTableBody
                callsInfoList={attorneyCallsLog}
                page={page}
                rowsPerPage={rowsPerPage}
              />
            )}
            {!!attorneyCallsLog && !!attorneyCallsLog.length && (
              <MyTablePagination
                colSpan={6}
                count={attorneyCallsLog.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onChangePage={setPage}
                onChangeRowsPerPage={(newRowPerPage) => {
                  setRowsPerPage(newRowPerPage);
                  setPage(0);
                }}
              />
            )}
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

CallsInfo.propTypes = {
  id: PropTypes.number,
};

CallsInfo.defaultProps = {
  id: null,
};

export default CallsInfo;
