import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import PropTypes from 'prop-types';
import { TableRow } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import { Done } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import Edit from '../../../../assets/icons/buttonIcons/Edit-black.svg';
import useStyleHelpers from '../../../../styles/styleHelpers';
import useStyles from '../../../../styles/styles';

const AppParamsTableBody = ({ paramsList, editIndex, startEditing, stopEditing, handleChange }) => {
  const { colorBlack } = useStyleHelpers();
  const { inputReadOnly, inputFocused } = useStyles();
  return (
    <TableBody>
      {!!paramsList &&
        !!paramsList.length &&
        paramsList.map(({ paramDescr, paramValue }, index) => {
          const currentlyEditing = editIndex === index;
          return (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow key={`appParam${index}`}>
              {!currentlyEditing ? (
                <>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        startEditing(index);
                      }}
                    >
                      <img src={Edit} alt="edit" />
                    </IconButton>
                  </TableCell>
                  <TableCell>{paramDescr}</TableCell>
                  <TableCell>{paramValue}</TableCell>
                </>
              ) : (
                <>
                  <TableCell>
                    <IconButton
                      className={colorBlack}
                      onClick={() => {
                        stopEditing(index);
                      }}
                    >
                      <Done />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <TextField
                      className={inputReadOnly}
                      name="paramDescr"
                      value={paramDescr}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      className={inputFocused}
                      type="number"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        min: '1',
                      }}
                      name="paramValue"
                      value={paramValue}
                      onChange={(e) => {
                        handleChange(e, index);
                      }}
                    />
                  </TableCell>
                </>
              )}
            </TableRow>
          );
        })}
    </TableBody>
  );
};

AppParamsTableBody.propTypes = {
  paramsList: PropTypes.arrayOf(PropTypes.object),
  editIndex: PropTypes.number,
  startEditing: PropTypes.func,
  stopEditing: PropTypes.func,
  handleChange: PropTypes.func,
};

AppParamsTableBody.defaultProps = {
  paramsList: [],
  editIndex: -1,
  startEditing: () => {},
  stopEditing: () => {},
  handleChange: () => {},
};

export default AppParamsTableBody;
