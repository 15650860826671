import { Builder } from '@shadowdrone/react-awesome-query-builder';
import React from 'react';

const renderBuilder = (props) => {
  return (
    <div className="query-builder-container">
      <div className="query-builder">
        <Builder {...props} />
      </div>
    </div>
  );
};

export default renderBuilder;
