import { getRequest } from '../services/fetchUtils.js';

export const getFirmsList = ({ page = 0, size = 5, name = '' }) => {
  const params = new URLSearchParams();
  params.append('page', page);
  params.append('size', size);
  // eslint-disable-next-line no-unused-expressions
  name && params.append('name', name);

  return getRequest(`/v1/firms/list?${params.toString()}`);
};

export const getOneFirmByName = ({ name = '' }) => {
  const params = new URLSearchParams();
  // eslint-disable-next-line no-unused-expressions
  name && params.append('firm_name', name);
  return getRequest(`v1/firms/names?${params.toString()}`);
};

export const getFirmsTotalListRequest = () => {
  return getRequest(`/v1/firmstotallist`);
};
