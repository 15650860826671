import React, { useCallback, useEffect, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useSearchStyles from '../../../styles/searchStyles.js';
import { useStableDispatch } from '../../../utils/hooks.js';
import { getSavedJobFilter, getSavedJobFiltersList } from '../../../thunks/jobThunks.js';
import {
  getSavedAttorneyFilter,
  getSavedAttorneyFiltersList,
} from '../../../thunks/attorneyThunks.js';
import useStyles from '../../../styles/styles.js';
import { setFilterName, setJobFilterYears, setJobSearchConfig } from '../../../actions/jobActions';
import { DEFAULT_SEARCH_CONFIG } from '../../../constants';
import { setAttorneySearchConfig } from '../../../actions/attorneyActions';

const ChooseFilter = ({ type = 'job' || 'attorney' }) => {
  const { filterSelect } = useSearchStyles();
  const { inputFocused } = useStyles();
  const { jobFilterList, attorneyFilterList, filterName } = useSelector(
    (store) => store.defaultReducer
  );
  const dispatch = useStableDispatch();
  const [list, setList] = useState([]);

  const jobEffect = useCallback(async () => {
    if (jobFilterList) return;
    await dispatch(getSavedJobFiltersList());
  }, [dispatch, jobFilterList]);

  const attorneyEffect = useCallback(async () => {
    if (attorneyFilterList) return;
    await dispatch(getSavedAttorneyFiltersList());
  }, [dispatch, attorneyFilterList]);

  useEffect(() => {
    switch (type) {
      case 'job':
        jobEffect();
        break;
      case 'attorney':
        attorneyEffect();
        break;
      default:
        break;
    }
  }, [type, jobEffect, attorneyEffect]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    type === 'job' && setList(jobFilterList);
    // eslint-disable-next-line no-unused-expressions
    type === 'attorney' && setList(attorneyFilterList);
  }, [type, jobFilterList, attorneyFilterList]);

  const onChange = ({ target: { value } }) => {
    if (!value) {
      dispatch(setFilterName(''));
      dispatch(setJobFilterYears(null));
      // eslint-disable-next-line no-unused-expressions
      type === 'job' && dispatch(setJobSearchConfig(DEFAULT_SEARCH_CONFIG));
      // eslint-disable-next-line no-unused-expressions
      type === 'attorney' && dispatch(setAttorneySearchConfig(DEFAULT_SEARCH_CONFIG));
      return;
    }
    const { jobYears } = list.find(({ name }) => name === value);
    dispatch(setFilterName(value));
    // eslint-disable-next-line no-unused-expressions
    type === 'job' && dispatch(getSavedJobFilter(value, jobYears));
    // eslint-disable-next-line no-unused-expressions
    type === 'attorney' && dispatch(getSavedAttorneyFilter(value));
  };

  return (
    <FormControl variant="filled" className={`${filterSelect} ${inputFocused}`}>
      <InputLabel id="filter">Choose a filter preset</InputLabel>
      <Select
        labelId="demo-simple-select-filled-label"
        id="filter"
        onChange={onChange}
        value={filterName}
      >
        <MenuItem value={null}>
          <em>None</em>
        </MenuItem>
        {!!list &&
          !!list.length &&
          list.map(
            ({ name = '', jobYears }) =>
              !!name && (
                <MenuItem value={name} key={name} years={jobYears}>
                  {name}
                </MenuItem>
              )
          )}
      </Select>
    </FormControl>
  );
};

ChooseFilter.propTypes = {
  type: PropTypes.string,
};

ChooseFilter.defaultProps = {
  type: '',
};

export default ChooseFilter;
