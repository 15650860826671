import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import List from '@material-ui/core/List';
import {
  Checkbox,
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import useStyleHelpers from '../../../styles/styleHelpers';
import useStyles from '../../../styles/styles';
import { useStableDispatch } from '../../../utils/hooks';
import { getQueueLists } from '../../../thunks/callThunks';
import useMakeCallsStyles from '../../../styles/callsStyles';

const PopulatePopUp = ({ state, onCancelAction, onConfirmAction }) => {
  const { colorRed, noPaddingTopBottom } = useStyleHelpers();
  const { dialogTitle } = useStyles();
  const { checkedListItem } = useMakeCallsStyles();

  const dispatch = useStableDispatch();
  const { queueLists } = useSelector((store) => store.callsReducer);
  const [checked, setChecked] = React.useState([]);

  const queueListsIsEmpty = JSON.stringify(queueLists) === '[]';

  useEffect(() => {
    if (queueListsIsEmpty) {
      dispatch(getQueueLists({ listIsActive: 1, userIsActive: 1, phaseNo: 1 }));
    }
  }, [dispatch, queueListsIsEmpty]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleAllToggle = (event) => {
    let newChecked = [];
    if (queueLists && event.target.checked) {
      newChecked = queueLists.map(({ id }) => id);
    }
    setChecked(newChecked);
  };

  return (
    <Dialog
      open={state}
      onClose={onCancelAction}
      aria-labelledby="alert-dialog-title-populate"
      aria-describedby="alert-dialog-description-populate"
    >
      <DialogTitle id="alert-dialog-title-populate" className={dialogTitle}>
        Populate The List
      </DialogTitle>
      <DialogContent>
        {!!queueLists && queueLists.length && (
          <List className={noPaddingTopBottom}>
            <ListItem button onClick={handleAllToggle} className={`${checkedListItem}`}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': 'label' }}
                />
              </ListItemIcon>
              <ListItemText id="label" primary="Select All Callers" />
              <ListItemSecondaryAction />
            </ListItem>
            <Collapse in>
              <List className={noPaddingTopBottom}>
                {queueLists.map(({ id, queueName }) => {
                  const labelId = `checkbox-list-label-${queueName + id}`;
                  return (
                    <ListItem
                      key={queueName + id}
                      role={undefined}
                      button
                      onClick={handleToggle(id)}
                      className={`${checkedListItem}`}
                    >
                      <ListItemIcon>
                        <Checkbox
                          checked={checked.indexOf(id) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={queueName} />
                      <ListItemSecondaryAction />
                    </ListItem>
                  );
                })}
              </List>
            </Collapse>
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelAction} className={`${dialogTitle} ${colorRed}`}>
          CLOSE
        </Button>
        <Button
          onClick={() => {
            onConfirmAction(checked.join());
          }}
          className={`${dialogTitle} ${colorRed}`}
          autoFocus
        >
          POPULATE
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PopulatePopUp.propTypes = {
  state: PropTypes.bool,
  onCancelAction: PropTypes.func,
  onConfirmAction: PropTypes.func,
};

PopulatePopUp.defaultProps = {
  state: false,
  onCancelAction: () => {},
  onConfirmAction: () => {},
};

export default PopulatePopUp;
