import * as _ from 'lodash';
import * as moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const formatFiles = (fileList = []) => {
  if (!fileList.length) return [];

  return _.orderBy(fileList, ['uploadDate'], ['desc']).map(({ uploadDate, ...rest }) => ({
    uploadDate: moment(uploadDate).format('LT L'),
    ...rest,
  }));
};
