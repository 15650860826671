import React from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import { Button, Select } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import useStyleHelpers from '../../../styles/styleHelpers';
import useStyles from '../../../styles/styles';
import callListConditions from './CallListConditions';
import Search from '../../reusableComponents/Search';
import useMakeCallsStyles from '../../../styles/callsStyles';

const CreateCallsToolbar = ({
  disabled,
  condition,
  onConditionChange,
  onSearchSubmitted,
  onPopulatePopUpOpen,
}) => {
  const { outlinedButton, inputFocused } = useStyles();
  const { padding, mediumTextFieldWidth, marginRightAuto, marginRight } = useStyleHelpers();
  const { callSelectInToolbar } = useMakeCallsStyles();

  return (
    <Toolbar className={`${padding}`}>
      <Button
        disabled={disabled}
        onClick={onPopulatePopUpOpen}
        className={`${outlinedButton} ${marginRightAuto}`}
        variant="outlined"
      >
        populate
      </Button>
      <FormControl
        variant="filled"
        className={`${mediumTextFieldWidth} ${inputFocused} ${marginRight} ${callSelectInToolbar}`}
      >
        {!!callListConditions && !!callListConditions.length && (
          <Select id="calls-filter" value={condition} variant="filled" onChange={onConditionChange}>
            {callListConditions.map(({ name = '', code = '' }) => (
              <MenuItem value={code} key={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
      <Search name="Search by name" onSearchSubmitted={onSearchSubmitted} />
    </Toolbar>
  );
};
CreateCallsToolbar.propTypes = {
  condition: PropTypes.string,
  onConditionChange: PropTypes.func,
  onSearchSubmitted: PropTypes.func,
  onPopulatePopUpOpen: PropTypes.func,
  disabled: PropTypes.bool,
};

CreateCallsToolbar.defaultProps = {
  condition: 'ALL',
  onConditionChange: () => {},
  onSearchSubmitted: () => {},
  onPopulatePopUpOpen: () => {},
  disabled: false,
};

export default CreateCallsToolbar;
