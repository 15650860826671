import React from 'react';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import useStyles from '../../../styles/styles.js';
import useMakeCallsStyles from '../../../styles/callsStyles.js';
import useStyleHelpers from '../../../styles/styleHelpers.js';

const NotesBlock = ({ handleNoteChange, noteValue, callLog }) => {
  const { inputFocused } = useStyles();
  const { noteField } = useMakeCallsStyles();
  const { margin, marginTopInput } = useStyleHelpers();

  return (
    <Grid container spacing={2} className={margin}>
      <Grid item lg={12} xl={12} md={12}>
        <TextField
          label="Note"
          placeholder="Type notes..."
          multiline
          variant="filled"
          className={`${noteField} ${inputFocused}`}
          margin="dense"
          value={noteValue}
          onChange={(event) => {
            handleNoteChange(event);
          }}
        />
        <TextField
          label="Previous Note"
          placeholder="Type notes..."
          multiline
          variant="filled"
          className={`${noteField} ${inputFocused} ${marginTopInput}`}
          value={(callLog && callLog.resultComment) || ''}
          margin="dense"
          InputProps={{
            readOnly: true,
          }}
        />
      </Grid>
    </Grid>
  );
};

NotesBlock.propTypes = {
  handleNoteChange: PropTypes.func,
  noteValue: PropTypes.string,
  callLog: PropTypes.objectOf(PropTypes.any),
};

NotesBlock.defaultProps = {
  handleNoteChange: () => {},
  noteValue: '',
  callLog: {},
};

export default NotesBlock;
