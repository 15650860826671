import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import { TableRow } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableLoader from '../../reusableComponents/TableLoader';
import { getColdCallers, removeColdCallersQueue } from '../../../thunks/callThunks';
import NoDataFound from '../../reusableComponents/NoDataFound';
import TableHeader from '../../reusableComponents/TableHeader';
import MainHeader from '../../reusableComponents/TableToolbar';
import useStyles from '../../../styles/styles';
import Button from '@material-ui/core/Button';
import useTableStyles from '../../../styles/tableStyles';

const ColdCallers = () => {
  const dispatch = useDispatch()
  const { content, paper, outlinedButton } = useStyles();
  const { tableTextAlignWithoutFirstChild } = useTableStyles();

  const {coldCallers} = useSelector((store) => store.callsReducer);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await dispatch(getColdCallers())
      setLoading(false)
    })()
  }, [dispatch])

  const deleteQueue = (listId) => {
    dispatch(removeColdCallersQueue(coldCallers, listId))
  }

  return (
    <>   
      {coldCallers.length === 0 
      ? <NoDataFound title={`Cold Caller list is empty`}/> 
      : <main className={content}>
        <MainHeader header="Cold Callers"/>
        <Paper className={paper}>
        <TableContainer component={Paper}>
          <Table className={tableTextAlignWithoutFirstChild}>
            <TableHeader headers={['Cold Caller List', 'Number of records in the queue', '']} />
              {loading ? (
                <TableLoader cols={7} />
              ) : (
                <TableBody>
                  {!!coldCallers &&
                    !!coldCallers.length && coldCallers.map(({ queueName, calls, listId }) => (
                      <TableRow key={listId}>
                        <TableCell>{queueName}</TableCell>
                        <TableCell>{calls}</TableCell>
                        <TableCell>
                          <Button 
                            className={outlinedButton} 
                            fullWidth 
                            variant="outlined" 
                            color="primary"
                            onClick={() => deleteQueue(listId)}
                          >
                            clear queue
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              )}
            </Table>
          </TableContainer>  
        </Paper>
      </main> }
    </>
  )
}

export default ColdCallers
