import React, { useState } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Redirect, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  StyledBackground,
  StyledContainer,
  StyledLogo,
  StyledPaper,
  StyledTypography,
  useSignInStyles,
} from './SignIn.styles';
import SignInForm from './SignInForm';
import { onSignIn } from '../../../thunks/userThunks';
import { useStableDispatch } from '../../../utils/hooks';
import useStyles from '../../../styles/styles.js';
import notification from '../../reusableComponents/notification';

const SignIn = () => {
  const [backdropOpen, setBackdropOpen] = useState(false);

  const dispatch = useStableDispatch();
  const history = useHistory();
  const { backdrop } = useStyles();
  const { margin } = useSignInStyles();

  const onSubmit = async (values, { setSubmitting }) => {
    try {
      setBackdropOpen(true);
      await dispatch(onSignIn(values));

      history.push('/attorneysList');
    } catch ({ message }) {
      notification({ message, type: 'error' });
    } finally {
      setBackdropOpen(false);
      setSubmitting(false);
    }
  };

  const { user } = useSelector((store) => store.defaultReducer);

  if (user && user.token) return <Redirect to="/" />;

  return (
    <StyledBackground>
      <StyledContainer component="main" maxWidth="xs">
        <CssBaseline />
        <StyledPaper>
          <StyledLogo />
          <StyledTypography component="h1" variant="h5" className={margin}>
            Sign in to account
          </StyledTypography>
          <SignInForm onSubmit={onSubmit} />
        </StyledPaper>
      </StyledContainer>
      <Backdrop open={backdropOpen} className={backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </StyledBackground>
  );
};

export default SignIn;
