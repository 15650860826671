import React, { useEffect, useState } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import { useSelector } from 'react-redux';
import useStyles from '../../../styles/styles';
import MyTablePagination from '../../reusableComponents/MyTablePagination';
import MainHeader from '../../reusableComponents/TableToolbar';
import { useStableDispatch } from '../../../utils/hooks.js';
import { getAttorneyList } from '../../../thunks/attorneyThunks.js';
import TableLoader from '../../reusableComponents/TableLoader';
import ResultsTableBody from './ResultsTableBody.js';
import AttorneysHeader from './AttorneysHeader.js';

const AttorneysList = () => {
  const { content, paper } = useStyles();
  const dispatch = useStableDispatch();

  const { attorneyList, attorneyListSize } = useSelector((store) => store.defaultReducer);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const attorneyListIsEmpty = !attorneyList || (attorneyList && !attorneyList.length);

  useEffect(() => {
    (async () => {
      if (attorneyListIsEmpty) {
        setLoading(true);
        await dispatch(getAttorneyList({ page, rowsPerPage, clearStore: false }));
        setLoading(false);
      }
    })();
  }, [dispatch, page, rowsPerPage, attorneyListIsEmpty]);

  const onChangePage = async (newPage) => {
    setLoading(true);
    setPage(newPage);
    await dispatch(
      getAttorneyList({ page: newPage, size: rowsPerPage, name: searchValue, clearStore: false })
    );
    setLoading(false);
  };
  const onChangeRowsPerPage = async (newRowsPerPage, newPage) => {
    setLoading(true);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    await dispatch(
      getAttorneyList({ page: newPage, size: newRowsPerPage, name: searchValue, clearStore: true })
    );
    setLoading(false);
  };

  const onSearchSubmitted = async (value) => {
    setLoading(true);
    setSearchValue(value);
    await dispatch(getAttorneyList({ page, size: rowsPerPage, name: value, clearStore: true }));
    setLoading(false);
  };

  return (
    <main className={content}>
      <MainHeader
        header="Attorneys List"
        searchPlaceholder="Search by name…"
        onSearchSubmitted={onSearchSubmitted}
      />
      <Paper className={paper}>
        <TableContainer component={Paper}>
          <Table>
            <AttorneysHeader />
            {loading ? (
              <TableLoader rowsPerPage={rowsPerPage} cols={7} />
            ) : (
              <ResultsTableBody attorneyList={attorneyList} page={page} />
            )}
            {!!attorneyList && !!attorneyList.length && (
              <MyTablePagination
                colSpan={7}
                count={attorneyListSize}
                page={page}
                rowsPerPage={rowsPerPage}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
              />
            )}
          </Table>
        </TableContainer>
      </Paper>
    </main>
  );
};

export default AttorneysList;
