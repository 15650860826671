import React from 'react';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import PropTypes from 'prop-types';
import useStyleHelpers from '../../../styles/styleHelpers';

const ConfirmationModal = ({
  title,
  description,
  state,
  cancelButtonText,
  confirmButtonText,
  onCancelAction,
  onConfirmAction,
}) => {
  const { colorRed } = useStyleHelpers();

  return (
    <Dialog
      open={state}
      onClose={onCancelAction}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelAction} className={colorRed}>
          {cancelButtonText}
        </Button>
        <Button onClick={onConfirmAction} className={colorRed} autoFocus>
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  cancelButtonText: PropTypes.string,
  confirmButtonText: PropTypes.string,
  state: PropTypes.bool,
  onCancelAction: PropTypes.func,
  onConfirmAction: PropTypes.func,
};

ConfirmationModal.defaultProps = {
  title: 'Confirm the action',
  description: '',
  cancelButtonText: 'Cancel',
  confirmButtonText: 'Ok',
  state: false,
  onCancelAction: () => {},
  onConfirmAction: () => {},
};

export default ConfirmationModal;
