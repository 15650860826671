import React from 'react';
import PropTypes from 'prop-types';
import useStatusBarsStyles from './StatusBars.styles.js';

const StatusBars = ({ status, errLevel }) => {
  const { uploaded, loaded, converted, merged, error } = useStatusBarsStyles();

  if (errLevel > 0) return <div className={error}>Error loading</div>;

  switch (status) {
    case 'U':
      return <div className={uploaded}>Uploaded</div>;
    case 'L':
      return <div className={loaded}>Loaded</div>;
    case 'C':
      return <div className={converted}>Converted</div>;
    case 'M':
      return <div className={merged}>Ready to use</div>;
    case 'A':
      return <div className={merged}>Ready to use</div>;
    default:
      return <div className={uploaded}>Uploaded</div>;
  }
};

StatusBars.propTypes = {
  status: PropTypes.string,
  errLevel: PropTypes.number,
};

StatusBars.defaultProps = {
  status: 'U',
  errLevel: 0,
};

export default StatusBars;
