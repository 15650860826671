import React, { useEffect, useState } from 'react';
import { Table, TableContainer } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import MainHeader from '../../reusableComponents/TableToolbar';
import useStyles from '../../../styles/styles';
import ExportToXlsx from '../../reusableComponents/ExportToXlsx';
import TableHeader from '../../reusableComponents/TableHeader';
import FirmsSummaryList from './FirmsSummaryList';
import { useStableDispatch } from '../../../utils/hooks';
import { getFirmList } from '../../../thunks/firmThunks';
import TableLoader from '../../reusableComponents/TableLoader';
import MyTablePagination from '../../reusableComponents/MyTablePagination';
import useTableStyles from '../../../styles/tableStyles';
import useStyleHelpers from '../../../styles/styleHelpers';
import Sum from '../../../assets/icons/buttonIcons/Sum.svg';

const FirmsSummary = () => {
  const { content, paper, linkButton } = useStyles();
  const { tableTextAlignWithoutFirstChild, boxShadow } = useTableStyles();
  const { flex, justifyContentEnd, startIconMarginRight } = useStyleHelpers();

  const { firmList, firmListSize } = useSelector((store) => store.defaultReducer);
  const dispatch = useStableDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);

  const firmListIsEmpty = (firmList && !firmList.length) || !firmList;

  useEffect(() => {
    (async () => {
      if (firmListIsEmpty) {
        setLoading(true);
        await dispatch(getFirmList({ page, size: rowsPerPage, clearStore: false }));
        setLoading(false);
      }
    })();
  }, [dispatch, firmListIsEmpty, page, rowsPerPage]);

  const onChangePage = async (newPage) => {
    setLoading(true);
    setPage(newPage);
    await dispatch(getFirmList({ page: newPage, size: rowsPerPage, clearStore: false }));
    setLoading(false);
  };
  const onChangeRowsPerPage = async (newRowsPerPage, newPage) => {
    setLoading(true);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    await dispatch(getFirmList({ page: newPage, size: newRowsPerPage, clearStore: true }));
    setLoading(false);
  };

  const onSearchSubmitted = async (value) => {
    setLoading(true);
    await dispatch(getFirmList({ page, size: rowsPerPage, name: value, clearStore: true }));
    setLoading(false);
  };

  return (
    <main className={content}>
      <MainHeader
        header="Firms Summary"
        searchPlaceholder="Search by name"
        onSearch={onSearchSubmitted}
        exportButton={<ExportToXlsx data={firmList.flat()} />}
        totalButton={
          <Link to="/total">
            <Button
              className={`${linkButton} ${startIconMarginRight}`}
              component="span"
              startIcon={<img src={Sum} alt="sum" />}
            >
              view total
            </Button>
          </Link>
        }
      />
      <Paper className={paper}>
        <TableContainer component={Paper} className={boxShadow}>
          <Table className={tableTextAlignWithoutFirstChild}>
            <TableHeader
              headers={[
                'Firm',
                'Lawyers',
                'Partners',
                'Location',
                'Profits Per Equity Partner',
                'Gross Revenues',
                'AL200',
                'Revenue Per Lawyer',
                'RPL Rank',
                'Compensation',
                'Net Income',
                'Profit Margin',
              ]}
            />
            {loading ? (
              <TableLoader rowsPerPage={rowsPerPage} cols={3} />
            ) : (
              <FirmsSummaryList firmList={firmList} page={page} />
            )}
          </Table>
        </TableContainer>
        {!!firmList && !!firmList.length && (
          <div className={`${flex} ${justifyContentEnd}`}>
            <MyTablePagination
              colSpan={8}
              count={firmListSize}
              page={page}
              rowsPerPage={rowsPerPage}
              onChangePage={onChangePage}
              onChangeRowsPerPage={onChangeRowsPerPage}
            />
          </div>
        )}
      </Paper>
    </main>
  );
};

export default FirmsSummary;
