import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { setAuth, setUser } from '../../actions/userActions';
import useStyles from '../../styles/styles';
import { useStableDispatch } from '../../utils/hooks';
import { refreshToken } from '../../thunks/userThunks';

const AuthWrapper = ({ children }) => {
  const dispatch = useStableDispatch();
  const { spin } = useStyles();

  const [pending, setPending] = useState(true);
  const {
    user: { token: userToken, tokenExpiryDate: userTokenExpiryDate },
  } = useSelector((store) => store.defaultReducer);

  const { layout } = useStyles();

  const IsExpired =
    // eslint-disable-next-line new-cap
    userTokenExpiryDate &&
    moment(new Date(userTokenExpiryDate)).subtract(10, 'minutes').toDate() <=
      // eslint-disable-next-line new-cap
      new moment(new Date()).toDate();

  useEffect(() => {
    const userJson = localStorage.user;

    if (!userJson) {
      setPending(false);
      return;
    }

    const { token, userId, role, tokenExpiryDate } = JSON.parse(userJson);

    if (!token) {
      setPending(false);
      return;
    }

    if (IsExpired) {
      dispatch(refreshToken());
    } else {
      setPending(false);
      dispatch(setUser({ token, userId, role, tokenExpiryDate }));
      dispatch(setAuth(true));
    }
  }, [dispatch, userToken, IsExpired]);

  if (pending) return <CircularProgress className={spin} />;

  return <div className={layout}>{children}</div>;
};

AuthWrapper.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
};

AuthWrapper.defaultProps = {
  children: {},
};

export default AuthWrapper;
