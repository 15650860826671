import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../reusableComponents/TabPanel';
import MainHeader from '../../reusableComponents/TableToolbar';
import useStyles from '../../../styles/styles';
import Filters from './Filters';
import ColdCalls from './ColdCalls';

const Settings = () => {
  const { content, tabPanel } = useStyles();

  const [tab, setTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <main className={content}>
      <MainHeader header="Settings" justify="flex-start" />
      <Tabs
        className={tabPanel}
        aria-label="Settings"
        value={tab}
        onChange={handleTabChange}
        centered
      >
        <Tab label="COLD CALLS" />
        <Tab label="FILTERS" />
      </Tabs>
      <TabPanel value={tab} index={1}>
        <Filters />
      </TabPanel>
      <TabPanel value={tab} index={0}>
        <ColdCalls />
      </TabPanel>
    </main>
  );
};

export default Settings;
