import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import useStyles from '../../../styles/styles';
import MainHeader from '../../reusableComponents/TableToolbar';
import useStyleHelpers from '../../../styles/styleHelpers';
import BackButton from '../../reusableComponents/BackButton';
import { useStableDispatch } from '../../../utils/hooks';
import { getAttorneyListByFirmName } from '../../../thunks/attorneyThunks';
import Loader from '../../reusableComponents/Loader';

const FirmEmployees = () => {
  const {
    content,
    attorneyChipBorder,
    boldSimpleString,
    smallSimpleString,
    attorneyChip,
    outlinedButton,
  } = useStyles();

  const {
    padding,
    largestMarginLeft,
    boxShadow,
    fullWidth,
    smallMargin,
    mediumMarginRight,
    mediumButtonWidth,
    searchMargin,
  } = useStyleHelpers();

  const { firmName } = useParams();

  const dispatch = useStableDispatch();
  const { firmAttorneyList } = useSelector((store) => store.defaultReducer);
  const [renderFirmAttorneysList, setRenderFirmAttorneysList] = useState(firmAttorneyList);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (firmAttorneyList && !firmAttorneyList.length) {
        setLoading(true);
        await dispatch(getAttorneyListByFirmName({ name: firmName }));
        setLoading(false);
      }
    })();
    setRenderFirmAttorneysList(firmAttorneyList);
  }, [dispatch, firmAttorneyList, firmName]);

  const onSearch = (value) => {
    const searchList = firmAttorneyList.filter(({ name }) =>
      name.toLowerCase().includes(value.toLowerCase())
    );
    return setRenderFirmAttorneysList(searchList);
  };

  return (
    <main className={content}>
      <div className={searchMargin}>
        <MainHeader
          prefix={<BackButton />}
          justify="flex-start"
          header={`${firmName} employees`}
          searchPlaceholder="Search by name"
          onSearch={onSearch}
        />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div className={`${padding} ${largestMarginLeft}`}>
          <Grid className={`${boxShadow} ${attorneyChipBorder}`} container>
            {!!renderFirmAttorneysList &&
              !!renderFirmAttorneysList.length &&
              renderFirmAttorneysList.map(({ id, name, firm }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid key={`Employee_${index}`} item xs={12} xl={4} lg={4} md={4}>
                  <Chip
                    icon={
                      <div className={fullWidth}>
                        <div>
                          <div className={boldSimpleString}>{name}</div>
                          <div className={smallSimpleString}>{firm}</div>
                        </div>
                        <div className={`${padding} ${smallMargin}`}>
                          <Link
                            to={{
                              pathname: `/attorneysList/${id}`,
                            }}
                          >
                            <Button
                              className={`${outlinedButton} ${mediumButtonWidth}`}
                              fullWidth
                              variant="outlined"
                              color="primary"
                            >
                              View
                            </Button>
                          </Link>
                        </div>
                      </div>
                    }
                    /* eslint-disable-next-line react/no-array-index-key */
                    key={index}
                    className={`${attorneyChip} ${mediumMarginRight} ${fullWidth}`}
                  />
                </Grid>
              ))}
          </Grid>
        </div>
      )}
    </main>
  );
};

export default FirmEmployees;
