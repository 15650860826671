import React, { useState } from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import { ExpandLess, ExpandMore } from '@material-ui/icons';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import ManageAccounts from '../../../assets/icons/menuIcons/ManageAccounts.svg';
import Firms from '../../../assets/icons/menuIcons/Firms.svg';
import Call from '../../../assets/icons/menuIcons/Call.svg';
import Attorneys from '../../../assets/icons/menuIcons/Attorneys.svg';
import JobSearch from '../../../assets/icons/menuIcons/JobSearch.svg';
import AttorneySearch from '../../../assets/icons/menuIcons/AttorneySearch.svg';
import Logout from '../../../assets/icons/menuIcons/Logout.svg';
import Upload from '../../../assets/icons/menuIcons/Upload.svg';
import FirmsList from '../../../assets/icons/menuIcons/FirmsList.svg';
import FirmsSummary from '../../../assets/icons/menuIcons/FirmsSummary.svg';
import Settings from '../../../assets/icons/menuIcons/Settings.svg';
import Interested from '../../../assets/icons/menuIcons/Interested.svg';
import MakeCalls from '../../../assets/icons/menuIcons/MakeCalls.svg';
import JobsFiles from '../../../assets/icons/menuIcons/JobsFiles.svg';
import Jobs from '../../../assets/icons/menuIcons/Jobs.svg';
import DirectionsRun from '../../../assets/icons/menuIcons/DirectionsRun.svg';
import useStyles from '../../../styles/styles';

const MenuList = ({ onLogout }) => {
  const { menuItem, nested } = useStyles();
  const [firmsOpen, setFirmsOpen] = useState(false);
  const [callsOpen, setCallsOpen] = useState(false);
  const [jobsOpen, setJobsOpen] = useState(false);
  const {
    user: { role },
  } = useSelector((store) => store.defaultReducer);

  const isColdCaller = role === 'Cold caller';
  const isAdmin = role === 'Admin'

  const toggleFirms = () => setFirmsOpen(!firmsOpen);
  const toggleCalls = () => setCallsOpen(!callsOpen);
  const toggleJobs = () => setJobsOpen(!jobsOpen);

  return (
    <>
      <Link to="/accountsList" underline="none">
        <ListItem button>
          <ListItemIcon>
            <img src={ManageAccounts} alt="Manage accounts" />
          </ListItemIcon>
          <ListItemText primary="Manage accounts" className={menuItem} />
        </ListItem>
      </Link>

      <ListItem button onClick={toggleFirms}>
        <ListItemIcon>
          <img src={Firms} alt="Firms" />
        </ListItemIcon>
        <ListItemText primary="Firms" className={menuItem} />
        {firmsOpen ? <ExpandLess className={menuItem} /> : <ExpandMore className={menuItem} />}
      </ListItem>
      <Collapse in={firmsOpen} timeout="auto" unmountOnExit>
        <Link to="/firmsList">
          <List component="div" disablePadding>
            <ListItem button className={nested}>
              <ListItemIcon>
                <img src={FirmsList} alt="FirmsList" />
              </ListItemIcon>
              <ListItemText primary="Firms LIST" className={menuItem} />
            </ListItem>
          </List>
        </Link>

        <Link to="/firmsSummary">
          <List component="div" disablePadding>
            <ListItem button className={nested}>
              <ListItemIcon>
                <img src={FirmsSummary} alt="FirmsSummary" />
              </ListItemIcon>
              <ListItemText primary="Firms summary" className={menuItem} />
            </ListItem>
          </List>
        </Link>
      </Collapse>

      <Link to="/attorneysList" underline="none">
        <ListItem button>
          <ListItemIcon>
            <img src={Attorneys} alt="Attorneys" />
          </ListItemIcon>
          <ListItemText primary="Attorneys list" className={menuItem} />
        </ListItem>
      </Link>
      {!isColdCaller && (
        <Link to="/uploadedFiles" underline="none">
          <ListItem button>
            <ListItemIcon>
              <img src={Upload} alt="Upload" />
            </ListItemIcon>
            <ListItemText primary="uploaded files" className={menuItem} />
          </ListItem>
        </Link>
      )}

      <ListItem button onClick={toggleCalls}>
        <ListItemIcon>
          <img src={Call} alt="Call" />
        </ListItemIcon>
        <ListItemText primary="Cold Call" className={menuItem} />
        {callsOpen ? <ExpandLess className={menuItem} /> : <ExpandMore className={menuItem} />}
      </ListItem>
      <Collapse in={callsOpen} timeout="auto" unmountOnExit>
        <Link to="/makeCalls">
          <List component="div" disablePadding>
            <ListItem button className={nested}>
              <ListItemIcon>
                <img src={MakeCalls} alt="MakeCalls" />
              </ListItemIcon>
              <ListItemText primary="Make calls (1 phase)" className={menuItem} />
            </ListItem>
          </List>
        </Link>
        {isAdmin && <Link to="/coldCallers">
          <List component="div" disablePadding>
          <ListItem button className={nested}>
              <ListItemIcon>
                <img src={MakeCalls} alt="ColdCallers" />
              </ListItemIcon>
              <ListItemText primary="Cold callers (Statistic)" className={menuItem} />
            </ListItem>
          </List>
        </Link>}
        {!isColdCaller && (
          <>
            <Link to="/interestedAttorneys">
              <List component="div" disablePadding>
                <ListItem button className={nested}>
                  <ListItemIcon>
                    <img src={Interested} alt="Interested" />
                  </ListItemIcon>
                  <ListItemText primary="Interested (2 phase)" className={menuItem} />
                </ListItem>
              </List>
            </Link>
            <Link to="/attorneysReadyToWork">
              <List component="div" disablePadding>
                <ListItem button className={nested}>
                  <ListItemIcon>
                    <img src={DirectionsRun} alt="ReadyToWork" />
                  </ListItemIcon>
                  <ListItemText primary="Working with" className={menuItem} />
                </ListItem>
              </List>
            </Link>
          </>
        )}
      </Collapse>

      <ListItem button onClick={toggleJobs}>
        <ListItemIcon>
          <img src={Jobs} alt="Jobs" />
        </ListItemIcon>
        <ListItemText primary="Jobs" className={menuItem} />
        {jobsOpen ? <ExpandLess className={menuItem} /> : <ExpandMore className={menuItem} />}
      </ListItem>
      <Collapse in={jobsOpen} timeout="auto" unmountOnExit>
        <Link to="/uploadJobs" underline="none">
          <List component="div" disablePadding>
            <ListItem button className={nested}>
              <ListItemIcon>
                <img src={JobsFiles} alt="JobsFiles" />
              </ListItemIcon>
              <ListItemText primary="Jobs Files" className={menuItem} />
            </ListItem>
          </List>
        </Link>

        <Link to="/jobSearch" underline="none">
          <List component="div" disablePadding>
            <ListItem button className={nested}>
              <ListItemIcon>
                <img src={JobSearch} alt="Search" />
              </ListItemIcon>
              <ListItemText primary="Job search" className={menuItem} />
            </ListItem>
          </List>
        </Link>
      </Collapse>

      {!isColdCaller && (
        <>
          <Link to="/attorneysSearch" underline="none">
            <ListItem button>
              <ListItemIcon>
                <img src={AttorneySearch} alt="AttorneySearch" />
              </ListItemIcon>
              <ListItemText primary="Attorney search" className={menuItem} />
            </ListItem>
          </Link>
          <Link to="/settings" underline="none">
            <ListItem button>
              <ListItemIcon>
                <img src={Settings} alt="Settings" />
              </ListItemIcon>
              <ListItemText primary="Settings" className={menuItem} />
            </ListItem>
          </Link>
        </>
      )}
      <Link to="/signIn" underline="none" onClick={onLogout}>
        <ListItem button>
          <ListItemIcon>
            <img src={Logout} alt="Logout" />
          </ListItemIcon>
          <ListItemText primary="Logout" className={menuItem} />
        </ListItem>
      </Link>
    </>
  );
};

MenuList.propTypes = {
  onLogout: PropTypes.func,
};

MenuList.defaultProps = {
  onLogout: () => {},
};

export default MenuList;
