import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Stick from '../../reusableComponents/Stick';
import useMakeCallsStyles from '../../../styles/callsStyles.js';
import useStyleHelpers from '../../../styles/styleHelpers.js';
import { useStableDispatch } from '../../../utils/hooks';
import { getStatsInformation } from '../../../thunks/callThunks';

const StatsInformation = ({ userId, listId, attorney: { id: fpId } }) => {
  const { row } = useMakeCallsStyles();
  const { padding, mediumMarginTop } = useStyleHelpers();

  const dispatch = useStableDispatch();

  const { callsPerDay, callsPerWeek, callsToAttorney, leftCalls, callsCountInQueue, callsCountActive, callsCountDormant, callsCountDormantByIdle } = useSelector(
    (store) => store.callsReducer
  );

  useEffect(() => {
    if (!userId) return;
    dispatch(getStatsInformation(userId, listId, fpId));
  }, [dispatch, userId, listId, fpId]);

  return (
    <div className={`${padding} ${mediumMarginTop}`}>
      <Grid container spacing={3}>
        <Grid item lg={8} xl={8} className={`${row}`}>
          <Stick title="Calls made today" value={callsPerDay} />

          <Stick title="Calls made this week" value={callsPerWeek} />

          <Stick title="Call to this rec last 7 days" value={callsToAttorney} />
          
          <Stick title="Total #Calls in Queue" value={callsCountInQueue} color="black" />
          <Stick title="Calls left on the list" value={leftCalls} color="black" />
          <Stick title="Active #Calls" value={callsCountActive} color="black" />
          <Stick title="Dormants" value={callsCountDormant} color="black" />
          <Stick title="Dormants by Idle" value={callsCountDormantByIdle} color="black" />
        </Grid>
      </Grid>
    </div>
  );
};

StatsInformation.propTypes = {
  userId: PropTypes.number,
  listId: PropTypes.number,
  attorney: PropTypes.objectOf(PropTypes.any),
};

StatsInformation.defaultProps = {
  userId: null,
  listId: null,
  attorney: {},
};

export default StatsInformation;
