import { makeStyles } from '@material-ui/core/styles';
import theme from './theme.js';

const useSearchStyles = makeStyles({
  query: {
    '& .query-builder-container': {
      width: '100%',
      padding: 10,
    },
    '& div .rule--field>div': {
      minWidth: '207px',
    },
    '& .group': {
      background: '#F0F0F0',
      border: '1px solid #3C3D3C',
    },
    '& .group--children>.group-or-rule-container>.group-or-rule::before, .group--children>.group-or-rule-container>.group-or-rule::after':{
      borderColor: '#d61c0f',
      left: '-20px',
      width: '20px'
    },
    '& .rule--drag-handler': {
      marginLeft:'20px'
    },
    '& .group-or-rule': {
      marginLeft: '15px'
    },
    '& .ant-select': {
      borderColor: theme.palette.main,
    },
    '& .ant-btn-danger:not([disabled]), & .ant-btn-primary:not([disabled]), & .ant-switch-checked': {
      background: theme.palette.main,
      border: '1px solid #D61B0C',
    },
    '& .ant-btn:hover:not([disabled]), & .ant-btn:focus:not([disabled])': {
      color: theme.palette.main,
      borderColor: theme.palette.main,
    },
    '& .ant-btn-primary:focus:not([disabled]), & .ant-btn-primary:hover:not([disabled])': {
      color: theme.palette.white,
      background: theme.palette.main,
      borderColor: theme.palette.main,
    },
    '& .ant-select:hover .ant-select-selector, & .ant-select-focused.ant-select-single .ant-select-selector, & .ant-input:hover, & .ant-input:focus, & .ant-picker-focused , & .ant-input-number:hover, & .ant-input-number-focused': {
      borderColor: theme.palette.main,
      boxShadow: '0 0 0 2px rgba(214, 27, 12, 0.2);',
    },
  },
  topSection: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  filterSelect: { width: 320, marginLeft: theme.spacing[5] },
  fileSelect: { minWidth: 320 },
  bottomSection: {
    marginTop: 'auto',
    display: 'flex',
    padding: theme.spacing[4],
    justifyContent: 'space-between',
  },
  bottomSectionLeftSide: { display: 'flex' },
  bottomSectionLeftNested: {
    display: 'flex',
    flexDirection: 'column',
    marginRight: theme.spacing[3],
  },
  bottomSectionLeftNestedString: {
    display: 'flex',
    alignItems: 'flex-end',
    marginTop: theme.spacing[3],
    '& .MuiTextField-root': { width: 60, marginRight: theme.spacing[2] },
  },
  bottomSectionLeftNestedP: { width: 260 },
  select: {
    minWidth: 260,
    marginRight: theme.spacing[3],
  },
});

export default useSearchStyles;
