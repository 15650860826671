import React from 'react';
import { TableBody, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import { Link } from 'react-router-dom';
import Report from '../../../assets/icons/buttonIcons/Report.svg';
import TableNoData from '../../reusableComponents/TableNoData';
import StatusBars from '../../reusableComponents/StatusBars/StatusBars';

const UploadedFilesList = ({ filteredList, page, rowsPerPage }) => {
  return (
    <TableBody>
      {(!!filteredList &&
        !!filteredList.length &&
        filteredList
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map(({ id, uploadDate, fileName, status, errLevel }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow key={`uploadFile${index}`}>
              <TableCell>{fileName}</TableCell>
              <TableCell>{uploadDate}</TableCell>
              <TableCell>
                <Link
                  to={{
                    pathname: `/logReport/${id}`,
                    state: { name: fileName },
                  }}
                >
                  <img src={Report} alt="report" />
                </Link>
              </TableCell>
              <TableCell>
                <StatusBars status={status} errLevel={errLevel} />
              </TableCell>
            </TableRow>
          ))) || <TableNoData colSpan={3} />}
    </TableBody>
  );
};

UploadedFilesList.propTypes = {
  filteredList: PropTypes.arrayOf(PropTypes.object),
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
};

UploadedFilesList.defaultProps = {
  filteredList: [],
  page: 0,
  rowsPerPage: 5,
};

export default UploadedFilesList;
