import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import ReactDOM from 'react-dom';
import CloseIcon from '@material-ui/icons/Close';
import Alert from '@material-ui/lab/Alert';

const notification = ({ message, type = 'info' || 'error' || 'warning' || 'success' }) => {
  const div = document.createElement('div');
  document.body.appendChild(div);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    ReactDOM.unmountComponentAtNode(div);
    div.remove();
  };

  ReactDOM.render(
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open
      autoHideDuration={5000}
      onClose={handleClose}
    >
      <Alert severity={type}>
        {message}
        <IconButton size="small" aria-label="close" onClick={handleClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Alert>
    </Snackbar>,
    div
  );
};

export default notification;
