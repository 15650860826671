import {
  SET_FIRM_LIST,
  SET_FIRM_LIST_SIZE,
  SET_FIRM_PROFILE,
  SET_FIRMS_TOTAL_LIST,
} from '../constants/reduxConstants';

export const setFirmList = (list) => ({ type: SET_FIRM_LIST, list });

export const setFirmListSize = (size) => ({ type: SET_FIRM_LIST_SIZE, size });

export const setFirmProfile = (profile) => ({ type: SET_FIRM_PROFILE, profile });

export const setFirmsTotalList = (list) => ({
  type: SET_FIRMS_TOTAL_LIST,
  list,
});
