import React, { useEffect, useState } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import { useSelector } from 'react-redux';
import useStyleHelpers from '../../../../styles/styleHelpers';
import TableHeader from '../../../reusableComponents/TableHeader';
import useStyles from '../../../../styles/styles';
import useTableStyles from '../../../../styles/tableStyles';
import { useStableDispatch } from '../../../../utils/hooks';
import TableLoader from '../../../reusableComponents/TableLoader';
import AppParamsTableBody from './AppParamsTableBody';
import {
  getAppParamsList,
  getCallActions,
  getQueueConfigsList,
  updateAppParam,
  updateQueueConfig,
} from '../../../../thunks/settingsThunks';
import QueueConfigsTableBody from './QueueConfigsTableBody';
import { setAppParams, setQueueConfigs } from '../../../../actions/settingsActions';
import notification from '../../../reusableComponents/notification';

const ColdCalls = () => {
  const { padding, mediumMargin } = useStyleHelpers();
  const { paper } = useStyles();
  const { tableHeaderFontBold } = useTableStyles();

  const [loading, setLoading] = useState(false);
  const [numberFieldError, setNumberFieldError] = useState(false);
  const [numberHelperText, setNumberHelperText] = useState('');

  const [editAppParamsIndex, setEditAppParamsIndex] = useState(-1);
  const [editQueueConfigsIndex, setQueueConfigsIndex] = useState(-1);

  const dispatch = useStableDispatch();
  const { appParams, queueConfigs, callActions } = useSelector((store) => store.callsReducer);

  const appParamsIsEmpty = !appParams || !appParams.length;
  const queueConfigsIsEmpty = !queueConfigs || !queueConfigs.length;
  const callActionsIsEmpty = !callActions || !callActions.length;

  useEffect(() => {
    (async () => {
      if (callActionsIsEmpty) {
        await dispatch(getCallActions());
      }
    })();
  }, [dispatch, callActionsIsEmpty]);

  useEffect(() => {
    (async () => {
      if (appParamsIsEmpty) {
        setLoading(true);
        await dispatch(getAppParamsList());
        setLoading(false);
      }
    })();
  }, [dispatch, appParamsIsEmpty]);

  useEffect(() => {
    (async () => {
      if (queueConfigsIsEmpty) {
        setLoading(true);
        await dispatch(getQueueConfigsList());
        setLoading(false);
      }
    })();
  }, [dispatch, queueConfigsIsEmpty]);

  const startAppParamsEditing = (index) => {
    setEditAppParamsIndex(index);
  };

  const startQueueConfigsEditing = (index) => {
    setQueueConfigsIndex(index);
  };

  const onAppParamsEditing = async (index) => {
    const body = appParams[index];
    setEditAppParamsIndex(-1);
    try {
      await dispatch(updateAppParam(body));
      notification({ message: 'The param was updated!', type: 'success' });
    } catch (e) {
      notification({ message: 'Something went wrong!', type: 'error' });
    }
  };

  const onQueueConfigsEditing = async (index) => {
    const body = queueConfigs[index];
    setQueueConfigsIndex(-1);
    await dispatch(updateQueueConfig(body));
  };

  const onAppParamsChange = ({ target: { value, name } }, index) => {
    const newAppParams = appParams.map((item, i) =>
      i === index ? { ...item, [name]: value } : item
    );
    dispatch(setAppParams(newAppParams));
  };

  const validation = (item) => {
    if ((item.takeOutPeriod || item.takeOutPeriod !== '') && item.takeOutInterval < 1) {
      setNumberFieldError(true);
      setNumberHelperText('Choose correct number');
    } else {
      setNumberFieldError(false);
      setNumberHelperText('');
    }
    if (!item.takeOutPeriod) {
      // eslint-disable-next-line no-param-reassign
      item.takeOutInterval = '';
    }
    return item;
  };

  const onQueueConfigsChange = ({ target: { value, name } }, index) => {
    const newQueueConfigs = queueConfigs.map((item, i) =>
      // eslint-disable-next-line no-nested-ternary
      i === index ? validation({ ...item, [name]: value }) : item
    );
    dispatch(setQueueConfigs(newQueueConfigs));
  };

  return (
    <div className={padding}>
      <div className={paper}>
        <div className={`${tableHeaderFontBold} ${mediumMargin}`}>Quotas settings</div>
        <TableContainer component={Paper}>
          <Table>
            <TableHeader headers={['Actions', 'Parameter Description', 'Parameter Value']} />
            {loading ? (
              <TableLoader />
            ) : (
              <AppParamsTableBody
                paramsList={appParams}
                editIndex={editAppParamsIndex}
                startEditing={startAppParamsEditing}
                stopEditing={onAppParamsEditing}
                handleChange={onAppParamsChange}
              />
            )}
          </Table>
        </TableContainer>
        <div className={`${tableHeaderFontBold} ${mediumMargin}`}>Phases settings</div>
        <TableContainer component={Paper}>
          <Table>
            <TableHeader
              headers={[
                'Actions',
                'Phase',
                'Result',
                'Attempt',
                'Action',
                'Take out interval',
                'Take out period',
              ]}
            />
            {loading ? (
              <TableLoader />
            ) : (
              <QueueConfigsTableBody
                queueConfigList={queueConfigs}
                editIndex={editQueueConfigsIndex}
                startEditing={startQueueConfigsEditing}
                stopEditing={onQueueConfigsEditing}
                handleChange={onQueueConfigsChange}
                numberFieldError={numberFieldError}
                numberHelperText={numberHelperText}
                callActions={callActions}
              />
            )}
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default ColdCalls;
