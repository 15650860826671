import { deleteRequest, getRequest, putRequest } from '../services/fetchUtils';

export const getFiltersRequest = () => {
  return getRequest(`/v1/filter/list`);
};

export const getAppParamsRequest = () => {
  return getRequest(`/appParams`);
};

export const getQueueConfigsRequest = () => {
  return getRequest(`/queueConfigs`);
};

export const getCallActionsRequest = () => {
  return getRequest(`/calls/actions`);
};

export const getCallResultsRequest = () => {
  return getRequest(`/calls/results`);
};

export const updateAppParamRequest = ({ paramCode, paramType, paramValue }) => {
  return putRequest(
    `/appParams?paramCode=${paramCode}&paramType=${paramType}&paramValue=${paramValue}`
  );
};

export const updateQueueConfigRequest = ({ id, actionCd, takeOutInterval, takeOutPeriod }) => {
  return putRequest(
    `/queueConfigs?actionCd=${actionCd}&takeOutInterval=${takeOutInterval}&takeOutPeriod=${takeOutPeriod}`,
    id
  );
};

export const deleteFilterRequest = (id) => {
  return deleteRequest(`v1/filter?filterId=${id}`);
};
