import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useSelector } from 'react-redux';
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

import MomentUtils from '@date-io/moment';
import useStyleHelpers from '../../../styles/styleHelpers';
import useStyles from '../../../styles/styles';
import useMakeCallsStyles from '../../../styles/callsStyles';
import { useStableDispatch } from '../../../utils/hooks';
import { getCallResults } from '../../../thunks/settingsThunks';

const ColdCallsPopUp = ({
  resultCode,
  state,
  onCancelAction,
  onConfirmAction,
  attorneyName,
  note,
  handleNoteChange,
}) => {
  const { colorRed, overflowYHidden } = useStyleHelpers();
  const { noteField, popUpNoteHeight } = useMakeCallsStyles();
  const { inputFocused, dialogTitle } = useStyles();

  const [selectedDate, setSelectedDate] = React.useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date.toJSON());
  };

  const dispatch = useStableDispatch();

  const { callResults } = useSelector((store) => store.callsReducer);

  useEffect(() => {
    (async () => {
      if (!callResults || !callResults.length) {
        await dispatch(getCallResults());
      }
    })();
  }, [dispatch, callResults]);

  const callResult = callResults ? callResults.find((item) => item.resultCode === resultCode) : {};

  return (
    <Dialog open={state} onClose={onCancelAction}>
      <DialogTitle id="form-dialog-title" className={dialogTitle}>
        CALL RESULT
      </DialogTitle>
      <DialogContent className={overflowYHidden}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Grid container spacing={3}>
            <Grid item lg={6} xl={6} md={6} sm={12}>
              <TextField
                className={inputFocused}
                autoFocus
                margin="dense"
                id="attorneyName"
                label="Attorney name"
                value={attorneyName}
                type="text"
                variant="filled"
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
              <TextField
                className={inputFocused}
                autoFocus
                margin="dense"
                id="shortNote"
                label="Short Note"
                type="text"
                variant="filled"
                value={callResult ? callResult.resultName : ''}
                fullWidth
                InputProps={{
                  readOnly: true,
                }}
              />
              <KeyboardDatePicker
                disableToolbar
                showTodayButton
                disablePast
                variant="inline"
                inputVariant="filled"
                format="MM/DD/yyyy"
                margin="normal"
                id="date-picker-inline"
                views={['year', 'month', 'date']}
                fullWidth
                minDate={Date.now()}
                label="Choose date"
                value={selectedDate}
                onChange={handleDateChange}
                className={inputFocused}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </Grid>
            <Grid item lg={6} xl={6} md={6} sm={12}>
              <TextField
                margin="dense"
                label="Notes"
                placeholder="Type notes..."
                multiline
                fullWidth
                value={note}
                onChange={(event) => {
                  handleNoteChange(event);
                }}
                variant="filled"
                className={`${noteField} ${inputFocused} ${popUpNoteHeight}`}
              />
              <KeyboardTimePicker
                disableToolbar
                disablePast
                margin="normal"
                id="time-picker"
                variant="inline"
                inputVariant="filled"
                label="Choose time"
                value={selectedDate}
                fullWidth
                className={inputFocused}
                onChange={handleDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change time',
                }}
              />
            </Grid>
          </Grid>
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelAction} className={`${dialogTitle} ${colorRed}`}>
          cancel
        </Button>
        <Button
          onClick={() => {
            onConfirmAction(resultCode, selectedDate);
            onCancelAction();
          }}
          className={`${dialogTitle} ${colorRed}`}
          autoFocus
        >
          save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ColdCallsPopUp.propTypes = {
  state: PropTypes.bool,
  onCancelAction: PropTypes.func,
  onConfirmAction: PropTypes.func,
  handleNoteChange: PropTypes.func,
  attorneyName: PropTypes.string,
  note: PropTypes.string,
  resultCode: PropTypes.string,
};

ColdCallsPopUp.defaultProps = {
  state: false,
  attorneyName: '',
  note: '',
  resultCode: '',
  onCancelAction: () => {},
  onConfirmAction: () => {},
  handleNoteChange: () => {},
};

export default ColdCallsPopUp;
