import React from 'react';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state/index.js';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Info from '../../../assets/icons/buttonIcons/Info.svg';

const TablePopup = ({ content }) => (
  <PopupState variant="popover" popupId="demo-popup-popover">
    {(popupState) => (
      <div>
        <IconButton variant="contained" color="primary" {...bindTrigger(popupState)}>
          <img src={Info} alt="info" />
        </IconButton>
        <Popover
          {...bindPopover(popupState)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box p={2}>
            <Typography>{content}</Typography>
          </Box>
        </Popover>
      </div>
    )}
  </PopupState>
);

TablePopup.propTypes = {
  content: PropTypes.string,
};

TablePopup.defaultProps = {
  content: '',
};

export default TablePopup;
