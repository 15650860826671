import { makeStyles } from '@material-ui/core/styles';

const AccountRoleStyle = makeStyles({
  roleStyle: {
    display: 'table-cell',
    padding: '16px',
    textAlign: 'left',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
});

export default AccountRoleStyle;
