import { setAuth, setUser } from '../actions/userActions';
import { refreshUserToken, signInUser } from '../requests/userRequests.js';

export const onSignIn = (user) => {
  return async (dispatch) => {
    const { code, message, token, role, userId, tokenExpiryDate } = await signInUser(user);
    if (code !== 200 && code !== 201) {
      throw new Error(message);
    }
    localStorage.user = JSON.stringify({ token, role, userId, tokenExpiryDate });
    dispatch(setAuth(true));
    return dispatch(setUser({ token, role, userId, tokenExpiryDate }));
  };
};

export const refreshToken = () => {
  return async (dispatch) => {
    const { token, tokenExpiryDate } = await refreshUserToken();
    const { userId, role } = JSON.parse(localStorage.user);
    localStorage.user = JSON.stringify({ token, role, userId, tokenExpiryDate });
    dispatch(setAuth(true));
    return dispatch(setUser({ token, role, userId, tokenExpiryDate }));
  };
};
