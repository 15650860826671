import Main from '../components/main/Main';
import SignIn from '../components/main/SignIn';
import AccountsList from '../components/main/AccountsList';
import FirmsList from '../components/main/FirmsList';
import AttorneysList from '../components/main/AttorneysList';
import FirmProfile from '../components/main/FirmProfile';
import AttorneyProfile from '../components/main/AttorneyProfile';
import AttorneysSearch from '../components/main/AttorneysSearch';
import JobSearch from '../components/main/JobSearch';
import UploadJobs from '../components/main/UploadJobs';
import JobSearchResults from '../components/main/JobSearchResults';
import AttorneysSearchResults from '../components/main/AttorneysSearchResults';
import MakeCalls from '../components/main/MakeCalls';
import AccountProfile from '../components/main/AccountProfile';
import Settings from '../components/main/Settings';
import UploadedFiles from '../components/main/UploadedFiles';
import LogReport from '../components/main/LogReport';
import FirmsSummary from '../components/main/FirmsSummary';
import FirmEmployees from '../components/main/FirmEmployees';
import FirmsTotal from '../components/main/FirmsTotal';
import InterestedAttorneys from '../components/main/InterestedAttorneys';
import AttorneySearchCreateCalls from '../components/main/AttorneySearchCreateCalls';
import AttorneysReadyToWork from '../components/main/AttorneysReadyToWork';
import ColdCallers from '../components/main/ColdCallers/ColdCallers';

const routes = [
  { path: '/', component: Main, auth: true, exact: true },
  { path: '/settings', component: Settings, auth: true },
  { path: '/total', component: FirmsTotal, auth: true },
  { path: '/accountsList', component: AccountsList, auth: true, exact: true },
  { path: '/addAccount', component: AccountProfile, auth: true },
  { path: '/editAccount', component: AccountProfile, auth: true },
  { path: '/firmsList', component: FirmsList, auth: true, exact: true },
  { path: '/firmsList/:firmName/employees', component: FirmEmployees, auth: true },
  { path: '/firmsList/:firmName', component: FirmProfile, auth: true, exact: true },
  { path: '/firmsSummary', component: FirmsSummary, auth: true },
  { path: '/attorneysList', component: AttorneysList, auth: true, exact: true },
  { path: '/attorneysList/:id', component: AttorneyProfile, auth: true },
  { path: '/attorneysReadyToWork', component: AttorneysReadyToWork, auth: true, exact: true },
  { path: '/interestedAttorneys', component: InterestedAttorneys, auth: true, exact: true },
  { path: '/attorneysSearch', component: AttorneysSearch, auth: true, exact: true },
  { path: '/attorneysSearch/results', component: AttorneysSearchResults, auth: true },
  { path: '/attorneysSearch/createCalls/:id', component: AttorneySearchCreateCalls, auth: true },
  { path: '/uploadedFiles', component: UploadedFiles, auth: true },
  { path: '/logReport/:id', component: LogReport, auth: true },
  { path: '/jobSearch', component: JobSearch, auth: true, exact: true },
  { path: '/jobSearch/results', component: JobSearchResults, auth: true },
  { path: '/makeCalls', component: MakeCalls, auth: true, exact: true },
  { path: '/makeCalls/:phase', component: MakeCalls, auth: true },
  { path: '/coldCallers', component: ColdCallers, auth: true },
  { path: '/uploadJobs', component: UploadJobs, auth: true },
  { path: '/signIn', component: SignIn },
];

export default routes;
