import React from 'react';
import Button from '@material-ui/core/Button';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import * as moment from 'moment';
import Upload from '../../../assets/icons/buttonIcons/Upload.svg';
import useStyles from '../../../styles/styles.js';

const ExportToXlsx = ({ data, fileName, split, splitBy, additionalPage, clientDownloadData, beautifyXlsx, sheetName, titles, label }) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const { linkButton, rotateImg } = useStyles();

  const saveXlsx = () => {
    const groupedData = _.groupBy(data, splitBy);

    const Sheets = {};
    const SheetNames = [];
    if (split) {
      Object.entries(groupedData).forEach(([key, value]) => {
        Sheets[key.slice(0, 30)] = XLSX.utils.json_to_sheet(value);
        if (titles)
          XLSX.utils.sheet_add_aoa(Sheets[key.slice(0, 30)], [titles], { origin: "A1" });
        SheetNames.push(key.slice(0, 30));
      });
    }
    if(sheetName == undefined)
      sheetName = 'data';
    if (!split) {
      Sheets[sheetName] = XLSX.utils.json_to_sheet(data);
      if (titles)
          XLSX.utils.sheet_add_aoa(Sheets[sheetName], [titles], { origin: "A1" });
      SheetNames.push(sheetName);
    }
    if (additionalPage) {
      //console.log("adfditional page")
      //console.log(additionalPage)
      Sheets[additionalPage.title] = XLSX.utils.json_to_sheet(additionalPage.data);
      //console.log("sheets")
      //console.log(Sheets)
      if (additionalPage.titles)
          XLSX.utils.sheet_add_aoa(Sheets[additionalPage.title], [additionalPage.titles], { origin: "A1" });

      SheetNames.push(additionalPage.title);
      
      try{
        if (additionalPage.beautifyXlsx)
        additionalPage.beautifyXlsx(Sheets, SheetNames);
      }
      catch(exA){
        console.log(exA)
      }  
      
    }

    try{
      if (beautifyXlsx)
      beautifyXlsx(Sheets, SheetNames);

    }
    catch(exB){
      console.log(exB)
    }
    
    //console.log("sheets")
    //console.log(SheetNames)
    const excelBuffer = XLSX.write({ Sheets, SheetNames }, { bookType: 'xlsx', type: 'array' });
    const dataToSave = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataToSave, `${fileName}.xlsx`);
  };

  const checkSaveXlsx = () => {
    if (clientDownloadData){
      clientDownloadData()
      .then(({ results }) => {
        if (results == undefined){
          alert("Error while downloading Data")
          return;
        }
        if (results.length == 0)
          return;
        data = results;
        saveXlsx();
      });
      return;
    }
    saveXlsx();
  };
  return (
    <Button
      className={`${linkButton} ${rotateImg}`}
      component="span"
      onClick={checkSaveXlsx}
      startIcon={<img src={Upload} alt="upload" />}
    >
      {label? label: 'export to excel'}
    </Button>
  );
};

ExportToXlsx.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  fileName: PropTypes.string,
  splitBy: PropTypes.string,
  split: PropTypes.bool,
};

ExportToXlsx.defaultProps = {
  data: [],
  fileName: `Export_${moment().format('L')}`,
  splitBy: '',
  split: false,
};

export default ExportToXlsx;
