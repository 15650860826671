import {
  SET_FILTER_NAME,
  SET_JOB_FILTER_YEARS,
  SET_JOB_FILTERS_LIST,
  SET_JOB_SEARCH_CONFIG,
} from '../constants/reduxConstants.js';

export const setJobSearchConfig = (config) => ({ type: SET_JOB_SEARCH_CONFIG, config });

export const setJobFiltersList = (list) => ({ type: SET_JOB_FILTERS_LIST, list });

export const setFilterName = (name) => ({ type: SET_FILTER_NAME, name });

export const setJobFilterYears = (years) => ({ type: SET_JOB_FILTER_YEARS, years });
