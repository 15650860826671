import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import useSearchStyles from './Search.styles';

const Search = ({ name, onChange, onSearchSubmitted }) => {
  const { searchInput, searchButton } = useSearchStyles();
  const [inputValue, setInputValue] = useState('');

  return (
    <FormControl className={searchInput}>
      <InputLabel htmlFor={name}>{name}</InputLabel>
      <Input
        id={name}
        endAdornment={
          onSearchSubmitted ? (
            <IconButton
              onClick={() => onSearchSubmitted && onSearchSubmitted(inputValue)}
              size="small"
              className={searchButton}
            >
              <SearchIcon />
            </IconButton>
          ) : (
            <SearchIcon />
          )
        }
        value={inputValue}
        onChange={({ target: { value } }) => {
          // eslint-disable-next-line no-unused-expressions
          onChange && onChange(value);
          setInputValue(value);
        }}
        onKeyPress={({ key, target: { value } }) => {
          // eslint-disable-next-line no-unused-expressions
          key === 'Enter' && onSearchSubmitted && onSearchSubmitted(value);
        }}
      />
    </FormControl>
  );
};

Search.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  onSearchSubmitted: PropTypes.func,
};

Search.defaultProps = {
  name: '',
  onChange: undefined,
  onSearchSubmitted: undefined,
};

export default Search;
