import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import { Button, TableRow } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import TableNoData from '../../reusableComponents/TableNoData';
import useStyles from '../../../styles/styles.js';

const ResultsTableBody = ({ filteredList, withError }) => {
  const { outlinedButton } = useStyles();
  return (
    <TableBody>
      {(!!filteredList &&
        !!filteredList.length &&
        filteredList.map(({ email, firm, id, location, name, title, ...profile }, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableRow key={index}>
            <TableCell>{name}</TableCell>
            <TableCell>{firm}</TableCell>
            <TableCell>{title}</TableCell>
            <TableCell>{location}</TableCell>
            <TableCell>
              <Link
                to={{
                  pathname: `/attorneysList/${id}`,
                  profile: { name, title, firm, location, email, ...profile },
                }}
              >
                <Button className={outlinedButton} fullWidth variant="outlined">
                  View
                </Button>
              </Link>
            </TableCell>
          </TableRow>
        ))) || <TableNoData colSpan={6} withError={withError} />}
    </TableBody>
  );
};

ResultsTableBody.propTypes = {
  filteredList: PropTypes.arrayOf(PropTypes.object),
  withError: PropTypes.bool,
};

ResultsTableBody.defaultProps = {
  filteredList: [],
  withError: false,
};

export default ResultsTableBody;
