import React from 'react';
import PropTypes from 'prop-types';
import useStatusBarsStyles from '../../../reusableComponents/StatusBars/StatusBars.styles';
import useStyleHelpers from '../../../../styles/styleHelpers';

const StatusBar = ({ active }) => {
  const { merged, uploaded } = useStatusBarsStyles();
  const { statusWidth } = useStyleHelpers();

  switch (active) {
    case 1:
      return <div className={`${merged} ${statusWidth}`}>Active</div>;
    default:
      return <div className={`${uploaded} ${statusWidth}`}>Inactive</div>;
  }
};

StatusBar.propTypes = {
  active: PropTypes.number,
};

StatusBar.defaultProps = {
  active: 0,
};

export default StatusBar;
