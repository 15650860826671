import React, { useEffect, useState } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import { useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import useStyles from '../../../styles/styles';
import { getFirmList } from '../../../thunks/firmThunks';
import { useStableDispatch } from '../../../utils/hooks';
import MainHeader from '../../reusableComponents/TableToolbar';
import MyTablePagination from '../../reusableComponents/MyTablePagination';
import FirmsListTableBody from './FirmsListTableBody.js';
import TableLoader from '../../reusableComponents/TableLoader';
import TableHeader from '../../reusableComponents/TableHeader';

const FirmsList = () => {
  const { content, paper } = useStyles();

  const { firmList, firmListSize } = useSelector((store) => store.defaultReducer);
  const dispatch = useStableDispatch();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);

  const firmListIsEmpty = (firmList && !firmList.length) || !firmList;

  useEffect(() => {
    (async () => {
      if (firmListIsEmpty) {
        setLoading(true);
        await dispatch(getFirmList({ page, size: rowsPerPage, clearStore: false }));
        setLoading(false);
      }
    })();
  }, [dispatch, firmListIsEmpty, page, rowsPerPage]);

  const onChangePage = async (newPage) => {
    setLoading(true);
    setPage(newPage);
    await dispatch(getFirmList({ page: newPage, size: rowsPerPage, clearStore: false }));
    setLoading(false);
  };
  const onChangeRowsPerPage = async (newRowsPerPage, newPage) => {
    setLoading(true);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    await dispatch(getFirmList({ page: newPage, size: newRowsPerPage, clearStore: true }));
    setLoading(false);
  };

  const onSearchSubmitted = async (value) => {
    setLoading(true);
    await dispatch(getFirmList({ page, size: rowsPerPage, name: value, clearStore: true }));
    setLoading(false);
  };

  return (
    <main className={content}>
      <MainHeader
        header="Firms List"
        searchPlaceholder="Search by name…"
        onSearchSubmitted={onSearchSubmitted}
      />
      <div className={paper}>
        <TableContainer component={Paper}>
          <Table>
            <TableHeader headers={['id', 'Firm Name', '']} />
            {loading ? (
              <TableLoader rowsPerPage={rowsPerPage} cols={3} />
            ) : (
              <FirmsListTableBody firmList={firmList} page={page} />
            )}
            {!!firmList && !!firmList.length && (
              <MyTablePagination
                colSpan={5}
                count={firmListSize}
                page={page}
                rowsPerPage={rowsPerPage}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
              />
            )}
          </Table>
        </TableContainer>
      </div>
    </main>
  );
};

export default FirmsList;
