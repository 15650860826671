import React from 'react';
import PropTypes from 'prop-types';

const TabPanel = ({ children, value, index }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
    >
      {children}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.element,
  value: PropTypes.number,
  index: PropTypes.number,
};

TabPanel.defaultProps = {
  children: undefined,
  value: 0,
  index: 0,
};

export default TabPanel;
