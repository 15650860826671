import React from 'react';
import PropTypes from 'prop-types';
import useStyles from '../../../styles/styles';
import NoData from '../../../assets/NoData.svg';
import useTableStyles from '../../../styles/tableStyles';
import useStyleHelpers from '../../../styles/styleHelpers';

const NoDataFound = ({ title, subTitle }) => {
  const { grayTitle } = useStyles();
  const { padding, marginAuto, textCenter } = useStyleHelpers();
  const { image } = useTableStyles();

  return (
    <div className={`${padding} ${marginAuto} ${textCenter}`}>
      <div className={grayTitle}>{title}</div>
      <div className={grayTitle}>{subTitle}</div>
      <img src={NoData} alt="No Data" className={image} />
    </div>
  );
};

NoDataFound.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
};

NoDataFound.defaultProps = {
  title: '',
  subTitle: '',
};

export default NoDataFound;
