import React from 'react';
import { TableBody, TableRow } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';

const FirmsTotalTableBody = ({ firmsTotalList }) => {
  return (
    <TableBody>
      {!!firmsTotalList &&
        !!firmsTotalList.length &&
        firmsTotalList.map(({ paramName, paramValue }) => (
          <TableRow key={paramName}>
            <TableCell>{paramName}</TableCell>
            <TableCell>{paramValue}</TableCell>
          </TableRow>
        ))}
    </TableBody>
  );
};

FirmsTotalTableBody.propTypes = {
  firmsTotalList: PropTypes.arrayOf(PropTypes.object),
};

FirmsTotalTableBody.defaultProps = {
  firmsTotalList: [],
};

export default FirmsTotalTableBody;
