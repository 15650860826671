import {
  SET_ACCOUNT_LIST,
  SET_ATTORNEY_FILTERS_LIST,
  SET_ATTORNEY_LIST,
  SET_ATTORNEY_LIST_SIZE,
  SET_ATTORNEY_PROFILE,
  SET_ATTORNEY_SEARCH_CONFIG,
  SET_ATTORNEYS_READY_TO_WORK,
  SET_ATTORNEYS_READY_TO_WORK_SIZE,
  SET_AUTH,
  SET_CURRENT_ACCOUNT,
  SET_FILTER_LIST,
  SET_FILTER_LIST_SIZE,
  SET_FILTER_NAME,
  SET_FIRM_ATTORNEY_LIST,
  SET_FIRM_ATTORNEY_LIST_SIZE,
  SET_FIRM_LIST,
  SET_FIRM_LIST_SIZE,
  SET_FIRM_PROFILE,
  SET_FIRMS_TOTAL_LIST,
  SET_JOB_FILTER_YEARS,
  SET_JOB_FILTERS_LIST,
  SET_JOB_SEARCH_CONFIG,
  SET_LOAD_ERROR_LIST,
  SET_ROLE_LIST,
  SET_TEMPLS_LIST,
  SET_USER,
} from '../constants/reduxConstants';
import { DEFAULT_SEARCH_CONFIG } from '../constants';

const initState = {
  user: {},
  firmList: [],
  filterList: [],
  roleList: [],
  loadTemplsList: [],
  firmListSize: 0,
  attorneyList: [],
  firmAttorneyList: [],
  firmAttorneyListSize: 0,
  accountList: [],
  attorneyListSize: 0,
  filterListSize: 0,
  jobSearchConfig: DEFAULT_SEARCH_CONFIG,
  jobFilterList: null,
  attorneySearchConfig: DEFAULT_SEARCH_CONFIG,
  attorneyFilterList: null,
  attorneyProfiles: {},
  firmProfile: {},
  auth: false,
  loadErrorList: [],
  filterName: '',
  jobFilterYears: null,
  firmsTotalList: [],
  attorneysReadyToWork: [],
  attorneysReadyToWorkSize: 0,
  currentAccount: {},
};

const defaultReducer = (
  state = initState,
  { type, user, auth, list, config, size, profile, name, years, account }
) => {
  switch (type) {
    case SET_USER:
      return { ...state, user };

    case SET_FIRM_LIST:
      return { ...state, firmList: list };

    case SET_FIRM_LIST_SIZE:
      return { ...state, firmListSize: size };

    case SET_AUTH:
      return { ...state, auth };

    case SET_ATTORNEY_LIST:
      return { ...state, attorneyList: list };

    case SET_ATTORNEY_LIST_SIZE:
      return { ...state, attorneyListSize: size };

    case SET_FIRM_ATTORNEY_LIST:
      return { ...state, firmAttorneyList: list };

    case SET_FIRM_ATTORNEY_LIST_SIZE:
      return { ...state, firmAttorneyListSize: size };

    case SET_JOB_SEARCH_CONFIG:
      return { ...state, jobSearchConfig: config };

    case SET_ATTORNEY_SEARCH_CONFIG:
      return { ...state, attorneySearchConfig: config };

    case SET_ATTORNEY_FILTERS_LIST:
      return { ...state, attorneyFilterList: list };

    case SET_ATTORNEY_PROFILE:
      return { ...state, attorneyProfiles: profile };

    case SET_JOB_FILTERS_LIST:
      return { ...state, jobFilterList: list };

    case SET_ACCOUNT_LIST:
      return { ...state, accountList: list };

    case SET_ROLE_LIST:
      return { ...state, roleList: list };

    case SET_FILTER_LIST:
      return { ...state, filterList: list };

    case SET_FILTER_LIST_SIZE:
      return { ...state, filterListSize: size };

    case SET_TEMPLS_LIST:
      return { ...state, loadTemplsList: list };

    case SET_LOAD_ERROR_LIST:
      return { ...state, loadErrorList: list };

    case SET_FILTER_NAME:
      return { ...state, filterName: name };

    case SET_JOB_FILTER_YEARS:
      return { ...state, jobFilterYears: years };

    case SET_FIRM_PROFILE:
      return { ...state, firmProfile: profile };

    case SET_FIRMS_TOTAL_LIST:
      return { ...state, firmsTotalList: list };

    case SET_ATTORNEYS_READY_TO_WORK:
      return { ...state, attorneysReadyToWork: list };

    case SET_ATTORNEYS_READY_TO_WORK_SIZE:
      return { ...state, attorneysReadyToWorkSize: size };

    case SET_CURRENT_ACCOUNT:
      return { ...state, currentAccount: account };

    default:
      return state;
  }
};

export default defaultReducer;
