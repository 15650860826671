import React from 'react';
import { Grid } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FilledInput from '@material-ui/core/FilledInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';
import useMakeCallsStyles from '../../../styles/callsStyles.js';
import Copy from '../../../assets/icons/buttonIcons/Copy.svg';
import notification from '../../reusableComponents/notification';
import useStyleHelpers from '../../../styles/styleHelpers.js';
import ProfileTextField from '../../reusableComponents/Profile/ProfileTextField';
import useStyles from '../../../styles/styles.js';

const TextFieldInformation = ({ attorney }) => {
  const { inputTitle } = useMakeCallsStyles();
  const { marginBottomInput } = useStyleHelpers();
  const { inputFocused } = useStyles();

  const { phone, id, location, email, firm, matches } = attorney;

  return (
    <Grid container spacing={3}>
      <Grid item lg={6} xl={6} md={12}>
        <ProfileTextField label="FId" value={id} />
        <ProfileTextField label="Location" value={location || ''} />
        <FormControl fullWidth className={inputFocused}>
          <InputLabel htmlFor="phone" className={`${inputTitle}`}>
            Phone
          </InputLabel>
          <FilledInput
            id="phone"
            value={phone || '-'}
            InputProps={{
              readOnly: true,
            }}
            className={marginBottomInput}
            endAdornment={
              <CopyToClipboard
                text={phone}
                onCopy={() => notification({ message: 'Phone number was copied', type: 'success' })}
              >
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <img src={Copy} alt="copy" />
                  </IconButton>
                </InputAdornment>
              </CopyToClipboard>
            }
          />
        </FormControl>
      </Grid>
      <Grid item lg={6} xl={6} md={12}>
        <ProfileTextField label="Email" value={email || '-'} />
        <ProfileTextField label="Firm" value={firm || '-'} />
        <ProfileTextField label="Matches" value={matches || '-'} />
      </Grid>
    </Grid>
  );
};

TextFieldInformation.propTypes = {
  attorney: PropTypes.objectOf(PropTypes.any),
};

TextFieldInformation.defaultProps = {
  attorney: {},
};

export default TextFieldInformation;
