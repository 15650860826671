import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import { TableRow } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import useStyles from '../../../styles/styles.js';

const FirmsListTableBody = ({ firmList, page }) => {
  const { outlinedButton } = useStyles();

  return (
    <TableBody>
      {!!firmList &&
        !!firmList.length &&
        firmList[page] &&
        firmList[page].map(({ firmName = '', id = '', ...profile }) => (
          // eslint-disable-next-line react/no-array-index-key
          <TableRow key={id}>
            <TableCell>{id}</TableCell>
            <TableCell>{firmName}</TableCell>
            <TableCell>
              <Link
                to={{
                  pathname: `/firmsList/${firmName}`,
                  profile: { firmName, id, ...profile },
                }}
              >
                <Button className={outlinedButton} fullWidth variant="outlined" color="primary">
                  VIEW
                </Button>
              </Link>
            </TableCell>
          </TableRow>
        ))}
    </TableBody>
  );
};

FirmsListTableBody.propTypes = {
  firmList: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
  page: PropTypes.number,
};

FirmsListTableBody.defaultProps = {
  firmList: [],
  page: 0,
};

export default FirmsListTableBody;
