import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import { useSelector } from 'react-redux';
import useStyles from '../../../styles/styles';
import MainHeader from '../../reusableComponents/TableToolbar';
import InterestedToolbar from './InterestedToolbar';
import TableHeader from '../../reusableComponents/TableHeader';
import {
  getInterestedAttorneysCount,
  getInterestedAttorneysList,
  getQueueList,
} from '../../../thunks/callThunks';
import { useStableDispatch } from '../../../utils/hooks';
import NoDataFound from '../../reusableComponents/NoDataFound';
import MyTablePagination from '../../reusableComponents/MyTablePagination';
import TableLoader from '../../reusableComponents/TableLoader';
import InterestedAttorneysResultsTableBody from './InterestedAttorneysResultsTableBody';

const InterestedAttorneys = () => {
  const { content, paper } = useStyles();

  const phase = 2;

  const dispatch = useStableDispatch();
  const {
    user: { userId },
  } = useSelector((store) => store.defaultReducer);
  const {
    currentQueueList,
    interestedAttorneysList,
    interestedAttorneysCount,
    searchInterestedAttorneysCount,
  } = useSelector((store) => store.callsReducer);

  const [currentQueueName, setCurrentQueueName] = useState('');
  const [currentQueue, setCurrentQueue] = useState({});
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const queueListIsEmpty = !currentQueueList.length;

  useEffect(() => {
    (async () => {
      if (!userId) return;
      if (queueListIsEmpty || currentQueueList[0].phaseNo !== phase) {
        await dispatch(getQueueList(userId, phase));
        return;
      }
      setCurrentQueueName(currentQueueList[0].queueName);
      await setCurrentQueue(currentQueueList[0]);
    })();
  }, [dispatch, userId, currentQueueList, queueListIsEmpty, phase]);

  useEffect(() => {
    if (!currentQueue.id) return;
    dispatch(getInterestedAttorneysCount(currentQueue.id));
  }, [dispatch, currentQueue.id]);

  const handleQueueChange = async (event) => {
    setCurrentQueueName(event.target.value);
    const queue = currentQueueList.find(({ queueName }) => queueName === event.target.value);
    setCurrentQueue(queue);
  };

  useEffect(() => {
    (async () => {
      if (!currentQueue.id) return;
      if (!interestedAttorneysCount) {
        setLoading(true);
        await dispatch(
          getInterestedAttorneysList({
            listId: currentQueue.id,
            page,
            rowsPerPage,
            clearStore: true,
          })
        );
        setLoading(false);
      }
    })();
  }, [dispatch, page, rowsPerPage, interestedAttorneysCount, currentQueue.id]);

  const onChangePage = async (newPage) => {
    setLoading(true);
    setPage(newPage);
    await dispatch(
      getInterestedAttorneysList({
        page: newPage,
        size: rowsPerPage,
        listId: currentQueue.id,
        name: searchValue,
        clearStore: false,
      })
    );
    setLoading(false);
  };

  const onChangeRowsPerPage = async (newRowsPerPage, newPage) => {
    setLoading(true);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    await dispatch(
      getInterestedAttorneysList({
        page: newPage,
        size: newRowsPerPage,
        listId: currentQueue.id,
        name: searchValue,
        clearStore: true,
      })
    );
    setLoading(false);
  };

  const onSearchSubmitted = async (value) => {
    setLoading(true);
    setSearchValue(value);
    setPage(0);
    await dispatch(
      getInterestedAttorneysList({
        page: 0,
        size: rowsPerPage,
        listId: currentQueue.id,
        name: value,
        clearStore: true,
      })
    );
    setLoading(false);
  };

  return (
    <main className={content}>
      <MainHeader
        header="Interested Attorneys list"
        searchPlaceholder="Search by name"
        onSearchSubmitted={onSearchSubmitted}
      />
      <InterestedToolbar
        interestedAttorneysCount={interestedAttorneysCount}
        userId={userId}
        currentQueueName={currentQueueName}
        queueList={currentQueueList}
        handleChange={handleQueueChange}
        phase={phase}
      />
      {queueListIsEmpty ? (
        <NoDataFound title="You have no calls assigned" />
      ) : (
        <div className={paper}>
          <TableContainer component={Paper}>
            <Table>
              <TableHeader
                headers={['Name', 'Firm', 'City', 'Last Caller', 'Last Call Date', 'Last note', '']}
              />
              {loading ? (
                <TableLoader rowsPerPage={rowsPerPage} cols={6} />
              ) : (
                <InterestedAttorneysResultsTableBody
                  interestedAttorneysList={interestedAttorneysList}
                  page={page}
                  interestedAttorneysCount={searchInterestedAttorneysCount}
                />
              )}
              {!!searchInterestedAttorneysCount && (
                <MyTablePagination
                  colSpan={8}
                  count={searchInterestedAttorneysCount}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onChangePage={onChangePage}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                />
              )}
            </Table>
          </TableContainer>
        </div>
      )}
    </main>
  );
};

export default InterestedAttorneys;
