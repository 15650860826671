import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { Table, TableContainer } from '@material-ui/core';
import { useSelector } from 'react-redux';
import useStyles from '../../../styles/styles';
import MainHeader from '../../reusableComponents/TableToolbar';
import BackButton from '../../reusableComponents/BackButton';
import TableHeader from '../../reusableComponents/TableHeader';
import { useStableDispatch } from '../../../utils/hooks';
import { getFirmsTotalList } from '../../../thunks/firmThunks';
import TableLoader from '../../reusableComponents/TableLoader';
import FirmsTotalTableBody from './FirmsTotalTableBody';

const FirmsTotal = () => {
  const { content, paper } = useStyles();
  const dispatch = useStableDispatch();
  const { firmsTotalList } = useSelector((store) => store.defaultReducer);
  const [loading, setLoading] = useState(false);

  const firmsTotalListIsEmpty = !firmsTotalList || !firmsTotalList.length;

  useEffect(() => {
    (async () => {
      if (firmsTotalListIsEmpty) {
        setLoading(true);
        await dispatch(getFirmsTotalList());
        setLoading(false);
      }
    })();
  }, [dispatch, firmsTotalListIsEmpty]);

  return (
    <main className={content}>
      <MainHeader prefix={<BackButton />} header="Total" justify="flex-start" />
      <Paper className={paper}>
        <TableContainer component={Paper}>
          <Table>
            <TableHeader headers={['Name', 'Values']} />
            {loading ? <TableLoader /> : <FirmsTotalTableBody firmsTotalList={firmsTotalList} />}
          </Table>
        </TableContainer>
      </Paper>
    </main>
  );
};

export default FirmsTotal;
