import {
  SET_ATTORNEY_FILTERS_LIST,
  SET_ATTORNEY_LIST,
  SET_ATTORNEY_LIST_SIZE,
  SET_ATTORNEY_PROFILE,
  SET_ATTORNEY_SEARCH_CONFIG,
  SET_ATTORNEYS_READY_TO_WORK,
  SET_ATTORNEYS_READY_TO_WORK_SIZE,
  SET_FIRM_ATTORNEY_LIST,
  SET_FIRM_ATTORNEY_LIST_SIZE,
} from '../constants/reduxConstants.js';

export const setAttorneyList = (list) => ({
  type: SET_ATTORNEY_LIST,
  list,
});

export const setFirmAttorneyList = (list) => ({
  type: SET_FIRM_ATTORNEY_LIST,
  list,
});

export const setFirmAttorneyListSize = (size) => ({ type: SET_FIRM_ATTORNEY_LIST_SIZE, size });

export const setAttorneyListSize = (size) => ({ type: SET_ATTORNEY_LIST_SIZE, size });

export const setAttorneySearchConfig = (config) => ({ type: SET_ATTORNEY_SEARCH_CONFIG, config });

export const setAttorneyFiltersList = (list) => ({ type: SET_ATTORNEY_FILTERS_LIST, list });

export const setAttorneyProfile = (profile) => ({ type: SET_ATTORNEY_PROFILE, profile });

export const setAttorneysReadyToWork = (list) => ({ type: SET_ATTORNEYS_READY_TO_WORK, list });

export const setAttorneysReadyToWorkSize = (size) => ({
  type: SET_ATTORNEYS_READY_TO_WORK_SIZE,
  size,
});
