import {
  SET_APP_PARAMS,
  SET_ATTORNEY_CALLS_LOG,
  SET_CALL_ACTIONS,
  SET_CALL_LIST_STATS,
  SET_CALL_RESULTS,
  SET_CALLS_PER_DAY,
  SET_CALLS_PER_WEEK,
  SET_CALLS_TO_ATTORNEY,
  SET_CREATED_CALL_LIST,
  SET_CREATED_CALL_LIST_SIZE,
  SET_CURRENT_CALL,
  SET_CURRENT_QUEUE_LIST,
  SET_INTERESTED_ATTORNEYS_COUNT,
  SET_INTERESTED_ATTORNEYS_LIST,
  SET_LEFT_CALLS,
  SET_NEXT_CALL,
  SET_QUEUE_CONFIG,
  SET_QUEUE_LISTS,
  SET_SEARCH_INTERESTED_ATTORNEYS_COUNT,
  SET_COLD_CALLERS,
  SET_CALLS_COUNT_IN_QUEUE,
  SET_CALLS_COUNT_ACTIVE,
  SET_CALLS_COUNT_DORMANT,
  SET_CALLS_COUNT_DORMANT_BY_IDLE
} from '../constants/reduxConstants';

const initState = {
  callsPerDay: null,
  callsPerWeek: null,
  callsToAttorney: null,
  leftCalls: null,
  interestedAttorneysCount: null,
  searchInterestedAttorneysCount: null,
  createdCallListSize: null,
  appParams: [],
  queueConfigs: [],
  callActions: [],
  callResults: [],
  currentQueueList: [],
  interestedAttorneysList: [],
  createdCallList: [],
  queueLists: [],
  attorneyCallsLog: [],
  currentCall: {},
  callListStats: {},
  nextCall: {},
  coldCallers: [],
  callsCountInQueue: null,
  callsCountActive: null,
  callsCountDormant: null,
  callsCountDormantByIdle: null,
};

const callsReducer = (state = initState, { type, size, list, call, stats }) => {
  switch (type) {
    case SET_APP_PARAMS:
      return { ...state, appParams: list };

    case SET_QUEUE_CONFIG:
      return { ...state, queueConfigs: list };

    case SET_CALL_ACTIONS:
      return { ...state, callActions: list };

    case SET_CALL_RESULTS:
      return { ...state, callResults: list };

    case SET_CALLS_PER_DAY:
      return { ...state, callsPerDay: size };

    case SET_CALLS_PER_WEEK:
      return { ...state, callsPerWeek: size };

    case SET_CALLS_TO_ATTORNEY:
      return { ...state, callsToAttorney: size };

    case SET_LEFT_CALLS:
      return { ...state, leftCalls: size };

    case SET_CURRENT_QUEUE_LIST:
      return { ...state, currentQueueList: list };

    case SET_CURRENT_CALL:
      return { ...state, currentCall: call };

    case SET_INTERESTED_ATTORNEYS_COUNT:
      return { ...state, interestedAttorneysCount: size };

    case SET_SEARCH_INTERESTED_ATTORNEYS_COUNT:
      return { ...state, searchInterestedAttorneysCount: size };

    case SET_INTERESTED_ATTORNEYS_LIST:
      return { ...state, interestedAttorneysList: list };

    case SET_CALL_LIST_STATS:
      return { ...state, callListStats: stats };

    case SET_CREATED_CALL_LIST:
      return { ...state, createdCallList: list };

    case SET_CREATED_CALL_LIST_SIZE:
      return { ...state, createdCallListSize: size };

    case SET_QUEUE_LISTS:
      return { ...state, queueLists: list };

    case SET_ATTORNEY_CALLS_LOG:
      return { ...state, attorneyCallsLog: list };

    case SET_NEXT_CALL:
      return { ...state, nextCall: call };

    case SET_COLD_CALLERS:
      return { ...state, coldCallers: list };

    case SET_CALLS_COUNT_IN_QUEUE:
        return { ...state, callsCountInQueue: size };

    case SET_CALLS_COUNT_ACTIVE:
        return { ...state, callsCountActive: size };
    
    case SET_CALLS_COUNT_DORMANT:
        return { ...state, callsCountDormant: size };

    case SET_CALLS_COUNT_DORMANT_BY_IDLE:
        return { ...state, callsCountDormantByIdle: size };

    default:
      return state;
  }
};

export default callsReducer;
