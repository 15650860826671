import React from 'react';
import { IconButton, TableRow } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import PropTypes from 'prop-types';
import TablePopup from './TablePopup.js';
import TableNoData from '../../reusableComponents/TableNoData';
import Link from '../../../assets/icons/buttonIcons/Link.svg';
import useStyleHelpers from '../../../styles/styleHelpers.js';

const ResultsTableBody = ({ filteredList, page, rowsPerPage }) => {
  const { flex } = useStyleHelpers();

  return (
    <TableBody>
      {(!!filteredList &&
        !!filteredList.length &&
        filteredList
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map(({ fpId, firmName, location, jobTitle, title, jobDesc, jobLink }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow key={index}>
              <TableCell>{fpId}</TableCell>
              <TableCell>{firmName}</TableCell>
              <TableCell>{location}</TableCell>
              <TableCell>{jobTitle}</TableCell>
              <TableCell>{title}</TableCell>
              <TableCell>
                <div className={flex}>
                  <TablePopup content={jobDesc} />
                  <a target="_blank" rel="noopener noreferrer" href={jobLink}>
                    <IconButton>
                      <img src={Link} alt="link" />
                    </IconButton>
                  </a>
                </div>
              </TableCell>
            </TableRow>
          ))) || <TableNoData colSpan={6} />}
    </TableBody>
  );
};

ResultsTableBody.propTypes = {
  filteredList: PropTypes.arrayOf(PropTypes.object),
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
};

ResultsTableBody.defaultProps = {
  filteredList: [],
  page: 0,
  rowsPerPage: 5,
};

export default ResultsTableBody;
