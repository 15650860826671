import React, { useEffect, useState } from 'react';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import { CheckboxWithLabel, Select, TextField } from 'formik-material-ui';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { Link, useHistory, useLocation } from 'react-router-dom';
import FormHelperText from '@material-ui/core/FormHelperText';
import { IconButton } from '@material-ui/core';
import useStyleHelpers from '../../../styles/styleHelpers';
import MainHeader from '../../reusableComponents/TableToolbar';
import useStyles from '../../../styles/styles';
import AccountFormStyles from './AccountFormStyle';
import { getRoleList } from '../../../thunks/roleThunks';
import { useStableDispatch } from '../../../utils/hooks';
import { addAccount, getAccountList, updateAccount } from '../../../thunks/accountThunks';
import notification from '../../reusableComponents/notification';
import Back from '../../../assets/icons/buttonIcons/Back.svg';
import Edit from '../../../assets/icons/buttonIcons/Edit.svg';
import SaveCancelButtons from '../../reusableComponents/SaveCancelButtons';

const AccountProfile = () => {
  const emptyAccount = {
    isActive: false,
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    roleId: '',
    userName: '',
  };

  const classes = AccountFormStyles();
  const { button, blackTittle, content, inputFocused, checkedIcon } = useStyles();
  const {
    largeButtonWidth,
    flexDirections,
    alignItems,
    padding,
    largeWidth,
    smallMarginTop,
    mediumMarginTop,
    largeMarginTop,
    largeMarginLeft,
    smallLeftPadding,
    mediumMargin,
    smallMargin,
    fieldErrorMargin,
    flex,
  } = useStyleHelpers();

  const dispatch = useStableDispatch();
  const {
    roleList,
    accountList,
    user: { role, userId },
  } = useSelector((store) => store.defaultReducer);
  const {
    state: { userAccount = {}, token = '' },
  } = useLocation();
  const history = useHistory();
  const isColdCaller = role === 'Cold caller';

  const [editingNote, setEditingNote] = useState(!!userAccount);

  const toggleEditingNote = () => setEditingNote(!editingNote);

  const roleListIsEmpty = !roleList || !roleList.length;
  const accountListIsEmpty = !accountList || !accountList.length;

  useEffect(() => {
    (async () => {
      if (roleListIsEmpty) {
        await dispatch(getRoleList());
      }
    })();
  }, [dispatch, roleListIsEmpty]);

  useEffect(() => {
    (async () => {
      if (accountListIsEmpty) {
        await dispatch(getAccountList());
      }
    })();
  }, [dispatch, accountListIsEmpty]);

  const onFormSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);
    const body = values;
    body.isActive = +values.isActive;
    try {
      await dispatch(addAccount(body));
      await dispatch(getAccountList(userId));
      notification({ message: 'The user was created!', type: 'success' });
      history.push('/accountsList');
    } catch (e) {
      notification({ message: 'Something went wrong!', type: 'error' });
    }
  };

  const onAccountSave = async (values) => {
    const body = values;
    body.isActive = +values.isActive;
    toggleEditingNote();
    try {
      await dispatch(updateAccount(body, token));
      notification({ message: 'The user was updated!', type: 'success' });
      history.push('/accountsList');
    } catch (e) {
      notification({ message: 'Something went wrong!', type: 'error' });
    }
  };

  const isExistingUser = (userName) => {
    return accountList.find((item) => {
      return item.userName === userName;
    });
  };

  const validation = (values) => {
    const errors = {};
    if (!values.userName) {
      errors.userName = 'Required';
    }
    const user = isExistingUser(values.userName);
    if (!userAccount && user) {
      errors.userName = 'The user already exists!';
    }
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,25}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (!values.roleId) {
      errors.roleId = 'Required';
    }
    if (!values.password) {
      errors.password = 'Required';
    }
    return errors;
  };

  return (
    <main className={content}>
      <div className={flex}>
        <div className={`${mediumMarginTop} ${largeMarginLeft}`}>
          <Link to="/accountsList">
            <img src={Back} alt="Back" />
          </Link>
        </div>
        <div>
          <MainHeader
            className={`${flexDirections} ${alignItems}`}
            header={userAccount ? userAccount.userName : 'Create new account'}
          />
          {!userAccount && (
            // eslint-disable-next-line jsx-a11y/label-has-associated-control
            <label className={`${padding} ${blackTittle}`}>
              Create a new user and add them to the platform
            </label>
          )}
          <Formik
            initialValues={userAccount || emptyAccount}
            validate={validation}
            onSubmit={userAccount ? onAccountSave : onFormSubmit}
          >
            {({ submitForm, isSubmitting }) => (
              <Form className={`${classes.root} ${padding}`} noValidate autoComplete="off">
                <div className={largeWidth}>
                  <Field
                    component={TextField}
                    name="userName"
                    label="Username"
                    type="userName"
                    disabled={editingNote}
                    variant="filled"
                    className={inputFocused}
                  />
                  <Field
                    component={TextField}
                    name="email"
                    label="Email"
                    type="email"
                    disabled={editingNote}
                    variant="filled"
                    className={inputFocused}
                  />
                  <Field
                    component={TextField}
                    name="firstName"
                    label="First name"
                    disabled={editingNote}
                    variant="filled"
                    className={inputFocused}
                  />
                  <Field
                    component={TextField}
                    name="lastName"
                    label="Last name"
                    disabled={editingNote}
                    variant="filled"
                    className={inputFocused}
                  />
                  <Field
                    component={TextField}
                    type="password"
                    name="password"
                    label="Password"
                    autoComplete="new-password"
                    disabled={editingNote}
                    variant="filled"
                    className={inputFocused}
                  />
                  <FormControl className={`${largeWidth} ${smallMargin} ${inputFocused}`}>
                    <InputLabel className={smallLeftPadding} htmlFor="roleSelect">
                      Choose a role
                    </InputLabel>
                    <Field
                      component={Select}
                      name="roleId"
                      type="select"
                      disabled={editingNote}
                      inputProps={{
                        id: 'roleSelect',
                        readOnly: isColdCaller,
                      }}
                      variant="filled"
                    >
                      {!!roleList &&
                        !!roleList.length &&
                        roleList.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.roleName}
                          </MenuItem>
                        ))}
                    </Field>
                    <FormHelperText className={fieldErrorMargin} error>
                      <ErrorMessage name="roleId" />
                    </FormHelperText>
                  </FormControl>
                  <FormControl className={checkedIcon}>
                    <Field
                      component={CheckboxWithLabel}
                      name="isActive"
                      Label={{ label: 'Active account' }}
                      disabled={editingNote || isColdCaller}
                      type="checkbox"
                    />
                  </FormControl>
                </div>
                {!userAccount && (
                  <Button
                    className={`${button} ${largeButtonWidth} ${smallMarginTop}`}
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={submitForm}
                  >
                    create new account
                  </Button>
                )}
                {!!userAccount && !editingNote && (
                  <div className={mediumMargin}>
                    <SaveCancelButtons onSubmit={submitForm} onCancel={toggleEditingNote} />
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
        <div className={largeMarginTop}>
          {!!editingNote && (
            <IconButton onClick={toggleEditingNote}>
              <img src={Edit} alt="edit" />
            </IconButton>
          )}
        </div>
      </div>
    </main>
  );
};

export default AccountProfile;
