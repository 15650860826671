import { SET_ACCOUNT_LIST, SET_CURRENT_ACCOUNT } from '../constants/reduxConstants.js';

export const setAccountList = (list) => ({
  type: SET_ACCOUNT_LIST,
  list,
});

export const setCurrentAccount = (account) => ({
  type: SET_CURRENT_ACCOUNT,
  account,
});
