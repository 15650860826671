import React from 'react';
import PropTypes from 'prop-types';
import useStyles from '../../../styles/styles';
import useMakeCallsStyles from '../../../styles/callsStyles';
import useStyleHelpers from '../../../styles/styleHelpers';

const StatsInformation = ({ stats: { allCount, attQueryCount1, attQueryCount0 } }) => {
  const { blackTittle } = useStyles();
  const { simpleRow } = useMakeCallsStyles();
  const { padding, marginRight } = useStyleHelpers();
  const { lastCallMargin } = useMakeCallsStyles();

  return (
    <div className={`${simpleRow} ${padding} ${lastCallMargin}`}>
      <div className={`${blackTittle} ${marginRight}`}>
        <strong>Records on the list:</strong> {allCount || '0'}
      </div>
      <div className={`${blackTittle} ${marginRight}`}>
        <strong>Ready to add:</strong> {attQueryCount1 || '0'}
      </div>
      <div className={`${blackTittle} ${marginRight}`}>
        <strong>Require confirmation:</strong> {attQueryCount0 || '0'}
      </div>
    </div>
  );
};

StatsInformation.propTypes = {
  stats: PropTypes.objectOf(PropTypes.any),
  allCount: PropTypes.number,
  attQueryCount1: PropTypes.number,
  attQueryCount0: PropTypes.number,
};

StatsInformation.defaultProps = {
  stats: {},
  allCount: null,
  attQueryCount1: null,
  attQueryCount0: null,
};

export default StatsInformation;
