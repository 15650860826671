import TablePagination from '@material-ui/core/TablePagination';
import { TableRow } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import TableFooter from '@material-ui/core/TableFooter';

const MyTablePagination = ({
  colSpan,
  count,
  rowsPerPage,
  page,
  expectMoreItems,
  onChangePage,
  onChangeRowsPerPage,
}) => {
  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          colSpan={colSpan}
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
          }}
          onChangePage={(event, newPage) => onChangePage(newPage)}
          onChangeRowsPerPage={({ target: { value } }) => {
            onChangeRowsPerPage(value);
          }}
          labelDisplayedRows={ ({ from, to, count }) => (`${from}-${to === -1 ? count : to} of ${(expectMoreItems ? (`more than `):``) + count}`) }
          // ActionsComponent={console.log}
        />
      </TableRow>
    </TableFooter>
  );
};

MyTablePagination.propTypes = {
  colSpan: PropTypes.number,
  count: PropTypes.number,
  rowsPerPage: PropTypes.number,
  page: PropTypes.number,
  expectMoreItems: PropTypes.bool,
  onChangePage: PropTypes.func,
  onChangeRowsPerPage: PropTypes.func,
};

MyTablePagination.defaultProps = {
  colSpan: 5,
  count: 0,
  rowsPerPage: 25,
  page: 0,
  expectMoreItems: false,
  onChangePage: undefined,
  onChangeRowsPerPage: undefined,
};

export default MyTablePagination;
