import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import { useSelector } from 'react-redux';
import { Checkbox, TableCell } from '@material-ui/core';
import MainHeader from '../../reusableComponents/TableToolbar';
import BackButton from '../../reusableComponents/BackButton';
import useStyles from '../../../styles/styles';
import StatsInformation from './StatsInformation';
import CreateCallsToolbar from './CreateCallsToolbar';
import useStyleHelpers from '../../../styles/styleHelpers';
import TableHeader from '../../reusableComponents/TableHeader';
import {
  getCallListStatsInformation,
  getCreatedCallList,
  insertQuery,
} from '../../../thunks/callThunks';
import { useStableDispatch } from '../../../utils/hooks';
import TableLoader from '../../reusableComponents/TableLoader';
import CreatedCallsResultsTableBody from './CreatedCallsResultsTableBody';
import MyTablePagination from '../../reusableComponents/MyTablePagination';
import { updateCreatedCall } from '../../../requests/callRequests';
import PopulatePopUp from './PopulatePopUp';
import notification from '../../reusableComponents/notification';

const AttorneySearchCreateCalls = () => {
  const { content } = useStyles();
  const { padding } = useStyleHelpers();

  const { id } = useParams();
  const history = useHistory();

  const dispatch = useStableDispatch();
  const { createdCallList, createdCallListSize, callListStats } = useSelector(
    (store) => store.callsReducer
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [condition, setCondition] = useState('ALL');
  const [searchValue, setSearchValue] = useState('');
  const [selected, setSelected] = React.useState([]);
  const [open, setOpen] = useState(false);

  const indeterminate =
    selected.length > 0 &&
    createdCallListSize &&
    createdCallListSize[page] &&
    selected.length < createdCallListSize[page].length;

  const checked =
    createdCallListSize &&
    createdCallListSize[page] &&
    createdCallListSize[page].length > 0 &&
    selected.length === createdCallListSize[page].length;

  useEffect(() => {
    if (!id) return;
    dispatch(getCallListStatsInformation(id));
  }, [dispatch, id, selected]);

  useEffect(() => {
    (async () => {
      if (!id) return;
      if (!createdCallListSize) {
        setLoading(true);
        await dispatch(
          getCreatedCallList({
            condition: 'ALL',
            queryId: id,
            page,
            size: rowsPerPage,
            clearStore: false,
          })
        );
        setLoading(false);
      }
    })();
  }, [dispatch, page, rowsPerPage, createdCallListSize, id]);

  useEffect(() => {
    if (createdCallList && createdCallList[page]) {
      const newSelected = createdCallList[page].map((item) => (item.add2Queue === 1 ? item : null));
      setSelected(newSelected.filter((item) => item !== null));
    }
  }, [createdCallList, page]);

  const onConditionChange = async (event) => {
    setCondition(event.target.value);
    setLoading(true);
    setPage(0);
    await dispatch(
      getCreatedCallList({
        condition: event.target.value,
        page: 0,
        size: rowsPerPage,
        queryId: id,
        name: searchValue,
        clearStore: true,
      })
    );
    setLoading(false);
  };

  const onPopulatePopUpOpen = () => {
    setOpen(true);
  };

  const onPopulatePopUpClose = () => {
    setOpen(false);
  };

  const onInsertQuery = async (queryList) => {
    if (!queryList) return;
    try {
      await dispatch(insertQuery(id, queryList));
      onPopulatePopUpClose();
      history.push('/attorneysSearch/');
      notification({ message: 'The list  was successfully populated!', type: 'success' });
    } catch (e) {
      notification({ message: 'Something went wrong!', type: 'error' });
    }
  };

  const onChangePage = async (newPage) => {
    setLoading(true);
    setPage(newPage);
    await dispatch(
      getCreatedCallList({
        condition,
        page: newPage,
        size: rowsPerPage,
        queryId: id,
        name: searchValue,
        clearStore: false,
      })
    );
    setLoading(false);
  };

  const onChangeRowsPerPage = async (newRowsPerPage, newPage) => {
    setLoading(true);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    await dispatch(
      getCreatedCallList({
        condition,
        page: newPage,
        size: newRowsPerPage,
        queryId: id,
        name: searchValue,
        clearStore: true,
      })
    );
    setLoading(false);
  };

  const onSearchSubmitted = async (value) => {
    setLoading(true);
    setSearchValue(value);
    setPage(0);
    await dispatch(
      getCreatedCallList({
        condition,
        page: 0,
        size: rowsPerPage,
        queryId: id,
        name: value,
        clearStore: true,
      })
    );
    setLoading(false);
  };

  const isSelected = (item) => {
    return selected.indexOf(item) !== -1;
  };

  /* it changes add2Queue to 1 (selected) in item and sends new item to the server
  when user check the item */
  const changeAdd2QueueTo1 = (item) => {
    if (item.add2Queue === 0) {
      // eslint-disable-next-line no-param-reassign
      item.add2Queue = 1;
      try {
        return updateCreatedCall({ fpId: item.id.fpId, queryId: id, value: 1 });
      } catch (e) {
        console.log(e);
      }
    }
    return undefined;
  };

  /* it changes add2Queue to 0 (not selected) in item and sends new item to the server
  when user uncheck the item */
  const changeAdd2QueueTo0 = (item) => {
    if (item.add2Queue === 1) {
      // eslint-disable-next-line no-param-reassign
      item.add2Queue = 0;
      try {
        return updateCreatedCall({ fpId: item.id.fpId, queryId: id, value: 0 });
      } catch (e) {
        console.log(e);
      }
    }
    return undefined;
  };

  const handleClick = async (event, item) => {

    if (item.errCd) //if error not selecteable.
      return;
    const selectedIndex = selected.indexOf(item);
    let newSelected = [];
    if (selectedIndex === -1) {
      await changeAdd2QueueTo1(item);
      newSelected = newSelected.concat(selected, item);
      setSelected(newSelected);
      return;
    }
    await changeAdd2QueueTo0(item);
    newSelected = [...selected];
    newSelected.splice(selectedIndex, 1);
    setSelected(newSelected);
  };

  const onSelectAllClick = (event) => {
    if (createdCallList[page] && event.target.checked) {
      const temp = [];
      const newSelected = createdCallList[page].map((item) => {
        if (item.errCd) //if error not selecteable.
          return null;
        temp.push(item);
        return changeAdd2QueueTo1(item);
      }).filter(m => m);
      Promise.all(newSelected).then(() => {
        setSelected(temp);
      });
      return;
    }
    Promise.all(
      createdCallList[page].map((item) => {
        return changeAdd2QueueTo0(item);
      })
    ).then(() => {
      setSelected([]);
    });
  };

  return (
    <main className={content}>
      <MainHeader
        header="Attorney list for cold calls"
        prefix={<BackButton />}
        justify="flex-start"
      />
      <div className={padding}>
        <StatsInformation stats={callListStats} />
        <CreateCallsToolbar
          disabled={!createdCallListSize}
          condition={condition}
          onConditionChange={onConditionChange}
          onSearchSubmitted={onSearchSubmitted}
          onPopulatePopUpOpen={onPopulatePopUpOpen}
        />
        <div className={`${padding}`}>
          <TableContainer component={Paper}>
            <Table>
              <TableHeader
                headers={['FP Id', 'Name', 'Firm', 'Additional Info', 'Status', '']}
                checkbox={
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={indeterminate}
                      checked={checked}
                      onChange={onSelectAllClick}
                      inputProps={{ 'aria-label': 'select all desserts' }}
                    />
                  </TableCell>
                }
              />
              {loading ? (
                <TableLoader rowsPerPage={rowsPerPage} cols={6} />
              ) : (
                <CreatedCallsResultsTableBody
                  createdCallList={createdCallList}
                  page={page}
                  createdCallListSize={createdCallListSize}
                  handleClick={handleClick}
                  isSelected={isSelected}
                />
              )}
              {!!createdCallListSize && (
                <MyTablePagination
                  colSpan={8}
                  count={createdCallListSize}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  onChangePage={onChangePage}
                  onChangeRowsPerPage={onChangeRowsPerPage}
                />
              )}
            </Table>
          </TableContainer>
        </div>
      </div>
      <PopulatePopUp
        state={open}
        onConfirmAction={onInsertQuery}
        onCancelAction={onPopulatePopUpClose}
      />
    </main>
  );
};

export default AttorneySearchCreateCalls;
