import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../styles/theme.js';

const defaultStyles = {
  background: theme.palette.success,
  borderRadius: theme.spacing[1],
  padding: theme.spacing[2],
  color: theme.palette.black,

  fontFamily: 'PT Sans Regular',
  fontSize: '14px',
  lineHeight: '17px',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const useStatusBarsStyles = makeStyles({
  uploaded: {
    ...defaultStyles,
    background: theme.palette.statusBar.uploaded,
  },
  loaded: { ...defaultStyles, background: theme.palette.statusBar.loaded },
  converted: { ...defaultStyles, background: theme.palette.statusBar.converted },
  merged: { ...defaultStyles, background: theme.palette.statusBar.ready },
  error: { ...defaultStyles, background: theme.palette.statusBar.error },
});

export default useStatusBarsStyles;
