import { getRequest, patchRequest, putRequest, uploadRequest } from '../services/fetchUtils.js';

export const getJobSearchCriteria = () =>
  getRequest('/v1/searchCriteria/?searchType=1').then(({ keys }) => keys);

export const uploadJobsList = (file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', 'JOB');
  return uploadRequest('/v1/file/upload', formData);
};

export const getUploadedJobFiles = () =>
  getRequest('/v1/files?type=JOB').then(({ files }) => files);

export const getJobSearch = (search) =>
  putRequest('/v1/search/jobs', { search }).then(({ results }) => results);

export const getLastActiveFile = () => getRequest('/v1/file/active');

export const getJobFileList = () =>
  getRequest('/v1/files?cma=true&type=job').then(({ files }) => files);

export const setJobActiveFile = (fileId) => patchRequest(`/v1/file/active?fileId=${fileId}`);

export const getJobFilters = () =>
  getRequest('/v1/filter/list?type=1').then(({ filters }) => filters);

export const getJobFilterByName = (name) =>
  getRequest(`/v1/filter?name=${name}`).then(({ config }) => atob(config));

export const saveJobFilterRequest = ({ filterConfig, name, jobYears, condition }) => {
  const formData = new FormData();
  // eslint-disable-next-line no-unused-expressions
  condition && formData.append('condition', condition);
  formData.append('file', new Blob([JSON.stringify(filterConfig)]));
  formData.append('name', name);
  formData.append('type ', '1');
  if (jobYears)
    formData.append('jobYears', jobYears);

  return uploadRequest('/v1/filter/save', formData);
};
