import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import Back from '../../../assets/icons/buttonIcons/Back.svg';
import useStyleHelpers from '../../../styles/styleHelpers.js';

const BackButton = () => {
  const history = useHistory();
  const { mediumMarginRight } = useStyleHelpers();

  return (
    <IconButton className={mediumMarginRight} onClick={() => history.go(-1)}>
      <img src={Back} alt="back" />
    </IconButton>
  );
};

export default BackButton;
