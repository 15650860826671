import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Utils as QbUtils } from '@shadowdrone/react-awesome-query-builder';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import useStyles from '../../../styles/styles.js';
import SaveCancelButtons from '../SaveCancelButtons';
import useStyleHelpers from '../../../styles/styleHelpers.js';
import useSaveFiltersStyles from './SaveFilters.styles.js';
import { saveJobFilter } from '../../../thunks/jobThunks.js';
import { useStableDispatch } from '../../../utils/hooks.js';
import { saveAttorneyFilter } from '../../../thunks/attorneyThunks.js';

const SaveFilter = ({ disabled, setSavingState, tree, type, jobYears, condition }) => {
  const { outlinedButton } = useStyles();
  const { mediumMarginLeft } = useStyleHelpers();
  const { wrapper, componentMargin, input, buttons } = useSaveFiltersStyles();

  const dispatch = useStableDispatch();
  const { jobFilterList } = useSelector((store) => store.defaultReducer);

  const [saving, setSaving] = useState(false);

  const toggleSaving = () => setSaving(!saving);

  const onSave = ({ filterName }, { setSubmitting }) => {
    setSubmitting(false);
    toggleSaving();
    setSavingState(false);

    switch (type) {
      case 'job':
        dispatch(
          saveJobFilter({
            filterConfig: QbUtils.getTree(tree),
            name: filterName,
            jobYears,
            condition,
          })
        );
        break;
      case 'attorney':
        dispatch(
          saveAttorneyFilter({ filterConfig: QbUtils.getTree(tree), name: filterName, condition })
        );
        break;
      default:
        break;
    }
  };

  const validation = ({ filterName }) => {
    const errors = {};
    if (!filterName) {
      errors.filterName = 'Enter filter name';
    }

    const nameExist = jobFilterList && jobFilterList.some(({ name }) => name === filterName);
    if (nameExist) {
      errors.filterName = 'Filter name already exists. Please, try another one';
    }

    return errors;
  };

  return (
    <div className={componentMargin}>
      {!saving && (
        <Button
          className={outlinedButton}
          color="primary"
          variant="outlined"
          onClick={() => {
            toggleSaving();
            setSavingState(true);
          }}
          disabled={disabled}
        >
          Save Filter
        </Button>
      )}
      {!!saving && (
        <Formik initialValues={{ filterName: '' }} validate={validation} onSubmit={onSave}>
          {({ submitForm, resetForm, isSubmitting }) => (
            <div className={wrapper}>
              <Field
                component={TextField}
                name="filterName"
                type="text"
                variant="filled"
                label="Filter name"
                className={input}
              />
              <div className={`${mediumMarginLeft} ${buttons}`}>
                <SaveCancelButtons
                  onSubmit={submitForm}
                  disabled={isSubmitting}
                  onCancel={() => {
                    toggleSaving();
                    resetForm();
                    setSavingState(false);
                  }}
                />
              </div>
            </div>
          )}
        </Formik>
      )}
    </div>
  );
};

SaveFilter.propTypes = {
  setSavingState: PropTypes.func,
  jobYears: PropTypes.number,
  type: PropTypes.string,
  condition: PropTypes.string,
  disabled: PropTypes.bool,
  tree: PropTypes.objectOf(PropTypes.any),
};

SaveFilter.defaultProps = {
  setSavingState: () => {},
  type: 'job' || 'attorney',
  disabled: false,
  jobYears: 0,
  condition: '',
  tree: {},
};

export default SaveFilter;
