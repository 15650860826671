import React from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';
import {getRoleList} from '../../../../thunks/roleThunks';
import {useStableDispatch} from '../../../../utils/hooks';
import AccountRoleStyle from './AccountRoleStyle';

const AccountRole = ({roleId}) => {
  const dispatch = useStableDispatch();
  const {roleList} = useSelector((store) => store.defaultReducer);

  const {roleStyle} = AccountRoleStyle();

  if (!roleList || !roleList.length) {
    dispatch(getRoleList());
  }

  const role = roleList.find((item) => {
    return item.id === roleId;
  });

  if (role) {
    return <td className={roleStyle}>{role.roleName}</td>;
  }
  return <td className={roleStyle}>The role is not assigned</td>;
};

AccountRole.propTypes = {
  roleId: PropTypes.number,
};

AccountRole.defaultProps = {
  roleId: null,
};

export default AccountRole;
