// eslint-disable-next-line import/prefer-default-export
export const makeQuery = (sqlQuery, yearsAgo) => {
  const agoQuery = `(
   (Min_Years Is Not Null and Max_Years Is Not Null and ${yearsAgo} between (Min_Years - 1)  AND (Max_Years  + 1)) OR
   (Min_Years Is Null AND Max_Years >= ( ${yearsAgo - 1} ) ) OR
   (Min_Years<=(${yearsAgo + 1}) AND Max_Years Is Null)
  )`;
  if (!sqlQuery && !yearsAgo) return '';
  if (!sqlQuery) return agoQuery;
  if (!yearsAgo) return sqlQuery;

  return `${sqlQuery} AND ${agoQuery}`;
};
