import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import ActionsOutlinedButton from '../../reusableComponents/ActionsOutlinedButton';
import useMakeCallsStyles from '../../../styles/callsStyles';
import { getAttorneysReadyToWork } from '../../../thunks/attorneyThunks';
import { useStableDispatch } from '../../../utils/hooks';

const ActionsBlock = ({ handleClickOpen, onSaveCall, phase, isButtonsDisabled}) => {
  const { gridActionButtons } = useMakeCallsStyles();
  const dispatch = useStableDispatch();

  return (
    <Grid container className={gridActionButtons}>
      <Grid item container lg={6} xl={6} md={12} sm={12}>
        <Grid item lg={12} xl={12} md={12} sm={12}>
          {phase === 2 ? (
            <ActionsOutlinedButton
              onClick={async () => {
                await onSaveCall('AM');
                await dispatch(getAttorneysReadyToWork({ page: 0, size: 5, clearStore: true }));
              }}
              title="App. the market"
              disabled={isButtonsDisabled}
            />
          ) : (
            <ActionsOutlinedButton
              onClick={() => {
                handleClickOpen('IB');
              }}
              title="Interested, has book"
              disabled={isButtonsDisabled}
            />
          )}
        </Grid>
        <Grid item lg={12} xl={12} md={12} sm={12}>
          <ActionsOutlinedButton
            onClick={() => {
              onSaveCall('VM');
            }}
            title="received voicemail"
            disabled={isButtonsDisabled}
          />
        </Grid>
        <Grid item lg={12} xl={12} md={12} sm={12}>
          <ActionsOutlinedButton
            onClick={() => {
              onSaveCall('NI');
            }}
            title="not interested"
            disabled={isButtonsDisabled}
          />
        </Grid>
      </Grid>
      <Grid item container lg={6} xl={6} md={12} sm={12}>
        <Grid item lg={12} xl={12} md={12} sm={12}>
          {phase === 2 ? (
            <ActionsOutlinedButton
              onClick={() => {
                onSaveCall('LV');
              }}
              title="Left Voicemail"
              disabled={isButtonsDisabled}
            />
          ) : (
            <ActionsOutlinedButton
              onClick={() => {
                onSaveCall('INB');
              }}
              title="Interested, no book"
              disabled={isButtonsDisabled}
            />
          )}
        </Grid>
        <Grid item lg={12} xl={12} md={12} sm={12}>
          <ActionsOutlinedButton
            onClick={() => {
              onSaveCall('AA');
            }}
            title="admin answered"
            disabled={isButtonsDisabled}
          />
        </Grid>
        <Grid item lg={12} xl={12} md={12} sm={12}>
          <ActionsOutlinedButton
            onClick={() => {
              onSaveCall('NLT');
            }}
            title="no longer there"
            disabled={isButtonsDisabled}
          />
        </Grid>
      </Grid>
      <Grid item container lg={12} xl={12} md={12} sm={12}>
        <ActionsOutlinedButton
          onClick={() => {
            handleClickOpen('RBS');
          }}
          title="ring back shortly"
          disabled={isButtonsDisabled}
        />
      </Grid>
    </Grid>
  );
};

ActionsBlock.propTypes = {
  handleClickOpen: PropTypes.func,
  onSaveCall: PropTypes.func,
  phase: PropTypes.number,
};

ActionsBlock.defaultProps = {
  handleClickOpen: () => {},
  onSaveCall: () => {},
  phase: 1,
};

export default ActionsBlock;
