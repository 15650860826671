import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { TableRow } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import useStyles from '../../../styles/styles.js';
import Delete from '../../../assets/icons/buttonIcons/Delete-red.svg';
import useStyleHelpers from '../../../styles/styleHelpers';
import StatusBar from './StatusBar/StatusBar';
import AccountRole from './AccountRole/AccountRole';

const AccountListTableBody = ({ accountList, handleClickOpen, disabled }) => {
  const { outlinedButton } = useStyles();
  const { mediumButtonWidth, textRight, opacity } = useStyleHelpers();

  return (
    <TableBody>
      {!!accountList &&
        !!accountList.length &&
        accountList.map(
          ({
            userId = '',
            userName = '',
            isActive = '',
            roleId = '',
            email = '',
            firstName = '',
            lastName = '',
            password = '',
            confirmationToken = '',
          }) => (
            <TableRow key={userId}>
              <TableCell>{userName}</TableCell>
              <AccountRole roleId={roleId} />
              <TableCell>
                <StatusBar active={isActive} />
              </TableCell>
              <TableCell className={textRight}>
                <Link
                  to={{
                    pathname: '/editAccount',
                    state: {
                      userAccount: {
                        userName,
                        isActive: Boolean(isActive),
                        roleId,
                        email,
                        firstName,
                        lastName,
                        password,
                      },
                      token: confirmationToken,
                    },
                  }}
                >
                  <Button
                    className={`${outlinedButton} ${mediumButtonWidth}`}
                    fullWidth
                    variant="outlined"
                    color="primary"
                  >
                    VIEW
                  </Button>
                </Link>
              </TableCell>
              <TableCell>
                <IconButton
                  onClick={() => {
                    handleClickOpen(userId);
                  }}
                  aria-label="delete"
                  disabled={disabled}
                  className={disabled && opacity}
                >
                  <img src={Delete} alt="delete" />
                </IconButton>
              </TableCell>
            </TableRow>
          )
        )}
    </TableBody>
  );
};

AccountListTableBody.propTypes = {
  accountList: PropTypes.arrayOf(PropTypes.object),
  handleClickOpen: PropTypes.func,
  disabled: PropTypes.bool,
};

AccountListTableBody.defaultProps = {
  accountList: [],
  handleClickOpen: () => {},
  disabled: false,
};

export default AccountListTableBody;
