import React, { useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import { Table, TableContainer } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import MainHeader from '../../reusableComponents/TableToolbar';
import BackButton from '../../reusableComponents/BackButton';
import useStyles from '../../../styles/styles';
import TableHeader from '../../reusableComponents/TableHeader';
import { useStableDispatch } from '../../../utils/hooks';
import { getLoadErrorListById } from '../../../thunks/loadErrorThunks';
import TableLoader from '../../reusableComponents/TableLoader';
import LogReportTableBody from './LogReportTableBody';
import useStyleHelpers from '../../../styles/styleHelpers';
import MyTablePagination from '../../reusableComponents/MyTablePagination';
import useTableStyles from '../../../styles/tableStyles';

const LogReport = () => {
  const {
    state: { name = '' },
  } = useLocation();
  const { id } = useParams();
  const { content, paper, subTitle } = useStyles();
  const { largeMarginLeft } = useStyleHelpers();
  const { textAlignLastChild } = useTableStyles();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const dispatch = useStableDispatch();
  const { loadErrorList } = useSelector((store) => store.defaultReducer);
  const loadErrorListIsEmpty =
    !loadErrorList || !loadErrorList[0] || loadErrorList[0].fileId.toString() !== id;

  useEffect(() => {
    (async () => {
      if (loadErrorListIsEmpty) {
        setLoading(true);
        await dispatch(getLoadErrorListById(id));
        setLoading(false);
      }
    })();
  }, [dispatch, loadErrorListIsEmpty, id]);

  return (
    <main className={content}>
      <MainHeader
        header="log report"
        prefix={<BackButton />}
        suffix={<div className={`${subTitle} ${largeMarginLeft}`}>{name}</div>}
        justify="flex-start"
      />
      <div className={paper}>
        <TableContainer component={Paper}>
          <Table className={textAlignLastChild}>
            <TableHeader headers={['Date', 'Event', 'Event Message', 'Error Level']} />
            {loading ? (
              <TableLoader rowsPerPage={rowsPerPage} />
            ) : (
              <LogReportTableBody
                logReportList={loadErrorList}
                page={page}
                rowsPerPage={rowsPerPage}
              />
            )}
            {!!loadErrorList && !!loadErrorList.length && (
              <MyTablePagination
                colSpan={6}
                count={loadErrorList.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onChangePage={setPage}
                onChangeRowsPerPage={(newRowPerPage) => {
                  setRowsPerPage(newRowPerPage);
                  setPage(0);
                }}
              />
            )}
          </Table>
        </TableContainer>
      </div>
    </main>
  );
};

export default LogReport;
