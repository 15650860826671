import AntdConfig from '@shadowdrone/react-awesome-query-builder/lib/config/antd';

// eslint-disable-next-line import/prefer-default-export
export const mapConfig = (keys) => {
  const configObject = { ...AntdConfig, fields: {} };

  configObject.types.select.widgets.select.operators.push('like', 'not_like', 'equal', 'not_equal', 'starts_with', 'ends_with');
  configObject.widgets.select.sqlFormatValue = configObject.widgets.text.sqlFormatValue;

  if (!keys || !keys.length) return configObject;

  keys.forEach(({ key, operators, values, ...obj }) => {
    configObject.fields[key] = { ...obj, valueSources: ['value'] };

    
    // this functionality allow to use operators from server
    if (operators && operators.length) {
      configObject.fields[key].operators = operators.map((string) => {
        const arr = string.toLowerCase().split(' ');
        return arr.join('_');
      });
    }

    if (values && values.length) {
      configObject.fields[key].fieldSettings = {};
      if (configObject.fields[key].type === 'select') {
        //activate custom values to allow partial searchs.
        if (configObject.fields[key].fieldSettings.allowCustomValues == undefined)
          configObject.fields[key].fieldSettings.allowCustomValues = true;
      }
      configObject.fields[key].listValues = values.map((value) => ({
        value,
        title: value,
      }));
    }
  });
  return configObject;
};
