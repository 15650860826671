import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import { Toolbar } from '@material-ui/core';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import useStyles from '../../../styles/styles.js';
import { getAttorneySearch, getAttorneySearchAllResults, getDemographic, getAttorneySearchAllQueryCompletedStatus } from '../../../requests/attorneyRequests.js';
import MainHeader from '../../reusableComponents/TableToolbar';
import { headers, printHeader } from './AttorneysSearchResults.utils';
import TableHeader from '../../reusableComponents/TableHeader';
import MyTablePagination from '../../reusableComponents/MyTablePagination';
import ExportToXlsx from '../../reusableComponents/ExportToXlsx';
import TableLoader from '../../reusableComponents/TableLoader';
import ResultsTableBody from './ResultTableBody.js';
import BackButton from '../../reusableComponents/BackButton';
import useStyleHelpers from '../../../styles/styleHelpers';
import * as moment from 'moment';
import DemographicsFieldFilter from './DemographicsFieldFilter';
import { attExcelTitles, demographicExcelTitles, numberToExcelLetters, addExcelComment } from './ExcelTitles';

const AttorneysSearchResults = () => {
  const { content, paper, checkedIcon } = useStyles();
  const { marginAuto } = useStyleHelpers();
  const [list, setList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [resultsTotalSize, setResultsTotalSize] = useState(0);
  const [queryReferenceId, setQueryReferenceId] = useState("");
  const [expectMoreItems, setExpectMoreItems] = useState(false);
  const [refreshQueryCompletition, setRefreshQueryCompletition] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkbox, setCheckbox] = useState({ allCount: false, splitFirms: false });
  const [useFieldFilter, setUseFieldFilter] = useState(false);
  const [allResults, setAllResults] = useState([]);
  const [demographic, setDemographic] = useState([]);
  const [chambersRankingFilter, setChambersRankingFilter] = useState("");
  const [legal500RankingFilter, setLegal500RankingFilter] = useState("");
  const [openFieldFilter, setOpenFieldFilter] = useState(false);
  const history = useHistory();
  const { search, moreThan, lessThan, displayColumnNames } = history.location.state;

  useEffect(() => {
    setLoading(true);
    getAttorneySearch(search, moreThan, lessThan, page, rowsPerPage, queryReferenceId)
      .then(({ status, results, totalSize, queryId, expectMoreItems }) => {
        if (results == undefined)
          console.log("error while running query2")
        console.log(status);
        setList(results);
        setFilteredList(results);
        setQueryReferenceId(queryId);
        setExpectMoreItems(expectMoreItems);
        //console.log(expectMoreItems)
        if (expectMoreItems)
          setResultsTotalSize(totalSize + 1);
        else
          setResultsTotalSize(totalSize);
        //console.log({ expectMoreItems, refreshQueryCompletition })
        if (expectMoreItems && !refreshQueryCompletition){
          setTimeout(() => {
            checkQueryCompletion(queryId)
          }, 1000);
        }
      }
      )
      
      .finally(() => setLoading(false));
  }, [search, moreThan, lessThan, rowsPerPage, page]);

  /*useEffect(() => {
    getAttorneySearchAllResults(search, moreThan, lessThan)
      .then(({ results }) => {
        setAllResults(results);
      })
      .finally(() => setLoading(false));
  }, [search, moreThan, lessThan])*/



  useEffect(() => {
    if (!!checkbox.allCount) {
      getDemographic(search, moreThan, lessThan, queryReferenceId)
        .then(({ firmDemographics }) => {
          filterDemographicRanks(firmDemographics);
          setDemographic(firmDemographics);
        })
        .finally(() => setLoading(false));
    }

  }, [search, moreThan, lessThan, checkbox, queryReferenceId])

  //console.log(demographic)

  const onSearch = (value) => {
    setFilteredList(
      list.filter(({ name }) =>
        value ? name && name.toLowerCase().includes(value.toLowerCase()) : true
      )
    );
    setPage(0);
  };

  const onCheckboxChanges = ({ target: { checked, name } }) =>
    setCheckbox({ ...checkbox, [name]: checked });


  const onDownloadAllData = async () => {
    setLoading(true);
    let completed = false;
    completed = (await getAttorneySearchAllQueryCompletedStatus(queryReferenceId)).completed;
    //console.log(completed);
    if (completed)
      return getAttorneySearchAllResults(search, moreThan, lessThan, queryReferenceId, displayColumnNames)
      .finally(() => setLoading(false));
    else
      alert('oops still loading data');
    setLoading(false);
    return {results:[]}
  }

  const onDownloadDemographicInfoOnly = async () => {
    setLoading(true);
    let completed = false;
    completed = (await getAttorneySearchAllQueryCompletedStatus(queryReferenceId)).completed;
    //console.log(completed);
    if (completed) {
      return getDemographic(search, moreThan, lessThan, queryReferenceId)
          .then(({ firmDemographics }) => {
            filterDemographicRanks(firmDemographics);
            return { results: firmDemographics };
          }
      )
      .finally(() => setLoading(false));
    }
    else
      alert('oops still loading data');
    setLoading(false);
    return {results:[]}
  }

  const handleFieldFilterConfirm = (chambersFilter, legal500Filter) => {
    setOpenFieldFilter(false);
    setChambersRankingFilter(chambersFilter);
    setLegal500RankingFilter(legal500Filter);
  };

  const handleCheckboxUseFieldFilterClick = ({target}) => {

    setUseFieldFilter(target.checked);
    if (target.checked && !openFieldFilter)
      setOpenFieldFilter(true);

  }

  const handleFieldFilterClose = () => {
    //onCheckboxChanges({ target: { checked: false, name: "useFieldFilter" } });
    setOpenFieldFilter(false);
    setChambersRankingFilter("");
    setLegal500RankingFilter("");
  };

  const handleFieldFilterIgnore = () => {
    //onCheckboxChanges({ target: { checked: false, name: "useFieldFilter" } });
    setOpenFieldFilter(false);
  };

  const filterDemographicRanks = (demographicInfo) => {
    //console.log(demographicInfo)
    demographicInfo.forEach(d => {
        d.chambersRankingFiltered="";
        if (chambersRankingFilter != '' && d.chambersRanking){
          let itemsParsedChamber = chambersRankingFilter.toLowerCase().split(";");
          d.chambersRankingFiltered = d.chambersRanking.split(';').filter(i => itemsParsedChamber.find( p => i.toLowerCase().includes(p))).join(';');
        }
        d.legal500RankingFiltered="";
        if (legal500RankingFilter != '' && d.legal500Ranking){
          let itemsParsed500 = legal500RankingFilter.toLowerCase().split(";");
          d.legal500RankingFiltered = d.legal500Ranking.split(';').filter(i => itemsParsed500.find( p => i.toLowerCase().includes(p))).join(';');
        } 
    });
  }
  
  const checkQueryCompletion = (queryReferenceId) => {
    getAttorneySearchAllQueryCompletedStatus(queryReferenceId)
      .then(({ completed, rowCount }) => {
        if (rowCount > 0)
          setResultsTotalSize(rowCount);
        setExpectMoreItems(!completed);
        setRefreshQueryCompletition(!completed);
        if (!completed) {
          setTimeout(() => {
            checkQueryCompletion(queryReferenceId)
          }, 1000);
        }
      });
  }

  const onBeatifyAttXlsx = (Sheets, SheetNames) => {

  /*    if(!(Sheets["Attorney Data"]["A1"].c)) Sheets["Attorney Data"]["A1"].c = [];{
      Sheets["Attorney Data"]["A1"].c.hidden = true;
      Sheets["Attorney Data"]["A1"].c.push({t:"TEST"});
      //Sheets[0].A1.v = "FPID";
      //SheetNames[0]="ATTT DATA";
    }*/
    
    attExcelTitles.forEach( (item, i) => {
      //console.log(item)
      if (item.comment){
        let cell = numberToExcelLetters(i)+"1";
        addExcelComment(Sheets["Attorney Data"][cell], item.comment)
      }
    });
      
  }
  const onBeatifyDemographicXlsx = (Sheets, SheetNames) => {

    demographicExcelTitles.forEach( (item, i) => {
      if (item.comment){
        let cell = numberToExcelLetters(i)+"1";
        addExcelComment(Sheets["Demographic Data"][cell], item.comment)
      }
    });
  }
  return (
    <main className={content}>
      <MainHeader
        header="Attorneys result list"
        searchPlaceholder="Search by name"
        onSearch={onSearch}
        prefix={<BackButton />}
        justify="flex-start"
        exportButton={
          <div className={marginAuto}>
            <ExportToXlsx
              data={allResults}
              headers={printHeader}
              split={checkbox.splitFirms}
              splitBy="firm"
              additionalPage={checkbox.allCount ? {title: 'Demographic Data', data: demographic, titles: demographicExcelTitles.map(t => t.label), beautifyXlsx: onBeatifyDemographicXlsx} : null}
              clientDownloadData={onDownloadAllData}
              beautifyXlsx={onBeatifyAttXlsx}
              sheetName="Attorney Data"
              titles={attExcelTitles.map(t => t.label)}
              label="Export Attorneys to Excel"
              fileName={'Attorneys_'+ moment().format('L')}
            />
            </div>
            }
        exportButtonSecondary={
          <div className={marginAuto}>
            <ExportToXlsx
              data={allResults}
              headers={printHeader}
              split={false}
              clientDownloadData={onDownloadDemographicInfoOnly}
              beautifyXlsx={onBeatifyDemographicXlsx}
              sheetName="Demographic Data"
              titles={demographicExcelTitles.map(t => t.label)}
              label="Export Demographics only"
              fileName={'Demographics_'+ moment().format('L')}
            />
          </div>
        }
      />
      <Toolbar>
        <FormGroup row>
          <FormControlLabel
            className={checkedIcon}
            control={
              <Checkbox checked={checkbox.allCount} onChange={onCheckboxChanges} name="allCount" />
            }
            label="Include Demographic Info"
          />
          <FormControlLabel
            className={checkedIcon}
            control={
              <Checkbox
                checked={checkbox.splitFirms}
                onChange={onCheckboxChanges}
                name="splitFirms"
              />
            }
            label="Split Firms"
          />
          <FormControlLabel
            className={checkedIcon}
            control={
              <Checkbox
                checked={useFieldFilter}
                onChange={handleCheckboxUseFieldFilterClick}
                name="useFieldFilter"
              />
            }
            label="Field Filter"
          />
        </FormGroup>
      </Toolbar>
      <div className={paper}>
        <TableContainer component={Paper}>
          <Table>
            <TableHeader headers={headers} />
            {loading ? (
              <TableLoader rowsPerPage={rowsPerPage} cols={5} />
            ) : (
              <ResultsTableBody filteredList={filteredList} page={page} rowsPerPage={rowsPerPage} withError={filteredList == undefined} />
            )}
            {!!filteredList && !!filteredList.length && (
              <MyTablePagination
                colSpan={5}
                count={resultsTotalSize}
                page={page}
                expectMoreItems={expectMoreItems}
                rowsPerPage={rowsPerPage}
                onChangePage={setPage}
                onChangeRowsPerPage={(newRowPerPage) => {
                  setRowsPerPage(newRowPerPage);
                  setPage(0);
                }}
              />
            )}
          </Table>
        </TableContainer>
      </div>
      <DemographicsFieldFilter
            state={openFieldFilter}
            onCancelAction={handleFieldFilterClose}
            //onIgnoreAction={handleFieldFilterIgnore}
            onConfirmAction={handleFieldFilterConfirm}
          />
    </main>
  );
};

export default AttorneysSearchResults;
