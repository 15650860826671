import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";

import useStyleHelpers from "../../../styles/styleHelpers";
import useStyles from "../../../styles/styles";

const DemographicsFieldFilter = ({
  state,
  onCancelAction,
  onIgnoreAction,
  onConfirmAction,
  chambersFilter,
  legal500Filter,
}) => {
  const { colorRed, overflowYHidden } = useStyleHelpers();
  const { inputFocused, dialogTitle } = useStyles();

  const [selectedChambersFilter, setSelectedChambersFilter] = useState("");
  const [selectedLegal500Filter, setSelectedLegal500Filter] = useState("");

  const handleChambersChange = ({ target }) => {
    setSelectedChambersFilter(target.value);
  };

  const handleLegal500Change = ({ target }) => {
    setSelectedLegal500Filter(target.value);
  };

  return (
    <Dialog open={state} onClose={onCancelAction}>
      <DialogTitle id="form-dialog-title" className={dialogTitle}>
        Field Filter
      </DialogTitle>
      <DialogContent className={overflowYHidden}>
        <Grid container spacing={3}>
          <Grid item lg={12} xl={12} md={12} sm={12}>
            <label>Chambers Ranking</label>
            <TextField
              className={inputFocused}
              autoFocus
              margin="dense"
              id="chambersRankingFilter"
              //label="Chambers Ranking Filter"
              value={chambersFilter}
              type="text"
              variant="filled"
              onChange={handleChambersChange}
              fullWidth
            />
            <label>Legal500 Ranking</label>
            <TextField
              className={inputFocused}
              autoFocus
              margin="dense"
              id="legal500RankingFilter"
              //label="Legal500 Ranking Filter"
              value={legal500Filter}
              type="text"
              variant="filled"
              onChange={handleLegal500Change}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onCancelAction}
          className={`${dialogTitle} ${colorRed}`}
        >
          Cancel
        </Button>
        {onIgnoreAction && (
          <Button
            onClick={onIgnoreAction}
            className={`${dialogTitle} ${colorRed}`}
          >
            Ignore Filter
          </Button>
        )}
        <Button
          onClick={() => {
            onConfirmAction(selectedChambersFilter, selectedLegal500Filter);
          }}
          className={`${dialogTitle} ${colorRed}`}
          autoFocus
        >
          Confirm filter
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DemographicsFieldFilter.propTypes = {
  state: PropTypes.bool,
  onCancelAction: PropTypes.func,
  onIgnoreAction: PropTypes.func,
  onConfirmAction: PropTypes.func,
};

DemographicsFieldFilter.defaultProps = {
  state: false,
  onCancelAction: () => {},
  //onIgnoreAction: () => {},
  onConfirmAction: () => {},
};

export default DemographicsFieldFilter;
