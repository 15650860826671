import React from 'react';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import TableNoData from '../../../reusableComponents/TableNoData';
import TablePopup from '../../JobSearchResults/TablePopup';
import { toLocalTimeFormated } from '../../../../utils/datesManagement.js';

const CallsInfoTableBody = ({ callsInfoList, page, rowsPerPage }) => {
  return (
    <TableBody>
      {(callsInfoList.length &&
        callsInfoList
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map(({ name, fpId, callTime, processTime, resultName, resultComment }, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow key={`callLog${name}${fpId}${i}`}>
              <TableCell>{name}</TableCell>
              <TableCell>{callTime ? toLocalTimeFormated(callTime) : '-'}</TableCell>
              <TableCell>{processTime ? toLocalTimeFormated(processTime) : '-'}</TableCell>
              <TableCell>{resultName || '-'}</TableCell>
              <TableCell>
                <TablePopup content={resultComment || 'No data'} />
              </TableCell>
            </TableRow>
          ))) || <TableNoData colSpan={7} />}
    </TableBody>
  );
};
CallsInfoTableBody.propTypes = {
  callsInfoList: PropTypes.arrayOf(PropTypes.object),
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
};

CallsInfoTableBody.defaultProps = {
  callsInfoList: [],
  page: 0,
  rowsPerPage: 5,
};

export default CallsInfoTableBody;
