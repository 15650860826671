const target = (window.env && window.env.API_DOMAIN_ADDR) || 'http://localhost:8080';

const generateAuthHeader = (token = '') => {
  return {Authorization: `${token}`};
};

async function request(url, method, bodyData, type) {
  let tokenJ = ""

  if (localStorage.user) {

    let userJson = localStorage.user;


    const {token} = JSON.parse(userJson);

    tokenJ = token;
  }


  const extendedHeaders = {
    ...generateAuthHeader(tokenJ),
  };
  const headers = new Headers();
  headers.append('Accept', 'application/json');

  headers.append('Content-Type', 'application/json');

  let body = bodyData ? JSON.stringify(bodyData) : undefined;

  if (type === 'FILE') {
    body = bodyData;
    headers.delete('Content-Type');
  }

  Object.entries(extendedHeaders).forEach(([key, value]) => headers.append(key, value));

  const targetURL = new URL(url, target);
  const response = await fetch(targetURL, {
    method,
    headers,
    body,
  });

  return response.json().catch(() => response);
}

export const getRequest = async (url) => {
  return request(url, 'GET');
};

export const postRequest = async (url, body = {}) => {
  return request(url, 'POST', body);
};

export const putRequest = async (url, body = {}) => {
  return request(url, 'PUT', body);
};

export const patchRequest = async (url, body = {}) => {
  return request(url, 'PATCH', body);
};

export const deleteRequest = async (url) => {
  return request(url, 'DELETE');
};

export const uploadRequest = async (url, file = new FormData()) => {
  return request(url, 'POST', file, 'FILE');
};
