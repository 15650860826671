import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useStyles from '../../../styles/styles';
import MenuList from './MenuList';
import logo from '../../../assets/truenorth-logo-2.svg';
import { useStableDispatch } from '../../../utils/hooks.js';
import { setAuth, setUser } from '../../../actions/userActions.js';

const Menu = () => {
  const { auth } = useSelector((store) => store.defaultReducer);

  const { menu, menuLogo } = useStyles();

  const [selected, setSelected] = useState(null);

  const dispatch = useStableDispatch();

  const onLogout = () => {
    delete localStorage.user;

    dispatch(setUser({}));
    dispatch(setAuth(false));
  };

  const highlight = (item) => {
    if (!item) return;
    if (selected) {
      selected.classList.remove('highlight');
    }
    setSelected(item);
    item.classList.add('highlight');
  };

  return (
    <>
      {auth && (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <div
          onClick={(event) => {
            const item = event.target.closest('a');
            highlight(item);
          }}
          className={menu}
        >
          <img src={logo} alt="logo" className={menuLogo} />
          <MenuList onLogout={onLogout} />
        </div>
      )}
    </>
  );
};

export default Menu;
