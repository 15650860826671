import {
  SET_ATTORNEY_CALLS_LOG,
  SET_CALL_LIST_STATS,
  SET_CALLS_PER_DAY,
  SET_CALLS_PER_WEEK,
  SET_CALLS_TO_ATTORNEY,
  SET_CREATED_CALL_LIST,
  SET_CREATED_CALL_LIST_SIZE,
  SET_CURRENT_CALL,
  SET_CURRENT_QUEUE_LIST,
  SET_INTERESTED_ATTORNEYS_COUNT,
  SET_INTERESTED_ATTORNEYS_LIST,
  SET_LEFT_CALLS,
  SET_NEXT_CALL,
  SET_QUEUE_LISTS,
  SET_SEARCH_INTERESTED_ATTORNEYS_COUNT,
  SET_COLD_CALLERS,
  SET_CALLS_COUNT_IN_QUEUE,
  SET_CALLS_COUNT_ACTIVE,
  SET_CALLS_COUNT_DORMANT,
  SET_CALLS_COUNT_DORMANT_BY_IDLE
} from '../constants/reduxConstants';

export const setCurrentQueueList = (list) => ({
  type: SET_CURRENT_QUEUE_LIST,
  list,
});

export const setCurrentCall = (call) => ({
  type: SET_CURRENT_CALL,
  call,
});

export const setCallsPerDay = (size) => ({
  type: SET_CALLS_PER_DAY,
  size,
});

export const setCallsPerWeek = (size) => ({
  type: SET_CALLS_PER_WEEK,
  size,
});

export const setCallsToAttorney = (size) => ({
  type: SET_CALLS_TO_ATTORNEY,
  size,
});

export const setLeftCalls = (size) => ({
  type: SET_LEFT_CALLS,
  size,
});

export const setInterestedAttorneysCount = (size) => ({
  type: SET_INTERESTED_ATTORNEYS_COUNT,
  size,
});

export const setSearchInterestedAttorneysCount = (size) => ({
  type: SET_SEARCH_INTERESTED_ATTORNEYS_COUNT,
  size,
});

export const setInterestedAttorneysList = (list) => ({
  type: SET_INTERESTED_ATTORNEYS_LIST,
  list,
});

export const setCallListStats = (stats) => ({
  type: SET_CALL_LIST_STATS,
  stats,
});

export const setCreatedCallList = (list) => ({
  type: SET_CREATED_CALL_LIST,
  list,
});

export const setCreatedCallListSize = (size) => ({
  type: SET_CREATED_CALL_LIST_SIZE,
  size,
});

export const setQueueLists = (list) => ({
  type: SET_QUEUE_LISTS,
  list,
});

export const setAttorneyCallsLog = (list) => ({
  type: SET_ATTORNEY_CALLS_LOG,
  list,
});

export const setNextCall = (call) => ({
  type: SET_NEXT_CALL,
  call,
});

export const setColdCallers = (list) => ({
  type: SET_COLD_CALLERS,
  list,
});

export const setCallsCountInQueue = (size) => ({
  type: SET_CALLS_COUNT_IN_QUEUE,
  size,
});

export const setCallsCountActive = (size) => ({
  type: SET_CALLS_COUNT_ACTIVE,
  size,
});

export const setCallsCountDormant = (size) => ({
  type: SET_CALLS_COUNT_DORMANT,
  size,
});

export const setCallsCountDormantByIdle = (size) => ({
  type: SET_CALLS_COUNT_DORMANT_BY_IDLE,
  size,
});