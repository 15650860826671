import { makeStyles } from '@material-ui/core/styles';

const AccountFormStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      width: '338px',
    },
  },
}));

export default AccountFormStyles;
