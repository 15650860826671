import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import PropTypes from 'prop-types';
import useSearchStyles from '../../../../styles/searchStyles';
import useStyles from '../../../../styles/styles';

const ChooseFilterType = ({ onTypeFiltersLoad, filterTypeList }) => {
  const { fileSelect } = useSearchStyles();
  const { inputFocused } = useStyles();

  return (
    <FormControl variant="filled" className={`${fileSelect} ${inputFocused}`}>
      <InputLabel id="filterType">Choose a filter preset</InputLabel>
      <Select
        labelId="demo-simple-select-filled-label"
        id="filter"
        onChange={({ target: { value } }) => {
          onTypeFiltersLoad(value);
        }}
      >
        <MenuItem value="all">
          <em>All</em>
        </MenuItem>
        {!!filterTypeList &&
          !!filterTypeList.length &&
          filterTypeList.map(
            ({ id = '', type = '' }) =>
              !!type && (
                <MenuItem value={id} key={id}>
                  {type}
                </MenuItem>
              )
          )}
      </Select>
    </FormControl>
  );
};

ChooseFilterType.propTypes = {
  onTypeFiltersLoad: PropTypes.func,
  filterTypeList: PropTypes.arrayOf(PropTypes.object),
};

ChooseFilterType.defaultProps = {
  onTypeFiltersLoad: () => {},
  filterTypeList: [],
};

export default ChooseFilterType;
