import React, { useEffect, useState } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import * as moment from 'moment';
import MainHeader from '../../reusableComponents/TableToolbar';
import useStyles from '../../../styles/styles.js';
import { getUploadedJobFiles, uploadJobsList } from '../../../requests/jobRequests.js';
import { formatFiles } from '../../../utils/UploadJobs.utils.js';
import MyTablePagination from '../../reusableComponents/MyTablePagination';
import notification from '../../reusableComponents/notification';
import TableLoader from '../../reusableComponents/TableLoader';
import UploadJobsTableBody from './UploadJobsTableBody.js';
import TableHeader from '../../reusableComponents/TableHeader';
import useTableStyles from '../../../styles/tableStyles';

const UploadJobs = () => {
  const { content, paper } = useStyles();
  const { tableTextAlignWithoutFirstChild } = useTableStyles();
  const [uploadList, setUploadList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getUploadedJobFiles()
      .then(formatFiles)
      .then((newList) => {
        setUploadList(newList);
        setFilteredList(newList);
      })
      .finally(() => setLoading(false));
  }, []);

  const onUpload = async (event) => {
    const [file] = event.target.files;
    if (!file) {
      return;
    }
    setLoading(true);
    try {
      const { uploadDate, ...response } = await uploadJobsList(file);

      const responseObj = { ...response, uploadDate: moment(uploadDate).format('LT L') };
      setUploadList([responseObj, ...uploadList]);
      setFilteredList([responseObj, ...filteredList]);
    } catch (e) {
      notification({ message: 'Something went wrong', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const onSearch = (value) => {
    setFilteredList(
      uploadList.filter(({ fileName }) =>
        value ? fileName.toLowerCase().includes(value.toLowerCase()) : true
      )
    );
  };

  return (
    <main className={content}>
      <MainHeader
        header="Uploaded jobs"
        onUpload={onUpload}
        searchPlaceholder="Search by name"
        onSearch={onSearch}
      />
      <div className={paper}>
        <TableContainer component={Paper}>
          <Table className={tableTextAlignWithoutFirstChild}>
            <TableHeader headers={['File name', 'Date of upload', 'Log Report', 'Status']} />
            {loading ? (
              <TableLoader rowsPerPage={rowsPerPage} cols={3} />
            ) : (
              <UploadJobsTableBody
                filteredList={filteredList}
                page={page}
                rowsPerPage={rowsPerPage}
              />
            )}

            {!!filteredList && !!filteredList.length && (
              <MyTablePagination
                colSpan={6}
                count={filteredList.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onChangePage={setPage}
                onChangeRowsPerPage={(newRowPerPage) => {
                  setRowsPerPage(newRowPerPage);
                  setPage(0);
                }}
              />
            )}
          </Table>
        </TableContainer>
      </div>
    </main>
  );
};

export default UploadJobs;
