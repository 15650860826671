import React, { useEffect, useState } from 'react';
import { Paper, Table, TableContainer } from '@material-ui/core';
import { useSelector } from 'react-redux';
import useStyles from '../../../styles/styles';
import MainHeader from '../../reusableComponents/TableToolbar';
import TableHeader from '../../reusableComponents/TableHeader';
import { useStableDispatch } from '../../../utils/hooks';
import { getAttorneysReadyToWork } from '../../../thunks/attorneyThunks';
import TableLoader from '../../reusableComponents/TableLoader';
import MyTablePagination from '../../reusableComponents/MyTablePagination';
import AttorneysReadyToWorkResultsTableBody from './AttorneysReadyToWorkResultsTableBody';

const AttorneysReadyToWork = () => {
  const { content, paper } = useStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const dispatch = useStableDispatch();
  const { attorneysReadyToWork, attorneysReadyToWorkSize } = useSelector(
    (store) => store.defaultReducer
  );

  const attorneysReadyToWorkIsEmpty = !attorneysReadyToWork || !attorneysReadyToWork.length;

  useEffect(() => {
    (async () => {
      if (attorneysReadyToWorkIsEmpty) {
        setLoading(true);
        await dispatch(
          getAttorneysReadyToWork({ page, size: rowsPerPage, name: searchValue, clearStore: true })
        );
        setLoading(false);
      }
    })();
  }, [dispatch, attorneysReadyToWorkIsEmpty, page, rowsPerPage, searchValue]);

  const onChangePage = async (newPage) => {
    setLoading(true);
    setPage(newPage);
    await dispatch(
      getAttorneysReadyToWork({
        page: newPage,
        size: rowsPerPage,
        name: searchValue,
        clearStore: false,
      })
    );
    setLoading(false);
  };

  const onChangeRowsPerPage = async (newRowsPerPage, newPage) => {
    setLoading(true);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    await dispatch(
      getAttorneysReadyToWork({
        page: newPage,
        size: newRowsPerPage,
        name: searchValue,
        clearStore: true,
      })
    );
    setLoading(false);
  };

  const onSearchSubmitted = async (value) => {
    setLoading(true);
    setSearchValue(value);
    setPage(0);
    await dispatch(
      getAttorneysReadyToWork({
        page: 0,
        size: rowsPerPage,
        name: value,
        clearStore: true,
      })
    );
    setLoading(false);
  };

  return (
    <main className={content}>
      <MainHeader
        header="Attorneys we work with"
        searchPlaceholder="Search by name"
        onSearchSubmitted={onSearchSubmitted}
      />
      <Paper className={paper}>
        <TableContainer component={Paper}>
          <Table>
            <TableHeader
              headers={['fp_id', 'Name', 'Firm', 'Location', 'Works from', 'Do not call until', '']}
            />
            {loading ? (
              <TableLoader rowsPerPage={rowsPerPage} cols={7} />
            ) : (
              <AttorneysReadyToWorkResultsTableBody
                attorneyList={attorneysReadyToWork}
                attorneysSize={attorneysReadyToWorkSize}
                page={page}
              />
            )}
            {!!attorneysReadyToWorkSize && (
              <MyTablePagination
                colSpan={7}
                count={attorneysReadyToWorkSize}
                page={page}
                rowsPerPage={rowsPerPage}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
              />
            )}
          </Table>
        </TableContainer>
      </Paper>
    </main>
  );
};

export default AttorneysReadyToWork;
