import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ProfileTextField from '../../reusableComponents/Profile/ProfileTextField';
import Chips from '../../reusableComponents/Profile/Chips';
import useStyleHelpers from '../../../styles/styleHelpers.js';
import useStyles from '../../../styles/styles.js';
import Description from '../../reusableComponents/Profile/Description';
import { useStableDispatch } from '../../../utils/hooks';
import { getOneFirmByName } from '../../../requests/firmRequests';
import { setFirmProfile } from '../../../actions/firmActions';

const Bio = ({ profile }) => {
  const {
    title,
    email,
    firm,
    phone,
    location,
    barAdmission,
    matches,
    id,
    primaryPractices,
    secondaryPractices,
    primarySectors,
    fullBio,
  } = profile;

  const [disableMod, setDisableMod] = useState(false);

  const dispatch = useStableDispatch();
  const { firmProfile } = useSelector((store) => store.defaultReducer);

  const firmProfileIsEmptyOrDifferent =
    JSON.stringify(firmProfile) === '{}' || firm !== firmProfile.firmName;

  useEffect(() => {
    (async () => {
      if (firmProfileIsEmptyOrDifferent) {
        setDisableMod(true);
        const tempProfile = await getOneFirmByName({ name: firm });
        if (!tempProfile.firm) {
          dispatch(setFirmProfile({}));
          setDisableMod(true);
          return;
        }
        await dispatch(setFirmProfile(tempProfile.firm));
        setDisableMod(false);
      }
    })();
  }, [dispatch, firmProfileIsEmptyOrDifferent, firm]);

  const { padding, margin } = useStyleHelpers();
  const { outlinedButton } = useStyles();

  return (
    <div className={padding}>
      <Grid container spacing={3} className={margin}>
        <Grid item lg={8} xl={8}>
          <Grid container spacing={3}>
            <Grid item lg={6} xl={6} md={12}>
              <ProfileTextField value={title} label="Title" />
              <ProfileTextField value={email} label="Email" />
              <ProfileTextField value={firm} label="Firm" />
              <ProfileTextField value={matches} label="Matches" />
            </Grid>

            <Grid item lg={6} xl={6} md={12}>
              <ProfileTextField value={id} label="FId" />
              <ProfileTextField value={phone} label="Phone" />
              <ProfileTextField value={location} label="Location" />
              <ProfileTextField value={barAdmission} label="Bar Admissions" />
            </Grid>
            <Grid item>
              <Description value={fullBio} title="Biography" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={4} xl={4}>
          <Chips
            title="Primary Practice"
            arrOfChips={primaryPractices && primaryPractices.split(',')}
          />
          <Chips
            title="Secondary Practice"
            arrOfChips={secondaryPractices && secondaryPractices.split(',')}
          />
          <Chips title="Primary Sector" arrOfChips={primarySectors && primarySectors.split(',')} />
          {!disableMod ? (
            <Link to={{ pathname: `/firmsList/${firm}`, profile: firmProfile }}>
              <Button
                className={`${outlinedButton} ${margin}`}
                fullWidth
                variant="outlined"
                color="primary"
                disabled={disableMod}
              >
                VIEW CURRENT FIRM
              </Button>
            </Link>
          ) : (
            <Button
              className={`${outlinedButton} ${margin}`}
              fullWidth
              variant="outlined"
              color="secondary"
              disabled={disableMod}
            >
              VIEW CURRENT FIRM
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

Bio.propTypes = {
  profile: PropTypes.objectOf(PropTypes.any),
};

Bio.defaultProps = {
  profile: {},
};

export default Bio;
