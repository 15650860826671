import { getTemplsRequest } from '../requests/templsRequests';
import { setTemplsList } from '../actions/templsActions';

// eslint-disable-next-line import/prefer-default-export
export const getLoadTempls = () => {
  return async (dispatch) => {
    const { loadTemplList } = await getTemplsRequest();
    dispatch(setTemplsList(loadTemplList));
  };
};
