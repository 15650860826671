import React, { useEffect, useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useStyles from '../../../styles/styles.js';
import MainHeader from '../../reusableComponents/TableToolbar';
import { getAttorneyProfile } from '../../../thunks/attorneyThunks.js';
import { useStableDispatch } from '../../../utils/hooks.js';
import TabPanel from '../../reusableComponents/TabPanel';
import Bio from './Bio.js';
import BackButton from '../../reusableComponents/BackButton';
import Education from './Education.js';
import Loader from '../../reusableComponents/Loader';
import CallsInfo from './CallsInfo';

const AttorneyProfile = () => {
  const { id } = useParams();
  const { profile: profileProp = {} } = useLocation();

  const dispatch = useStableDispatch();

  const { content, tabPanel } = useStyles();

  const { attorneyProfiles: { [id]: profile = {} } = {} } = useSelector(
    (store) => store.defaultReducer
  );
  const { id: savedId, name } = profile;

  const [loading, setLoading] = useState(false);

  const [tab, setTab] = useState(0);

  useEffect(() => {
    (async () => {
      if (savedId) return;

      setLoading(true);
      await dispatch(getAttorneyProfile({ id }));
      setLoading(false);
    })();
  }, [id, savedId, dispatch]);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <main className={content}>
      <MainHeader header={name || profileProp.name} prefix={<BackButton />} justify="flex-start" />
      <Tabs className={tabPanel} aria-label="Firms" value={tab} onChange={handleTabChange} centered>
        <Tab label="BIO" />
        <Tab label="EDUCATION" />
        <Tab label="CALLS INFO" />
      </Tabs>
      <TabPanel value={tab} index={0}>
        <Loader loading={loading}>
          <Bio profile={{ ...profile, id }} />
        </Loader>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Loader loading={loading}>
          <Education profile={profile} />
        </Loader>
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <Loader loading={loading}>
          <CallsInfo id={id} />
        </Loader>
      </TabPanel>
    </main>
  );
};

export default AttorneyProfile;
