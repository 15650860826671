import { getRequest, uploadRequest } from '../services/fetchUtils.js';

// eslint-disable-next-line import/prefer-default-export
export const getAttorneySearchCriteria = () =>
  getRequest('/v1/searchCriteria/?searchType=2').then(({ keys }) => keys);

export const getAttorneySearch = (search, moreThan = '', lessThan = '', page, size, queryReferenceId) => {
  const params = new URLSearchParams();
  // eslint-disable-next-line no-unused-expressions
  moreThan && params.append('moreThan', moreThan);
  // eslint-disable-next-line no-unused-expressions
  lessThan && params.append('lessThan', lessThan);
  // eslint-disable-next-line no-unused-expressions
  search && params.append('search', search);
  // eslint-disable-next-line no-unused-expressions
  params.append('page', page);
  queryReferenceId && params.append('queryReferenceId', queryReferenceId);
  // eslint-disable-next-line no-unused-expressions
  size && params.append('size', size);
  return getRequest(`/v1/attorney/search?${params.toString()}`).then(({ status, results, totalSize, queryId, expectMoreItems }) => {
    return { status, results, totalSize, queryId, expectMoreItems };
  });
};

export const getAttorneySearchAllResults = (search, moreThan = '', lessThan = '', queryReferenceId, displayColumnNames) => {
  const params = new URLSearchParams();
  // eslint-disable-next-line no-unused-expressions
  moreThan && params.append('moreThan', moreThan);
  // eslint-disable-next-line no-unused-expressions
  lessThan && params.append('lessThan', lessThan);
  // eslint-disable-next-line no-unused-expressions
  search && params.append('search', search);
  // eslint-disable-next-line no-unused-expressions
  queryReferenceId && params.append('queryReferenceId', queryReferenceId);
  // eslint-disable-next-line no-unused-expressions
  //displayColumnNames && params.append('displayColumnNames', displayColumnNames); //DO NOT SENT FOR NOW
  return getRequest(`/v1/attorney/search-all?${params.toString()}`).then(({ results }) => {
    return { results };
  });
};

export const getDemographic = (search, moreThan = '', lessThan = '', queryReferenceId) => {
  const params = new URLSearchParams();
  // eslint-disable-next-line no-unused-expressions
  moreThan && params.append('moreThan', moreThan);
  // eslint-disable-next-line no-unused-expressions
  lessThan && params.append('lessThan', lessThan);
  // eslint-disable-next-line no-unused-expressions
  search && params.append('search', search);
  // eslint-disable-next-line no-unused-expressions
  queryReferenceId && params.append('queryReferenceId', queryReferenceId);
  return getRequest(`/v1/firms/demographic?${params.toString()}`).then(({ firmDemographics }) => {
    return { firmDemographics };
  });
};

export const getAttorneyRequest = ({ page = 0, size = 5, name = '' }) => {
  const params = new URLSearchParams();
  params.append('page', page);
  params.append('size', size);
  // eslint-disable-next-line no-unused-expressions
  name && params.append('name', name);
  return getRequest(`/v1/attorney/list?${params.toString()}`);
};

export const getAttorneyByFirmRequest = ({ name }) => {
  const params = new URLSearchParams();
  // eslint-disable-next-line no-unused-expressions
  name && params.append('firm_name', name);
  return getRequest(`/v1/attorney/firms?${params.toString()}`);
};

export const getAttorneyFilters = () =>
  getRequest('/v1/filter/list?type=2').then(({ filters }) => filters);

export const getAttorneysReadyToWorkRequest = ({ name = '', page = 0, size = 5 }) => {
  const params = new URLSearchParams();
  // eslint-disable-next-line no-unused-expressions
  name && params.append('name', name);
  return getRequest(`/v1/attorney/addings?page=${page}&size=${size}&${params.toString()}`);
};

export const saveAttorneyFilterRequest = ({ filterConfig, name, condition }) => {
  const formData = new FormData();
  // eslint-disable-next-line no-unused-expressions
  condition && formData.append('condition', condition);
  formData.append('file', new Blob([JSON.stringify(filterConfig)]));
  formData.append('name', name);
  formData.append('type ', '2');
  return uploadRequest('/v1/filter/save', formData);
};

export const getAttorneyFilterByName = (name) =>
  getRequest(`/v1/filter?name=${name}`).then(({ config }) => atob(config));

export const getAttorneyProfileById = (id) =>
  getRequest(`/v1/attorney/?id=${id}`).then(({ attorney }) => attorney);

export const getAttorneySearchAllQueryCompletedStatus = (queryReferenceId) => {
  const params = new URLSearchParams();
  // eslint-disable-next-line no-unused-expressions
  queryReferenceId && params.append('queryReferenceId', queryReferenceId);
  return getRequest(`/v1/attorney/search-completed?${params.toString()}`).then(({ completed, rowCount }) => {
    return { completed, rowCount };
  });
};