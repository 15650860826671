import { makeStyles } from '@material-ui/core/styles';

const useSaveFiltersStyles = makeStyles({
  wrapper: { display: 'inline-flex', alignItems: 'center' },
  componentMargin: { marginLeft: 'auto' },
  input: { width: 320, '& .MuiFormHelperText-root': { position: 'absolute', bottom: -24 } },
  buttons: { alignSelf: 'center' },
});

export default useSaveFiltersStyles;
