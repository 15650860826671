import React, { useEffect, useState } from 'react';
import { TableContainer } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import { useSelector } from 'react-redux';
import useStyles from '../../../styles/styles';
import MainHeader from '../../reusableComponents/TableToolbar';
import { getExcludeJOBUploaded, uploadFileWithType } from '../../../requests/filesRequests';
import TableHeader from '../../reusableComponents/TableHeader';
import { formatFiles } from '../../../utils/UploadJobs.utils';
import TableLoader from '../../reusableComponents/TableLoader';
import UploadedFilesList from './UploadedFilesList';
import MyTablePagination from '../../reusableComponents/MyTablePagination';
import notification from '../../reusableComponents/notification';
import { useStableDispatch } from '../../../utils/hooks';
import { getLoadTempls } from '../../../thunks/TemplsThunk';
import useTableStyles from '../../../styles/tableStyles';

const UploadedFiles = () => {
  const { content, paper } = useStyles();
  const { tableTextAlignWithoutFirstChild } = useTableStyles();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [uploadList, setUploadList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);

  const dispatch = useStableDispatch();
  const { loadTemplsList } = useSelector((store) => store.defaultReducer);

  if (!loadTemplsList || !loadTemplsList.length) {
    dispatch(getLoadTempls());
  }

  useEffect(() => {
    setLoading(true);
    getExcludeJOBUploaded()
      .then(formatFiles)
      .then((newList) => {
        setUploadList(newList);
        setFilteredList(newList);
      })
      .finally(() => setLoading(false));
  }, []);

  const onDropDownUpload = async (event, type) => {
    const [file] = event.target.files;
    if (!file) {
      return;
    }
    setLoading(true);
    try {
      const { uploadDate, ...response } = await uploadFileWithType(file, type);

      const responseObj = { ...response, uploadDate: moment(uploadDate).format('LT L') };
      setUploadList([responseObj, ...uploadList]);
      setFilteredList([responseObj, ...filteredList]);
    } catch (e) {
      notification({ message: 'Something went wrong', type: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const onSearch = (value) => {
    setFilteredList(
      uploadList.filter(({ fileName }) =>
        value ? fileName.toLowerCase().includes(value.toLowerCase()) : true
      )
    );
  };

  return (
    <main className={content}>
      <MainHeader
        header="Uploaded Files"
        onDropDownUpload={onDropDownUpload}
        dropDownList={loadTemplsList}
        searchPlaceholder="Search by name"
        onSearch={onSearch}
      />
      <div className={paper}>
        <TableContainer component={Paper}>
          <Table className={tableTextAlignWithoutFirstChild}>
            <TableHeader headers={['File name', 'Date of upload', 'Log Report', 'Status']} />
            {loading ? (
              <TableLoader rowsPerPage={rowsPerPage} cols={3} />
            ) : (
              <UploadedFilesList
                filteredList={filteredList}
                page={page}
                rowsPerPage={rowsPerPage}
              />
            )}

            {!!filteredList && !!filteredList.length && (
              <MyTablePagination
                colSpan={6}
                count={filteredList.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onChangePage={setPage}
                onChangeRowsPerPage={(newRowPerPage) => {
                  setRowsPerPage(newRowPerPage);
                  setPage(0);
                }}
              />
            )}
          </Table>
        </TableContainer>
      </div>
    </main>
  );
};

export default UploadedFiles;
