import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import Stick from '../../../reusableComponents/Stick';
import { getNextCall } from '../../../../thunks/callThunks';
import { useStableDispatch } from '../../../../utils/hooks';
import useMakeCallsStyles from '../../../../styles/callsStyles';

const NextCall = ({ fpId }) => {
  const { nextCallStickContainer } = useMakeCallsStyles();
  const dispatch = useStableDispatch();
  const { nextCall } = useSelector((store) => store.callsReducer);

  const { name, callTime } = nextCall;

  useEffect(() => {
    (async () => {
      if (!fpId) return;
      await dispatch(getNextCall(fpId));
    })();
  }, [dispatch, fpId]);

  return (
    <div className={nextCallStickContainer}>
      <Stick
        color="red"
        title="Next Planned Call"
        value={name && callTime ? `${name}, ${moment(callTime).format('l LT')}` : `-`}
      />
    </div>
  );
};
NextCall.propTypes = {
  fpId: PropTypes.number,
};

NextCall.defaultProps = {
  fpId: null,
};

export default NextCall;
