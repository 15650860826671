import {
  setAttorneyFiltersList,
  setAttorneyList,
  setAttorneyListSize,
  setAttorneyProfile,
  setAttorneySearchConfig,
  setAttorneysReadyToWork,
  setAttorneysReadyToWorkSize,
  setFirmAttorneyList,
  setFirmAttorneyListSize,
} from '../actions/attorneyActions.js';
import {
  getAttorneyFilterByName,
  getAttorneyFilters,
  getAttorneyProfileById,
  getAttorneyRequest,
  saveAttorneyFilterRequest,
  getAttorneyByFirmRequest,
  getAttorneysReadyToWorkRequest,
} from '../requests/attorneyRequests.js';

export const getAttorneyList = ({ page = 0, size = 5, name = '', clearStore = false }) => {
  return async (dispatch, getState) => {
    const {
      defaultReducer: { attorneyList },
    } = getState();
    if (attorneyList[page] && !clearStore) return;

    const { attorneys, totalSize } = await getAttorneyRequest({ page, size, name });
    let newAttorneysList = [];
    if (!clearStore) {
      newAttorneysList = [...attorneyList];
    }

    newAttorneysList[page] = attorneys;
    dispatch(setAttorneyListSize(totalSize));
    dispatch(setAttorneyList(newAttorneysList));
  };
};

export const getAttorneysReadyToWork = ({ page = 0, size = 5, name = '', clearStore = false }) => {
  return async (dispatch, getState) => {
    const {
      defaultReducer: { attorneysReadyToWork },
    } = getState();
    if (attorneysReadyToWork[page] && !clearStore) return;

    const { attorneys, totalSize } = await getAttorneysReadyToWorkRequest({ page, size, name });
    let newAttorneysList = [];
    if (!clearStore) {
      newAttorneysList = [...attorneysReadyToWork];
    }

    newAttorneysList[page] = attorneys;
    dispatch(setAttorneysReadyToWorkSize(totalSize));
    dispatch(setAttorneysReadyToWork(newAttorneysList));
  };
};

export const getSavedAttorneyFiltersList = () => {
  return async (dispatch) => {
    const filters = await getAttorneyFilters();
    dispatch(setAttorneyFiltersList(filters));
  };
};

export const getAttorneyListByFirmName = ({ name }) => {
  return async (dispatch) => {
    if (!name) return;
    const { attorneys, totalSize } = await getAttorneyByFirmRequest({ name });
    dispatch(setFirmAttorneyListSize(totalSize));
    dispatch(setFirmAttorneyList(attorneys));
  };
};

export const saveAttorneyFilter = ({ name, filterConfig, condition }) => {
  return async (dispatch, getState) => {
    const {
      defaultReducer: { attorneyFilterList },
    } = getState();

    await saveAttorneyFilterRequest({ filterConfig, name, condition });

    const newList = attorneyFilterList ? [{ name }, ...attorneyFilterList] : [{ name }];

    dispatch(setAttorneyFiltersList(newList));
  };
};

export const getSavedAttorneyFilter = (name = '') => {
  return async (dispatch) => {
    const filter = await getAttorneyFilterByName(name);
    dispatch(setAttorneySearchConfig(filter));
  };
};

export const getAttorneyProfile = ({ id }) => {
  return async (dispatch, getState) => {
    const {
      defaultReducer: { attorneyProfiles },
    } = getState();

    const profile = await getAttorneyProfileById(id);

    if (!profile) return;

    const newList = { ...attorneyProfiles, [id]: profile };

    dispatch(setAttorneyProfile(newList));
  };
};
