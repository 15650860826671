import React from 'react';
import { Button, Toolbar } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import useStyles from '../../../styles/styles';
import useTableStyles from '../../../styles/tableStyles';
import useStyleHelpers from '../../../styles/styleHelpers';
import ChooseQueue from '../../reusableComponents/ChooseQueue';

const InterestedToolbar = ({
  interestedAttorneysCount,
  queueList,
  currentQueueName,
  handleChange,
  phase,
}) => {
  const { outlinedButton } = useStyles();
  const { tableHeaderFontBold } = useTableStyles();
  const { largeMarginLeft, mediumMarginRight, marginRightAuto } = useStyleHelpers();

  return (
    <Toolbar>
      <div>
        <strong className={`${tableHeaderFontBold} ${mediumMarginRight}`}>
          Interested attorneys:
        </strong>
        {interestedAttorneysCount || 0}
      </div>
      <Link
        to={{
          pathname: `/makeCalls/${phase}`,
        }}
        disabled={!interestedAttorneysCount}
        className={`${marginRightAuto}`}
      >
        <Button
          className={`${outlinedButton} ${largeMarginLeft}`}
          variant="outlined"
          disabled={!interestedAttorneysCount}
        >
          Make calls
        </Button>
      </Link>
      <ChooseQueue
        queueList={queueList}
        currentQueueName={currentQueueName}
        handleChange={handleChange}
      />
    </Toolbar>
  );
};

InterestedToolbar.propTypes = {
  interestedAttorneysCount: PropTypes.number,
  queueList: PropTypes.string,
  currentQueueName: PropTypes.string,
  phase: PropTypes.number,
  handleChange: PropTypes.func,
};

InterestedToolbar.defaultProps = {
  interestedAttorneysCount: 0,
  queueList: [],
  currentQueueName: '',
  phase: 2,
  handleChange: () => {},
};

export default InterestedToolbar;
