import { makeStyles } from '@material-ui/core/styles';
import theme from './theme.js';

const useStyleHelpers = makeStyles(
  {
    padding: { paddingLeft: theme.spacing[4], paddingRight: theme.spacing[4] },
    noPaddingTopBottom: { paddingTop: 0, paddingBottom: 0 },
    smallLeftPadding: { paddingLeft: theme.spacing[2] },
    smallMargin: { marginTop: theme.spacing[2], marginBottom: theme.spacing[2] },
    mediumMargin: { marginTop: theme.spacing[3], marginBottom: theme.spacing[3] },
    marginTopInput: { marginTop: theme.spacing[3] },
    marginBottomInput: { marginBottom: theme.spacing[3] },
    mediumMarginBottom: { marginBottom: theme.spacing[3] },
    margin: { marginTop: theme.spacing[4], marginBottom: theme.spacing[4] },
    smallMarginTop: { marginTop: theme.spacing[4] },
    mediumMarginTop: { marginTop: theme.spacing[6] },
    mediumMarginLeft: { marginLeft: theme.spacing[3] },
    largeMarginLeft: { marginLeft: theme.spacing[5] },
    largestMarginLeft: { marginLeft: theme.spacing[8] },
    largeMarginTop: { marginTop: theme.spacing[10] },
    fieldErrorMargin: { margin: '3px 14px 0' },
    mediumMarginRight: { marginRight: theme.spacing[3] },
    marginRight: { marginRight: theme.spacing[4] },
    largeFormMarginRight: { marginRight: '10%' },
    mediumFormMarginRight: { marginRight: '5%' },
    flex: { display: 'flex' },
    block: { display: 'block' },
    justifyContent: { justifyContent: 'space-between' },
    justifyContentEnd: { justifyContent: 'flex-end' },
    flexDirections: { flexDirections: 'column' },
    flexDirectionsRow: { flexDirections: 'row' },
    alignItems: { alignItems: 'start' },
    mediumButtonWidth: { width: theme.spacing[5] },
    largeButtonWidth: { width: '45ch' },
    mediumTextFieldWidth: { width: '280px' },
    searchButtonWidth: { width: '145px' },
    largeWidth: { width: '338px' },
    fitContentWidth: { width: 'fit-content' },
    textRight: { textAlign: 'right' },
    textCenter: { textAlign: 'center' },
    statusWidth: { width: theme.spacing[10] },
    colorRed: { color: theme.palette.main },
    colorBlack: { color: theme.palette.black },
    textAlignCenter: { textAlign: 'center' },
    marginAuto: { margin: 'auto' },
    marginRightAuto: { marginRight: 'auto' },
    marginLeftAuto: { marginLeft: 'auto' },
    whiteSpace: { whiteSpace: 'nowrap' },
    fullWidth: { width: '100%' },
    upperText: {
      textTransform: 'uppercase',
    },
    boxShadow: { boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.15)' },
    searchMargin: { '& .MuiToolbar-root>div:last-child': { marginLeft: 'auto' } },
    chipMarginRight: {
      '&>.MuiChip-root': { marginRight: '10px', marginTop: 0, marginBottom: '10px' },
    },
    gridSpacing: {
      '& .MuiGrid-spacing-xs-2 > .MuiGrid-item': {
        paddingRight: 0,
      },
    },
    startIconMarginRight: { '& .MuiButton-startIcon': { marginRight: 0 } },
    overflowYHidden: { overflowY: 'hidden' },
    opacity: { opacity: '0.5' },
  },
  { index: 1 }
);

export default useStyleHelpers;
