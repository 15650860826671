import {
  deleteColdCallersQueue,
  getAttorneyCallsLogRequest,
  getCallListStatsRequest,
  getCallsPerTodayRequest,
  getCallsPerWeekRequest,
  getCallsRequest,
  getCallsToAttorneyRequest,
  getColdCallersRequest,
  getCreatedCallListRequest,
  getInterestedAttorneysListRequest,
  getLeftCallsRequest,
  getNextCallRequest,
  getQueueListRequest,
  getQueueListsRequest,
  insertQueryRequest,
  getCallsStatsByUserRequest,
} from '../requests/callRequests';
import {
  setAttorneyCallsLog,
  setCallListStats,
  setCallsPerDay,
  setCallsPerWeek,
  setCallsToAttorney,
  setColdCallers,
  setCreatedCallList,
  // eslint-disable-next-line import/named
  setCreatedCallListSize,
  setCurrentCall,
  setCurrentQueueList,
  setInterestedAttorneysCount,
  setInterestedAttorneysList,
  setLeftCalls,
  setNextCall,
  setQueueLists,
  setSearchInterestedAttorneysCount,
  setCallsCountInQueue,
  setCallsCountActive,
  setCallsCountDormant,
  setCallsCountDormantByIdle,
} from '../actions/callActions';

export const getQueueList = (id, phase) => {
  return async (dispatch) => {
    if (!id || !phase) return;
    const { queueLists } = await getQueueListRequest(id, phase);
    const isEmptyQueueList = !queueLists || !queueLists.length;
    if (isEmptyQueueList) {
      dispatch(setCurrentQueueList([]));
      return;
    }
    dispatch(setCurrentQueueList(queueLists));
  };
};

export const getCurrentCall = (queueId, id, phase) => {
  return async (dispatch) => {
    if (!id || !queueId || !phase) return;
    const { calls } = await getCallsRequest(queueId, id);
    const isEmptyCalls = !calls || !calls.length;
    if (isEmptyCalls) {
      dispatch(setCurrentCall({}));
      return;
    }
    dispatch(setCurrentCall(calls[0]));
  };
};

export const getInterestedAttorneysList = ({
  page = 0,
  size = 5,
  listId,
  name = '',
  clearStore = false,
}) => {
  return async (dispatch, getState) => {
    if (!listId) {
      dispatch(setInterestedAttorneysList([]));
      return;
    }
    const {
      callsReducer: { interestedAttorneysList },
    } = getState();
    if (interestedAttorneysList[page] && !clearStore) return;

    const { callQueues, totalSize } = await getInterestedAttorneysListRequest({
      page,
      size,
      listId,
      name,
    });
    let newInterestedAttorneysList = [];
    if (!clearStore) {
      newInterestedAttorneysList = [...interestedAttorneysList];
    }
    newInterestedAttorneysList[page] = callQueues;
    // eslint-disable-next-line no-unused-expressions
    dispatch(setSearchInterestedAttorneysCount(totalSize));
    dispatch(setInterestedAttorneysList(newInterestedAttorneysList));
  };
};

export const getCreatedCallList = ({
  condition,
  queryId,
  page = 0,
  size = 5,
  name = '',
  clearStore = false,
}) => {
  return async (dispatch, getState) => {
    if (!condition || !queryId) return;
    const {
      callsReducer: { createdCallList },
    } = getState();
    if (createdCallList[page] && !clearStore) return;

    const { createCalls, totalSize } = await getCreatedCallListRequest({
      condition,
      queryId,
      page,
      size,
      name,
    });
    let newCreatedCallList = [];
    if (!clearStore) {
      newCreatedCallList = [...createdCallList];
    }
    newCreatedCallList[page] = createCalls;
    dispatch(setCreatedCallListSize(totalSize));
    dispatch(setCreatedCallList(newCreatedCallList));
  };
};

export const getInterestedAttorneysCount = (listId) => {
  return (dispatch) => {
    if (!listId) return;
    getLeftCallsRequest(listId).then((leftCalls) =>
      dispatch(setInterestedAttorneysCount(leftCalls))
    );
  };
};

export const getStatsInformation = (userId, listId, fpId) => {
  return (dispatch) => {
    if (!userId) return;
    getCallsPerTodayRequest(userId).then((callsPerDay) => dispatch(setCallsPerDay(callsPerDay)));
    getCallsPerWeekRequest(userId).then((callsPerWeek) => dispatch(setCallsPerWeek(callsPerWeek)));
    getCallsStatsByUserRequest(userId, listId).then(stats => {
      //console.log(stats);
      for (let index = 0; index < stats.length; index++) {
        const stat = stats[index];
        
      
      //stats.forEach(stat => { //no array detected
        //console.log(stat);
        switch(stat.statCode){
          case 'SET_CALLS_COUNT_IN_QUEUE':
              console.log('stat 1')
              dispatch(setCallsCountInQueue(stat.countValue));
              break;
          case 'SET_CALLS_COUNT_ACTIVE':
              console.log('stat 2')
              dispatch(setCallsCountActive(stat.countValue));
              break;
          case 'SET_CALLS_COUNT_DORMANT':
              console.log('stat 3')
              dispatch(setCallsCountDormant(stat.countValue));
              break;
          case 'SET_CALLS_COUNT_DORMANT_BY_IDLE':
              console.log('stat 4')
              dispatch(setCallsCountDormantByIdle(stat.countValue));
              break;
        }
       
     // });
    }
      console.log(stats);
    });

    if (!listId) return;
    getLeftCallsRequest(listId).then((leftCalls) => dispatch(setLeftCalls(leftCalls)));
    if (!fpId) return;
    getCallsToAttorneyRequest(fpId).then((callsToAttorney) =>
      dispatch(setCallsToAttorney(callsToAttorney))
    );
  };
};

export const getCallListStatsInformation = (listId) => {
  return async (dispatch) => {
    if (!listId) return;
    const stats = await getCallListStatsRequest(listId);
    dispatch(setCallListStats(stats));
  };
};

export const getQueueLists = ({ listIsActive = 1, userIsActive = 1, phaseNo = 1 }) => {
  return async (dispatch) => {
    if (!phaseNo) return;
    const { queueLists } = await getQueueListsRequest(listIsActive, userIsActive, phaseNo);
    dispatch(setQueueLists(queueLists));
  };
};

export const getAttorneyCallsLog = (fpId) => {
  return async (dispatch) => {
    if (!fpId) return;
    const { callLogs } = await getAttorneyCallsLogRequest(fpId);
    dispatch(setAttorneyCallsLog(callLogs));
  };
};

export const getNextCall = (fpId) => {
  return async (dispatch) => {
    if (!fpId) return;
    const { nextCallInfoList } = await getNextCallRequest(fpId);
    // eslint-disable-next-line no-unused-expressions
    nextCallInfoList && nextCallInfoList[0]
      ? dispatch(setNextCall(nextCallInfoList[0]))
      : dispatch(setNextCall({}));
  };
};

export const insertQuery = (queryId, queryList) => {
  return async () => {
    if (!queryId || !queryList) return;
    const resp = await insertQueryRequest(queryId, queryList);
    if (resp.status !== 200) throw new Error(resp.status);
  };
};

export const getColdCallers = () => {
  return async (dispatch) => {
    const { coldCallerCalls } = await getColdCallersRequest();
    dispatch(setColdCallers(coldCallerCalls));
  };
};

export const removeColdCallersQueue = (data, listId) => {
  return async (dispatch) => {
    await deleteColdCallersQueue(listId);
    dispatch(setColdCallers(data.filter(item => item.listId !== listId)));
  };
};
