import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import { TextField } from 'formik-material-ui';
import { useSelector } from 'react-redux';
import useStyles from '../../../styles/styles.js';
import MainHeader from '../../reusableComponents/TableToolbar';
import BackButton from '../../reusableComponents/BackButton';
import useStyleHelpers from '../../../styles/styleHelpers';
import FirmProfileField from '../../reusableComponents/Profile/FirmProfileTextFields';
import SaveCancelButtons from '../../reusableComponents/SaveCancelButtons';
import { useStableDispatch } from '../../../utils/hooks';
import { getAttorneyListByFirmName } from '../../../thunks/attorneyThunks';
import AttorneyChips from './AttorneyChips';
import Loader from '../../reusableComponents/Loader';
import { getFirmByName } from '../../../thunks/firmThunks';

const FirmProfile = () => {
  const { content, inputFocused } = useStyles();
  const {
    padding,
    largestMarginLeft,
    mediumFormMarginRight,
    mediumMargin,
    tableHeaderFontBold,
    fullWidth,
    gridSpacing,
  } = useStyleHelpers();

  const dispatch = useStableDispatch();
  const { firmAttorneyList, firmAttorneyListSize, firmProfile } = useSelector(
    (store) => store.defaultReducer
  );

  const { firmName } = useParams();
  const { profile = {} } = useLocation();

  const [editingNote, setEditingNote] = useState(false);
  const [loading, setLoading] = useState(false);
  const [savedProfile, setSavedProfile] = useState(profile || firmProfile);

  const toggleEditingNote = () => setEditingNote(!editingNote);

  const listIsemptyOrAttorneyIsInFirm =
    !firmAttorneyList || !firmAttorneyList[0] || profile.firmName !== firmAttorneyList[0].firm;

  const firmProfileIsEmptyOrDifferent =
    JSON.stringify(firmProfile) === '{}' || firmName !== firmProfile.firmName;

  const savedProfileIsEmpty = JSON.stringify(savedProfile) === '{}';

  useEffect(() => {
    (async () => {
      if (firmProfileIsEmptyOrDifferent) {
        await dispatch(getFirmByName({ name: firmName }));
      }
    })();
    setSavedProfile(firmProfile);
  }, [dispatch, firmProfileIsEmptyOrDifferent, firmProfile, firmName]);

  useEffect(() => {
    (async () => {
      if (listIsemptyOrAttorneyIsInFirm) {
        setLoading(true);
        await dispatch(getAttorneyListByFirmName({ name: profile.firmName }));
        setLoading(false);
      }
    })();
  }, [dispatch, listIsemptyOrAttorneyIsInFirm, profile.firmName]);

  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <main className={content}>
      <MainHeader header={savedProfile.firmName} prefix={<BackButton />} justify="flex-start" />
      {!savedProfileIsEmpty && (
        <>
          <Formik
            initialValues={{
              city: savedProfile.city || '',
              practiceScope: savedProfile.practiceScope || '',
              compensationAvg: currencyFormatter.format(savedProfile.compensationAvg) || '',
              lawyersCnt: savedProfile.lawyersCnt || '',
              partnersCnt: savedProfile.partnersCnt || '',
              profitPerPartner: currencyFormatter.format(savedProfile.profitPerPartner) || '',
              netIncome: currencyFormatter.format(savedProfile.netIncome) || '',
              profitMargin: `${Math.round(savedProfile.profitMargin*100)}%` || '',
              profitMarginRanking: savedProfile.profitMargin || ''
            }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              resetForm();
              setSubmitting(false);
              toggleEditingNote();
            }}
          >
            {({ submitForm }) => (
              <Form
                className={`${padding} ${largestMarginLeft} ${mediumFormMarginRight}`}
                noValidate
                autoComplete="off"
              >
                <FormControl id="generalFirmInfo">
                  <FormLabel className={`${mediumMargin} ${tableHeaderFontBold}`}>
                    General Information
                  </FormLabel>
                  <Grid container spacing={2} className={gridSpacing}>
                    <Grid container item xs={12} spacing={2}>
                      <FirmProfileField
                        label="Location"
                        name="city"
                        type="text"
                        disabled={!editingNote}
                      />
                      <FirmProfileField
                        label="Practice Scope"
                        name="practiceScope"
                        disabled={!editingNote}
                      />
                      <FirmProfileField
                        label="Attorneys"
                        name="lawyersCnt"
                        disabled={!editingNote}
                      />
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                      <FirmProfileField
                        label="Partners"
                        name="partnersCnt"
                        disabled={!editingNote}
                      />
                      <FirmProfileField
                        label="Profits Per Equity Partner"
                        name="profitPerPartner"
                        disabled={!editingNote}
                      />
                      <FirmProfileField
                        label="Compensation Ave"
                        name="compensationAvg"
                        disabled={!editingNote}
                      />
                    </Grid>
                    <Grid container item xs={12} spacing={2}>
                      <FirmProfileField
                        label="Net Income"
                        name="netIncome"
                        disabled={!editingNote}
                      />
                      <FirmProfileField
                        label="Profit Margin"
                        name="profitMargin"
                        disabled={!editingNote}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
                <FormControl id="firmRankings" className={fullWidth}>
                  <FormLabel className={`${mediumMargin} ${tableHeaderFontBold}`}>
                    Rankings
                  </FormLabel>
                  <Field
                    component={TextField}
                    name="profitMarginRanking"
                    label="L500 Ranking"
                    InputProps={{
                      readOnly: !editingNote,
                    }}
                    variant="filled"
                    fullWidth
                    className={`${inputFocused}`}
                  />
                  <Field
                    component={TextField}
                    name="profitMarginRanking"
                    label="Chambers Ranking"
                    InputProps={{
                      readOnly: !editingNote,
                    }}
                    variant="filled"
                    fullWidth
                    className={`${inputFocused} ${mediumMargin}`}
                  />
                </FormControl>
                {/* these buttons for editing information */}
                {!!editingNote && (
                  <div className={mediumMargin}>
                    <SaveCancelButtons onSubmit={submitForm} onCancel={toggleEditingNote} />
                  </div>
                )}
              </Form>
            )}
          </Formik>
          <Loader loading={loading}>
            <AttorneyChips
              firmName={firmName}
              attorneyListSize={firmAttorneyListSize}
              attorneyList={firmAttorneyList}
            />
          </Loader>
        </>
      )}
    </main>
  );
};

export default FirmProfile;
