import React from 'react';
import { TableBody, TableCell, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import moment from 'moment';
import useStyles from '../../../styles/styles';
import TableNoData from '../../reusableComponents/TableNoData';

const AttorneysReadyToWorkResultsTableBody = ({
  attorneyList = [],
  attorneysSize = 0,
  page = 0,
}) => {
  const { outlinedButton } = useStyles();

  return (
    <TableBody>
      {(!!attorneysSize &&
        attorneyList[page].map(
          ({ fpId, name, firmName, location, workTogetherFrom, notDisturbTill }) => (
            <TableRow key={`attorneyReady${fpId}`}>
              <TableCell>{fpId}</TableCell>
              <TableCell>{name}</TableCell>
              <TableCell>{firmName}</TableCell>
              <TableCell>{location}</TableCell>
              <TableCell>{moment(workTogetherFrom).format('l LT')}</TableCell>
              <TableCell>{moment(notDisturbTill).format('l LT')}</TableCell>
              <TableCell>
                <Link
                  to={{
                    pathname: `/attorneysList/${fpId}`,
                  }}
                >
                  <Button className={outlinedButton} fullWidth variant="outlined" color="primary">
                    VIEW
                  </Button>
                </Link>
              </TableCell>
            </TableRow>
          )
        )) || <TableNoData colSpan={7} />}
    </TableBody>
  );
};

AttorneysReadyToWorkResultsTableBody.propTypes = {
  attorneyList: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
  attorneysSize: PropTypes.number,
  page: PropTypes.number,
};

AttorneysReadyToWorkResultsTableBody.defaultProps = {
  attorneyList: [],
  attorneysSize: 0,
  page: 0,
};

export default AttorneysReadyToWorkResultsTableBody;
