import { makeStyles } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core';
import theme from './theme.js';

const muiTheme = createMuiTheme();

const useMakeCallsStyles = makeStyles(
  {
    stick: {
      marginRight: '39px',
      minWidth: 180,
      minHeight: 65,
      borderRadius: 4,
      padding: '7px 13px',
      position: 'relative',
      color: theme.palette.white,
    },
    stickShadow: {
      width: 119,
      height: 42,

      position: 'absolute',
      bottom: 0,
      zIndex: -1,
      margin: '0 18px',
    },
    redStick: { background: theme.palette.stickRed },
    redStickShadow: { boxShadow: '0px 5px 30px rgba(232, 83, 72, 0.6)' },
    blackStick: { background: theme.palette.stickBlack },
    blackStickShadow: { boxShadow: '0px 5px 30px rgba(60, 61, 60, 0.6)' },
    stickTitle: {
      fontFamily: 'PT Sans',
      fontSize: '14px',
      lineHeight: '18px',
    },
    stickNumber: {
      fontFamily: 'PT Sans',
      fontSize: '24px',
      lineHeight: '31px',
      fontWeight: 'bold',
    },
    nextCallStickContainer: {
      width: 'fit-content',
      margin: '24px auto 0',
      '&>div': { marginRight: 0 },
    },
    row: { display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%' },
    simpleRow: { display: 'flex', alignItems: 'flex-end', width: '100%' },
    inputTitle: { paddingLeft: 12, paddingTop: 12 },
    noteField: { width: '100%', '& textarea': { minHeight: 100 } },
    popUpNoteHeight: { '&>.MuiInputBase-root>textarea': { minHeight: 80 } },
    lastCallMargin: { marginTop: theme.spacing[5], marginBottom: theme.spacing[4] },
    checkedListItem: {
      '&>div>.MuiCheckbox-root.Mui-checked': {
        color: theme.palette.main,
        backgroundImage: theme.palette.main,
      },
      '&': { paddingTop: 0, paddingBottom: 0 },
      '&>.MuiListItemIcon-root': { minWidth: 'fit-content' },
    },
    callSelectInToolbar: {
      '&': {
        paddingBottom: '10px',
      },
      '&>.MuiInputBase-root': {
        height: '40px',
      },
      '&>.MuiInputBase-root>.MuiSelect-root': {
        paddingTop: '15px',
        height: '40px',
        boxSizing: 'border-box',
      },
    },
    gridActionButtons: {
      '& .MuiGrid-grid-lg-6:nth-child(1), .MuiGrid-grid-xl-6:nth-child(1)': { paddingRight: '6px' },
      '& .MuiGrid-grid-lg-6:nth-child(2), .MuiGrid-grid-xl-6:nth-child(2)': { paddingLeft: '6px' },
      '&>.MuiGrid-container>.MuiGrid-item': { paddingBottom: theme.spacing[3] },
      [muiTheme.breakpoints.down('md')]: {
        '&>.MuiGrid-grid-md-12': {
          padding: '0 !important',
        },
      },
    },
  },
  { index: 1 }
);

export default useMakeCallsStyles;
