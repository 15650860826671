import React from 'react';
import { TextField } from 'formik-material-ui';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import useStyles from '../../../../styles/styles';

const FirmProfileField = ({ label, name, disabled, ...props }) => {
  const { inputFocused } = useStyles();
  return (
    <Grid item xs={12} xl={4} lg={4} md={4}>
      <Field
        key={label}
        component={TextField}
        name={name}
        label={label}
        InputProps={{
          readOnly: disabled,
        }}
        // disabled={disabled}
        variant="filled"
        fullWidth
        className={`${inputFocused}`}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...props}
      />
    </Grid>
  );
};

FirmProfileField.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  props: PropTypes.object,
};

FirmProfileField.defaultProps = {
  label: '',
  name: '',
  disabled: true,
  props: {},
};

export default FirmProfileField;
