import React, { useEffect, useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import {
  getJobFileList,
  getLastActiveFile,
  setJobActiveFile,
} from '../../../requests/jobRequests.js';
import useSearchStyles from '../../../styles/searchStyles.js';
import useStyles from '../../../styles/styles.js';

const CurrentFile = () => {
  const { fileSelect } = useSearchStyles();
  const { inputFocused } = useStyles();

  const [fileList, setFileList] = useState([]);
  const [activeFile, setActiveFile] = useState({});

  useEffect(() => {
    getJobFileList().then(setFileList);
  }, []);

  useEffect(() => {
    getLastActiveFile().then(({ id }) => setActiveFile(id));
  }, []);

  const handleChange = ({ target: { value } }) => {
    setJobActiveFile(value);
    setActiveFile(value);
  };

  return (
    <div>
      <FormControl variant="filled" className={`${fileSelect} ${inputFocused}`}>
        <InputLabel id="activeFile">Active File</InputLabel>
        <Select labelId="activeFile" id="activeFile" value={activeFile} onChange={handleChange}>
          {!!fileList &&
            !!fileList.length &&
            fileList.map(({ id, fileName }) => (
              <MenuItem key={id} value={id}>
                {fileName}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default CurrentFile;
