import React, { useEffect, useState } from 'react';
import { Query, Utils as QbUtils } from '@shadowdrone/react-awesome-query-builder';
import { Button, Toolbar } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AntdConfig from '@shadowdrone/react-awesome-query-builder/lib/config/antd';
import { sortBy } from 'lodash';

import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import useStyles from '../../../styles/styles.js';
import MainHeader from '../../reusableComponents/TableToolbar';
import useSearchStyles from '../../../styles/searchStyles.js';
import renderBuilder from './Builder.js';

import Cross from '../../../assets/icons/buttonIcons/Cross.svg';
import { useStableDispatch } from '../../../utils/hooks.js';
import {
  setJobFilterYears,
  setJobSearchConfig,
  setFilterName,
} from '../../../actions/jobActions.js';
import { DEFAULT_SEARCH_CONFIG } from '../../../constants';
import { mapConfig } from '../../../utils/search.js';
import { getJobSearchCriteria } from '../../../requests/jobRequests.js';
import { makeQuery } from './JobSearch.utils.js';
import SaveFilter from '../../reusableComponents/SaveFilter';
import CurrentFile from './CurrentFile.js';
import ChooseFilter from '../../reusableComponents/ChooseFilter';
import useStyleHelpers from '../../../styles/styleHelpers';

const JobSearch = () => {
  const { content, paper, button, linkButton, toolbar, buttonHeight, inputFocused } = useStyles();
  const { query, bottomSection, select, bottomSectionLeftSide } = useSearchStyles();
  const { largeMarginLeft } = useStyleHelpers();

  const { jobSearchConfig, jobFilterYears } = useSelector((store) => store.defaultReducer);
  const dispatch = useStableDispatch();

  const [tree, setTree] = useState(
    QbUtils.checkTree(QbUtils.loadTree(jobSearchConfig), AntdConfig)
  );
  const [config, setConfig] = useState(AntdConfig);

  const [saving, setSaving] = useState(false);

  const history = useHistory();

  useEffect(() => {
    (async () => {
      const keys = await getJobSearchCriteria();
      const sortedKeys = sortBy(keys, 'key');
      const configObject = mapConfig(sortedKeys);

      setConfig(configObject);
      setTree(QbUtils.checkTree(QbUtils.loadTree(jobSearchConfig), configObject));
    })();
  }, [jobSearchConfig]);

  const onChange = (immutableTree, newConfig) => {
    setTree(immutableTree);
    console.log(`saveTo server: ${JSON.stringify(QbUtils.getTree(immutableTree))}`);
    setConfig(newConfig);
  };

  const onChangeJobYears = (event) => {
    dispatch(setJobFilterYears(+event.target.value));
  };

  const onReset = () => {
    dispatch(setJobSearchConfig(DEFAULT_SEARCH_CONFIG));
    dispatch(setFilterName(''));
    dispatch(setJobFilterYears(null));
    setTree(QbUtils.checkTree(QbUtils.loadTree(DEFAULT_SEARCH_CONFIG), config));
  };

  const onSearch = () => {
    dispatch(setJobSearchConfig(QbUtils.getTree(tree)));
    console.info(makeQuery(QbUtils.sqlFormat(tree, config), jobFilterYears));
    history.push({
      pathname: '/jobSearch/results',
      state: { search: makeQuery(QbUtils.sqlFormat(tree, config), jobFilterYears) },
    });
  };

  return (
    <main className={content}>
      <MainHeader
        header="Job Search"
        justify="flex-start"
        suffix={
          <Button
            className={`${linkButton} ${buttonHeight} ${largeMarginLeft}`}
            onClick={() => {
              onReset();
            }}
            startIcon={<img src={Cross} alt="cross" />}
          >
            Reset
          </Button>
        }
      />
      <Toolbar className={toolbar}>
        {!saving && <CurrentFile />}
        {!saving && <ChooseFilter type="job" />}
        <SaveFilter
          disabled={!QbUtils.sqlFormat(tree, config)}
          condition={QbUtils.sqlFormat(tree, config)}
          setSavingState={setSaving}
          tree={tree}
          type="job"
          jobYears={jobFilterYears}
        />
      </Toolbar>
      <div className={`${paper} ${query}`}>
        <Query
          /* eslint-disable-next-line react/jsx-props-no-spreading */
          {...config}
          value={tree}
          onChange={onChange}
          renderBuilder={renderBuilder}
          className={query}
        />
      </div>
      <Formik
        initialValues={{ yearsAgo: jobFilterYears }}
        val
        onSubmit={(_, { setSubmitting }) => {
          setSubmitting(false);
          onSearch();
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form className={bottomSection} onChange={onChangeJobYears}>
            <div className={bottomSectionLeftSide}>
              <Field
                component={TextField}
                name="yearsAgo"
                label="Years ago associate graduated"
                type="number"
                variant="filled"
                className={`${select} ${inputFocused}`}
                inputProps={{ min: 1, value: +jobFilterYears || '' }}
              />
            </div>
            <Button
              variant="contained"
              color="primary"
              className={`${button} ${buttonHeight}`}
              onClick={submitForm}
              disabled={isSubmitting}
            >
              Search
            </Button>
          </Form>
        )}
      </Formik>
    </main>
  );
};

export default JobSearch;
