import React, { useEffect, useState } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import useStyles from '../../../styles/styles';
import useStyleHelpers from '../../../styles/styleHelpers';
import MainHeader from '../../reusableComponents/TableToolbar';
import { useStableDispatch } from '../../../utils/hooks';
import TableLoader from '../../reusableComponents/TableLoader';
import AccountListTableBody from './AccountListTableBody';
import { deleteAccount, getAccountList } from '../../../thunks/accountThunks';
import TableHeader from '../../reusableComponents/TableHeader';
import notification from '../../reusableComponents/notification';
import ConfirmationModal from '../../reusableComponents/ConfirmationModal';

const AccountsList = () => {
  const { content, paper, button, buttonHeight } = useStyles();
  const { marginRightAuto, largeMarginLeft } = useStyleHelpers();
  const dispatch = useStableDispatch();
  const {
    accountList,
    currentAccount,
    user: { role, userId },
  } = useSelector((store) => store.defaultReducer);
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState('');

  const isColdCaller = role === 'Cold caller';
  const accountListIsEmpty = !accountList || !accountList.length;

  const handleClickOpen = (id) => {
    setOpen(true);
    setDeleteUserId(id);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    (async () => {
      if (accountListIsEmpty || currentAccount.userId !== userId) {
        setLoading(true);
        await dispatch(getAccountList(userId));
        setLoading(false);
      }
    })();
  }, [dispatch, accountListIsEmpty, currentAccount, userId]);

  const onAccountDelete = async () => {
    try {
      await dispatch(deleteAccount(deleteUserId));
      handleClose();
      notification({ message: 'The user was deleted!', type: 'success' });
      history.push('/accountsList');
    } catch (e) {
      notification({ message: 'Something went wrong!', type: 'error' });
    }
  };

  return (
    <main className={content}>
      <MainHeader
        header="Manage accounts"
        suffix={
          <Link
            className={marginRightAuto}
            to={{
              pathname: '/addAccount',
              state: {
                userAccount: null,
                token: '',
              },
            }}
            disabled={isColdCaller}
          >
            <Button
              variant="contained"
              color="primary"
              className={`${largeMarginLeft} ${button} ${buttonHeight}`}
              disabled={isColdCaller}
            >
              Add Account
            </Button>
          </Link>
        }
      />
      <div className={paper}>
        <TableContainer component={Paper}>
          <Table>
            <TableHeader headers={['Username', 'Role', 'Account Status', '', 'Actions']} />
            {loading ? (
              <TableLoader />
            ) : (
              <AccountListTableBody
                handleClickOpen={handleClickOpen}
                accountList={isColdCaller ? [currentAccount] : accountList}
                disabled={isColdCaller}
              />
            )}
          </Table>
        </TableContainer>
      </div>
      <ConfirmationModal
        title="Are you sure you want to delete this user?"
        description="The action cannot be canceled"
        cancelButtonText="Cancel"
        confirmButtonText="Ok"
        state={open}
        onCancelAction={handleClose}
        onConfirmAction={onAccountDelete}
      />
    </main>
  );
};

export default AccountsList;
