import React, { useEffect, useState } from 'react';
import { Query, Utils as QbUtils } from '@shadowdrone/react-awesome-query-builder';
import 'antd/dist/antd.css';
import '@shadowdrone/react-awesome-query-builder/lib/css/styles.css';
import * as _ from 'lodash';

import '@shadowdrone/react-awesome-query-builder/lib/css/compact_styles.css';
import { useSelector } from 'react-redux';
import AntdConfig from '@shadowdrone/react-awesome-query-builder/lib/config/antd';
import { useHistory } from 'react-router-dom';
import { Button, Toolbar } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import renderBuilder from './Builder.js';
import useStyles from '../../../styles/styles.js';
import useSearchStyles from '../../../styles/searchStyles.js';
import MainHeader from '../../reusableComponents/TableToolbar';
import { useStableDispatch } from '../../../utils/hooks.js';
import { mapConfig } from '../../../utils/search.js';
import { getAttorneySearchCriteria } from '../../../requests/attorneyRequests.js';
import { DEFAULT_SEARCH_CONFIG } from '../../../constants';
import Cross from '../../../assets/icons/buttonIcons/Cross.svg';
import { setAttorneySearchConfig } from '../../../actions/attorneyActions.js';
import LeftSideSection from './LeftSideSection.js';
import ChooseFilter from '../../reusableComponents/ChooseFilter/ChooseFilter.js';
import SaveFilter from '../../reusableComponents/SaveFilter';
import useStyleHelpers from '../../../styles/styleHelpers';
import { setFilterName } from '../../../actions/jobActions';
import notification from '../../reusableComponents/notification';
import { createCallListRequest } from '../../../requests/callRequests';
import Loader from '../../reusableComponents/Loader';

const AttorneysSearch = () => {
  const { attorneySearchConfig } = useSelector((store) => store.defaultReducer);
  const dispatch = useStableDispatch();

  const [tree, setTree] = useState(
    QbUtils.checkTree(QbUtils.loadTree(attorneySearchConfig), AntdConfig)
  );
  const [config, setConfig] = useState(AntdConfig);

  const { content, paper, linkButton, button, buttonHeight, toolbar, outlinedButton } = useStyles();
  const { query, bottomSection, bottomSectionLeftSide } = useSearchStyles();
  const { largeMarginLeft, fitContentWidth, marginAuto, searchButtonWidth } = useStyleHelpers();

  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [lessThan, setLessThan] = useState('');
  const [moreThan, setMoreThan] = useState('');

  const disabled = !QbUtils.sqlFormat(tree, config) || loading;

  const history = useHistory();

  useEffect(() => {
    (async () => {
      const keys = await getAttorneySearchCriteria();
      //console.log({ keys })
      //force case insensitive
      //keys.filter(kItem => kItem.type === "text" || kItem.type === "select").map(kItem => kItem.key = "lower("+kItem.key+")");
      //console.log({ keys })
      const sortedKeys = _.sortBy(keys, 'label');
      const configObject = mapConfig(sortedKeys);

      setConfig(configObject);
      setTree(QbUtils.checkTree(QbUtils.loadTree(attorneySearchConfig), configObject));
    })();
  }, [attorneySearchConfig]);

  const onChange = (immutableTree, newConfig) => {
    setTree(immutableTree);
    console.log(`saveTo2 server: ${JSON.stringify(QbUtils.getTree(immutableTree))}`);
    setConfig(newConfig);
    setLoading(false);
  };

  const onLessThanChange = ({ target }) => {
    setLessThan(target.value);
  };

  const onMoreThanChange = ({ target }) => {
    setMoreThan(target.value);
  };

  const onReset = () => {
    dispatch(setAttorneySearchConfig(DEFAULT_SEARCH_CONFIG));
    dispatch(setFilterName(''));
    setTree(QbUtils.checkTree(QbUtils.loadTree(DEFAULT_SEARCH_CONFIG), config));
  };

  const onSearch = () => {
    dispatch(setAttorneySearchConfig(QbUtils.getTree(tree)));
    console.log({ search: QbUtils.sqlFormat(tree, config) });
    console.log({ tree: tree})

    history.push({
      pathname: '/attorneysSearch/results',
      state: { search: QbUtils.sqlFormat(tree, config), lessThan, moreThan, displayColumnNames: getColumnNames() },
    });
  };

  const onFormList = async () => {
    await dispatch(setAttorneySearchConfig(QbUtils.getTree(tree)));
    const search = QbUtils.sqlFormat(tree, config);
    if (!search) return;
    setLoading(true);
    try {
      const id = await createCallListRequest(search);
      if (!id || typeof id !== 'number') {
        throw new Error('Something went wrong!');
      }
      setLoading(false);
      history.push({
        pathname: `/attorneysSearch/createCalls/${id}`,
        state: { search: QbUtils.sqlFormat(tree, config), lessThan, moreThan },
      });
    } catch (e) {
      notification({ message: 'Something went wrong!', type: 'error' });
      setLoading(false);
    }
  };

  const getColumnNames = () => {
    return "";
    /* seems that this is not needed, because already have all columns
    try {
      const jsonInfo = JSON.stringify(QbUtils.getTree(tree));
      const rx = /\"field\"\:\"((\w|\_|\#|\{|\}|\.)+)\"\,/g;
      const names = [...new Set([...jsonInfo.matchAll(rx)].map( s => s[1]))];;
      
      console.log(names)
      return names;
    }
    catch(ex){
      console.error(ex);
      return "";  
    }
     */
    
  }

  return (
    <main className={content}>
      <MainHeader
        header="Attorneys search"
        justify="flex-start"
        suffix={
          <Button
            className={`${linkButton} ${buttonHeight} ${largeMarginLeft}`}
            onClick={onReset}
            startIcon={<img src={Cross} alt="cross" />}
          >
            Reset
          </Button>
        }
      />
      <Toolbar className={toolbar}>
        {!saving && <ChooseFilter type="attorney" />}
        <SaveFilter
          condition={QbUtils.sqlFormat(tree, config)}
          disabled={disabled}
          setSavingState={setSaving}
          tree={tree}
          type="attorney"
        />
      </Toolbar>
      <Loader loading={loading}>
        <div className={`${paper} ${query}`}>
          <Query
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...config}
            value={tree}
            onChange={onChange}
            renderBuilder={renderBuilder}
            className={query}
          />
        </div>
      </Loader>
      <div className={bottomSection}>
        <div className={bottomSectionLeftSide}>
          <LeftSideSection
            lessThan={lessThan}
            moreThan={moreThan}
            onLessThanChange={onLessThanChange}
            onMoreThanChange={onMoreThanChange}
          />
        </div>
        <Grid container spacing={3} className={fitContentWidth}>
          <Grid item lg={6} md={6} xl={6} xs={12} sm={12} className={marginAuto}>
            <Button
              variant="outlined"
              color="primary"
              className={`${outlinedButton} ${buttonHeight} ${searchButtonWidth}`}
              onClick={onFormList}
              disabled={disabled}
            >
              form a list
            </Button>
          </Grid>
          <Grid item lg={6} md={6} xl={6} xs={12} sm={12} className={marginAuto}>
            <Button
              variant="contained"
              color="primary"
              className={`${button} ${buttonHeight} ${searchButtonWidth}`}
              onClick={onSearch}
              disabled={disabled}
            >
              Search
            </Button>
          </Grid>
        </Grid>
      </div>
    </main>
  );
};

export default AttorneysSearch;
