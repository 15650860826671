import React from 'react';
import { TableRow } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import TableBody from '@material-ui/core/TableBody';
import PropTypes from 'prop-types';
import { Done } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Edit from '../../../../assets/icons/buttonIcons/Edit-black.svg';
import useStyleHelpers from '../../../../styles/styleHelpers';
import useStyles from '../../../../styles/styles';
import { periods } from './ColdCalls.utils';

const QueueConfigsTableBody = ({
  queueConfigList,
  editIndex,
  startEditing,
  stopEditing,
  handleChange,
  numberFieldError,
  numberHelperText,
  callActions,
}) => {
  const { colorBlack } = useStyleHelpers();
  const { inputReadOnly, inputFocused } = useStyles();

  return (
    <TableBody>
      {!!queueConfigList &&
        !!queueConfigList.length &&
        queueConfigList.map(({ id, resultName, takeOutInterval, takeOutPeriod, actionCd }, i) => {
          const action = callActions.find((item) => item.actionCode === actionCd);
          const period = periods.find((item) => {
            return item.periodCode === takeOutPeriod;
          });
          const currentlyEditing = editIndex === i;
          return (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow key={`queueConfig${i}`}>
              {!currentlyEditing ? (
                <>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        startEditing(i);
                      }}
                    >
                      <img src={Edit} alt="edit" />
                    </IconButton>
                  </TableCell>
                  <TableCell>{id.phaseNo}</TableCell>
                  <TableCell>{resultName}</TableCell>
                  <TableCell>{id.attemptNo}</TableCell>
                  <TableCell>{action ? action.actionName : ''}</TableCell>
                  <TableCell>{takeOutInterval}</TableCell>
                  <TableCell>{period ? period.periodName : ''}</TableCell>
                </>
              ) : (
                <>
                  <TableCell>
                    <IconButton
                      disabled={numberFieldError}
                      className={colorBlack}
                      onClick={() => {
                        stopEditing(i);
                      }}
                    >
                      <Done />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <TextField
                      className={inputReadOnly}
                      name="phaseNo"
                      value={id.phaseNo}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      className={inputReadOnly}
                      name="resultName"
                      value={resultName}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      className={inputReadOnly}
                      name="attemptNo"
                      type="number"
                      value={id.attemptNo}
                      fullWidth
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      className={inputFocused}
                      name="actionCd"
                      select
                      value={actionCd}
                      fullWidth
                      SelectProps={{
                        native: true,
                      }}
                      onChange={(e) => {
                        handleChange(e, i);
                      }}
                    >
                      {!!callActions &&
                        !!callActions.length &&
                        callActions.map((item) => (
                          <option key={item.actionCode} value={item.actionCode}>
                            {item.actionName}
                          </option>
                        ))}
                    </TextField>
                  </TableCell>
                  <TableCell>
                    <TextField
                      className={inputFocused}
                      error={numberFieldError}
                      helperText={numberHelperText}
                      name="takeOutInterval"
                      type="number"
                      value={takeOutInterval}
                      fullWidth
                      inputProps={{
                        min: '1',
                      }}
                      onChange={(e) => {
                        handleChange(e, i);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <FormControl>
                      <TextField
                        className={inputFocused}
                        select
                        name="takeOutPeriod"
                        value={takeOutPeriod}
                        fullWidth
                        SelectProps={{
                          native: true,
                        }}
                        onChange={(e) => {
                          handleChange(e, i);
                        }}
                      >
                        <option value="">None</option>
                        {periods.map((item) => (
                          <option key={item.periodCode} value={item.periodCode}>
                            {item.periodName}
                          </option>
                        ))}
                      </TextField>
                    </FormControl>
                  </TableCell>
                </>
              )}
            </TableRow>
          );
        })}
    </TableBody>
  );
};

QueueConfigsTableBody.propTypes = {
  queueConfigList: PropTypes.arrayOf(PropTypes.object),
  callActions: PropTypes.arrayOf(PropTypes.object),
  editIndex: PropTypes.number,
  startEditing: PropTypes.func,
  stopEditing: PropTypes.func,
  handleChange: PropTypes.func,
  numberFieldError: PropTypes.bool,
  numberHelperText: PropTypes.string,
};

QueueConfigsTableBody.defaultProps = {
  queueConfigList: [],
  callActions: [],
  editIndex: -1,
  startEditing: () => {},
  stopEditing: () => {},
  handleChange: () => {},
  numberFieldError: false,
  numberHelperText: '',
};

export default QueueConfigsTableBody;
