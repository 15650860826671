import React from 'react';
import { TableRow } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import TableBody from '@material-ui/core/TableBody';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import useStyles from '../../../styles/styles';

const ResultsTableBody = ({ attorneyList, page }) => {
  const { outlinedButton } = useStyles();
  return (
    <TableBody>
      {!!attorneyList &&
        !!attorneyList.length &&
        attorneyList[page] &&
        attorneyList[page].map(
          ({
            id = '',
            name = '',
            title = '',
            firm = '',
            location = '',
            email = '',
            ...profile
          }) => (
            <TableRow key={id}>
              <TableCell>{id}</TableCell>
              <TableCell>{name}</TableCell>
              <TableCell>{title}</TableCell>
              <TableCell>{firm}</TableCell>
              <TableCell>{location}</TableCell>
              <TableCell>{email}</TableCell>
              <TableCell>
                <Link
                  to={{
                    pathname: `/attorneysList/${id}`,
                    profile: { name, title, firm, location, email, ...profile },
                  }}
                >
                  <Button className={outlinedButton} fullWidth variant="outlined" color="primary">
                    VIEW
                  </Button>
                </Link>
              </TableCell>
            </TableRow>
          )
        )}
    </TableBody>
  );
};

ResultsTableBody.propTypes = {
  attorneyList: PropTypes.arrayOf(PropTypes.any),
  page: PropTypes.number,
};

ResultsTableBody.defaultProps = {
  attorneyList: [],
  page: 0,
};

export default ResultsTableBody;
