import { setRoleList } from '../actions/roleActions';
import { getRoleRequest } from '../requests/roleRequests';

// eslint-disable-next-line import/prefer-default-export
export const getRoleList = () => {
  return async (dispatch) => {
    const { roleList } = await getRoleRequest();
    dispatch(setRoleList(roleList));
  };
};
