import makeStyles from '@material-ui/core/styles/makeStyles.js';
import theme from './theme.js';

const useTableStyles = makeStyles(
  {
    noData: { textAlign: 'center' },
    image: { padding: theme.spacing[5], height: '100%' },
    tableTextAlignWithoutFirstChild: {
      '& .MuiTableRow-root .MuiTableCell-root:not(:first-child)': { textAlign: 'center' },
    },
    textAlignLastChild: {
      '& .MuiTableRow-root .MuiTableCell-root:last-child': { textAlign: 'center' },
    },
    boxShadow: { boxShadow: '0 0 black' },
    tableHeaderFontBold: {
      fontFamily: 'PT Sans Bold',
      fontSize: '18px',
      fontStyle: 'bold',
      color: theme.palette.black,
    },
    checkedRow: {
      '&.Mui-selected': { backgroundColor: theme.palette.lightPink },
      '&.MuiTableRow-root:hover': { backgroundColor: 'none' },
      '&>td>.MuiCheckbox-root.Mui-checked': {
        color: theme.palette.main,
        backgroundImage: theme.palette.main,
      },
    },
  },
  { index: 1 }
);

export default useTableStyles;
