export const attExcelTitles = [
  { label: "FPID", key: "id" },
  { label: "First Name", key: "firstName" },
  { label: "Full Name", key: "name" },
  { label: "Middle Name", key: "middleName" },
  { label: "Last Name", key: "lastName" },
  { label: "Title", key: "title" },
  { label: "Firm Name", key: "firm" },
  { label: "Location", key: "location" },
  { label: "Email Address", key: "email" },
  { label: "Phone", key: "phone" },
  { label: "Bar Admissions", key: "barAdmission" },
  { label: "Practice Area (Crude)", key: "practiceAreas", comment:"Highest (most abstract) level of classification of the lawyer's practice area. (This is from the original classification software, which is less accurate, so we don't use this.)" },
  { label: "Specialization (Crude)", key: "specialties" , comment: "2nd Level classification of lawyer's practice Area. (This is from the original classification software, which is less accurate, so we don't use this.)"},
  { label: "Law School", key: "lawSchool" },
  { label: "Grad. Year", key: "graduationYear" },
  { label: "Undergrad. School", key: "undergraduate" },
  { label: "Honors", key: "honors" },
  { label: "Advanced Degree", key: "advanced_degree" },
  { label: "Education", key: "education", comment: "Sentence in the bio that contains the educational information" },
  { label: "Languages", key: "languages" },
  { label: "Bio URL", key: "profileUrl" },
  { label: "AmLaw 200 Rank", key: "top200" },
  { label: "Vault 50 Rank", key: "vault50" },
  { label: "Vault 10 Rank", key: "vault10" },
  { label: "Status", key: "status" },
  { label: "Biography", key: "fullBio" },
  { label: "Sector (Primary)", key: "primarySectors", comment:"Sector: The industry vertical that the attorney practices in\nPrimary Sector: The industry vertical that, based on the language of the attorney's bio, they spend the most time in." },
  { label: "Class (Primary)", key: "primaryClass" , comment:"Class: A generalized area of law.\nPrimary Class: The general area of law that, based on the language of the attorney's bio, they spend the most time in."},
  { label: "Class (Secondary)", key: "secondaryClass" , comment: "Class: A generalized area of law.\nSecondary Class: The general area of law that, based on the language of the attorney's bio, they spend less concentrated time in."},
  { label: "Practices V1 (Tertiary)", key: "remainingPractices", comment: "Tertiary Practice/s: These are inconsequential areas of practice for the attorney. I.e., they are significantly underrepresented in the attorney's bio, in comparison to the other classifications.\nPractice: The mode of attorney classification, this represents attorney's specific (actual) area/s of expertise.\nPractice Structure: Comprised of two parts: [Umbrella/Niche] [actual function]. For example: \"M&A Regulatory\/Compliance\" = [M&A] [regulatory\/compliance]." },
  { label: "Practices V1 (Primary)", key: "primaryPractices", comment: "Primary Practice/s: The practice/s that are significantly overrepresented in the attorney's bio, in comparison to the other classifications.\nPractice: The mode of attorney classification, this represents attorney's specific (actual) area/s of expertise.\nPractice Structure: Comprised of two parts: [Umbrella/Niche] [actual function]. For example: \"M&A Regulatory/Compliance\" = [M&A] [regulatory/compliance]." },
  { label: "Practices V1 (Secondary)", key: "secondaryPractices", comment: "Secondary Practice/s: The practice that, based on the language of the attorney's bio, they spend some, but a smaller percentage of their time in.\nPractice: The mode of attorney classification, this represents attorney's specific (actual) area/s of expertise.\nPractice Structure: Comprised of two parts: [Umbrella/Niche] [actual function]. For example: \"M&A Regulatory/Compliance\" = [M&A] [regulatory/compliance]." },
  { label: "Points Breakdown", key: "pointsBreakdown", comment: "The representation of points across the various classifications." },
  { label: "Practices V2 (Primary)", key: "jobsPracticePrimary", comment: "Practice: the primary mode of attorney classification. Comprised of two parts [Umbrella/Niche] [actual function]. For example: M&A Regulatory/Compliance - [M&A] [regulatory/compliance].\nJobs Practice: a practice classifications whose grouping is based on the Umbrella/Niche (specifically) as opposed to the classification on the whole.\nJobs Primary Practice: The practice grouping that, based on the language of the attorney's bio, they spend the most time in." },
  { label: "Practices V2 (Secondary)", key: "jobsPracticeSecondary", comment: "Practice: the primary mode of attorney classification. Comprised of two parts [Umbrella/Niche] [actual function]. For example: M&A Regulatory/Compliance - [M&A] [regulatory/compliance].\nJobs Practice: a practice classifications whose grouping is based on the Umbrella/Niche (specifically) as opposed to the classification on the whole.\nJobs Primary Practice: The practice grouping that, based on the language of the attorney's bio, they spend the most time in." },
  { label: "Gender", key: "gender", comment: "The assumed gender of the attorney based on the commonly understood gender of their name." },
  { label: "Degree (Standardized)", key: "classifiedDegree", comment: "The standardized titles/classifications that our software gives to the attorney's degree/s." },
  { label: "Leadership Classifications", key: "leadershipMatches", comment: "Marks the existence of \"leadership\" keywords\/language in the attorney's bio." },
  { label: "Leadership Sentence", key: "leadershipSource", comment: "The sentence in the biography that has leadership-based language." },
  { label: "Attorney Age", key: "attorneyAge", comment: "Generalized attorney age based on assumption that the attorney graduated law school at the approximate age of 24." },
];

export const demographicExcelTitles = [
  { label: "Firm Name", key: "firm" },
  { label: "Associates Total (Search)", key: "searchAssociatesTotalCnt", comment: "The number of associates within the parameters of the search." },
  { label: '"Attorneys" Total (Search)', key: "searchAttorneysTotalCnt", comment: "The number of \"attorneys\" (sometimes firms use this label instead of \"associate\") within the parameters of the search." },
  { label: "Counsels Total (Search)", key: "searchCounselsTotalCnt", comment: "The number of Of Counsel within the parameters of the search." },
  { label: "Partners Total (Search)", key: "searchPartnersTotalCnt", comment: "The number of Partners within the parameters of the search." },
  { label: "Lawyers Total (Search)", key: "searchTotalCnt", comment: "Total number of lawyers - i.e., all associates/attorneys/counsels and partners - within the parameters of the search." },
  { label: "Leverage Ratio (Search)", key: "searchLeverageRatio", comment: "The leverage ratio of associates to partners within the parameters of the search." },
  { label: "Male/Female Ratio (Search)", key: "searchMaleFemaleRatio", comment: "The ratio of males to females within the parameters of the search." },
  { label: "Associates Total (Firmwide)", key: "firmAssociatesTotalCnt", comment: "Total number of associates within all of the domestic offices of the firm." },
  { label: '"Attorneys" Total (Firmwide)', key: "firmAttorneysTotalCnt", comment: "Total number of \"attorneys\" (sometimes firms use this label instead of \"associate\") within all of the domestic offices of the firm." },
  { label: "Counsels Total (Firmwide)", key: "firmCounselsTotalCnt", comment: "Total number of Of Counsels within all of the domestic offices of the firm." },
  { label: "Partners Total (Firmwide)", key: "firmPartnersTotalCnt", comment: "Total number of partners within all of the domestic offices of the firm." },
  { label: "Lawyers Total (FirmWide)", key: "firmTotalCnt", comment: "The total number of lawyers within all domestic offices of the firm." },
  { label: "Leverage Ratio (Firmwide)", key: "firmLeverageRatio", comment: "The leverage of all associates to all partners within all domestic offices of the firm." },
  { label: "Male/Female Ratio (Firmwide)", key: "firmMaleFemaleRatio", comment: "The ratio of males to females within all of the domestic offices of the firm." },
  { label: "Firm Headquarters", key: "headQuarters", comment: "Firm's headquarter's location - per American Lawyer." },
  { label: "Lawyer Headcount (AmLaw)", key: "lawyerHeadCnt", comment: "Firm's total headcount - per American Lawyer. This may differ from \"Lawyers Total (FirmWide)\" column, as they may be based on different data sets." },
  { label: "Gross Revenue (AmLaw)", key: "grossRevenue", comment: "American Lawyer numbers - Gross Revenue." },
  { label: "RPL (AmLaw)", key: "revenuePerLawyer", comment: "American Lawyer numbers - Revenue Per Lawyer." },
  { label: "PPP (AmLaw)", key: "profitsPerPartner", comment: "American Lawyer numbers - Profits per (equity) Partner." },
  { label: "AmLaw 200 Ranking", key: "amLaw200", comment: "Actual American Lawyer Rank." },
  { label: "Net Income (AmLaw)", key: "netIncome", comment: "American Lawyer numbers - Firm's Net Income." },
  { label: "Profit Margin (AmLaw)", key: "profitMargin", comment:"American Lawyer numbers - Firm's Profit Margin." },
  { label: "Chambers.com Rankings", key: "chambersRanking" },
  { label: "Vault.com Rankings", key: "vaultRanking" },
  { label: "Legal500.com Rankings", key: "legal500Ranking" },
  { label: "Chambers.com Rankings (Search)", key: "chambersRankingFiltered", comment:"Chambers Rankings that are specific/pertinent to the parameters of the current search." },
  { label: "Vault.com Rankings (Search)", key: "vaultRankingFiltered", comment: "Vault Rankings that are specific/pertinent to the parameters of the current search." },
  { label: "Legal500.com Rankings (Search)", key: "legal500RankingFiltered", comment: "Legal500 Rankings that are specific/pertinent to the parameters of the current search." },

  //Equity
  { label: "Equity Partners", key: "equityPartners", comment: "The Number of Equity Partners at the Firm (as a whole)." },
  { label: "Non-equity Partners", key: "nonEquityPartners", comment: "The Number of Non-equity partners at the firm (as a whole)." },
  { label: "Ratio Non-equity/Equity Partner", key: "nonEquityEquityPartnerRatio", comment: "The ratio of Non-equity partners to Equity partners; i.e., the number of Non-equity partners for each Equity partner. " },
  { label: "Z-Score  N-eq/Eq Partners", key: "zScoreNonEqEqPartners", comment: "This is the Z-Score of a firm's N-eq/Eq Partner ratio. A Z-score (also called a standard score) gives you an idea of how far from the mean a data point is; i.e., a measure of how many standard deviations below or above the population mean a raw score is. This gives us a good way to compare results to a average, or “normal,” figure." },

  //Education
  { label: "Top 10 law schools (% of pop.)", key: "eduTop10LawSchools", comment: "The percentage of the firm's ranks (all attorney levels) that graduated from one of the top 20 law schools (as ranked by the U.S. News & World Report)." },
  { label: "Top 20 law schools (% of pop.)", key: "eduTop20LawSchools", comment: "The percentage of the firm's ranks (all attorney levels) that graduated from one of the top 20 law schools (as ranked by the U.S. News & World Report)." },
  { label: "Top 30 law schools (% of pop.)", key: "eduTop30LawSchools", comment: "The percentage of the firm's ranks (all attorney levels) that graduated from one of the top 30 law schools (as ranked by the U.S. News & World Report)." },
  { label: "Top 31-65 law schools (% of pop.)", key: "eduTop31to65LawSchools", comment: "The percentage of the firm's ranks (all attorney levels) that graduated from within the band of 31-65 top ranked law schools (as ranked by the U.S. News & World Report)." },
  { label: "Top 66-105 law schools (% of pop.)", key: "eduTop66to105LawSchools", comment: "The percentage of the firm's ranks (all attorney levels) that graduated from within the band of 66-105 top ranked law schools (as ranked by the U.S. News & World Report)." },
  { label: "NOT top 105 schools (% of pop.)", key: "eduNotTop105LawSchools", comment: "The percentage of the firm's ranks (all attorney levels) that DID NOT graduate from one of the top 105 ranked law schools (as ranked by the U.S. News & World Report)." },

  //Internationality
  { label: "Domestic Offices", key: "intCountDomesticCities", comment: "The number of domestic (to the US) offices the firm currently has." },
  { label: "International Offices", key: "intCountInternationalCities", comment: "The number of international (outside of the US) offices the firm currently has." },
  { label: "Ratio Intern./Dom. Cities", key: "intIntDomesticOfficesRatio", comment: "The ratio of international offices to domestic offices; i.e., the number of international offices for each domestic offices" },
  { label: "Z-Score Int/Dom", key: "zScoreNonIntDomesticOffices", comment: "This is the Z-Score a firm's Iternational/Domestic offices. A Z-score (also called a standard score) gives you an idea of how far from the mean a data point is; i.e., a measure of how many standard deviations below or above the population mean a raw score is. This gives us a good way to compare results to a average, or “normal,” figure." },
  { label: "International Cities", key: "internationalOffices", comment: "These are the international cities in which the firms have offices." },

];

export const jobsExcelTitles = [
  { label: "FPID", key: "fpId" },
  { label: "Firm Name", key: "firmName" },
  { label: "Location", key: "location" },
  { label: "Job Title", key: "jobTitle" },
  { label: "Position Title", key: "title" },
  { label: "Job Description", key: "jobDesc" },
  { label: "Job URL", key: "jobLink" },
  { label: "Practice Area (Crude)", key: "practiceAreas", comment: "Highest (most abstract) level of classification of the lawyer's practice area. (This is from the original classification software, which is less accurate, so we don't use this.)" },
  { label: "Specialization (Crude)", key: "specialty", comment: "2nd Level classification of lawyer's practice Area. (This is from the original classification software, which is less accurate, so we don't use this.)" },
  { label: "Minimum Years", key: "minYears", comment: "The required minumum tenure for the position.\nNote: Sometimes we employ a \"fuzzy search\" - +/- 2 years as firms are often (usually) flexible on this. If you have questions, contact your recruiter" },
  { label: "Maximum Years", key: "maxYears", comment: "The required maximum tenure for the position.\nNote: Sometimes we employ a \"fuzzy search\" - +/- 2 years as firms are often (usually) flexible on this. If you have questions, contact your recruiter." },
  { label: "Degrees", key: "degrees", comment: "These are the required degrees for the position." },
  { label: "Languages", key: "languages" },
  { label: "Job URL", key: "onlineLink" },
  { label: "HR Email", key: "contactEmail" },
  { label: "Original Post Date", key: "datePosted" },
  { label: "Modification Date", key: "dateModified" },
  { label: "Current Status", key: "status" },
  { label: "Open to Recruiters", key: "isRecruter" },
  { label: "Sector (Primary)", key: "primarySectors", comment: "Sector: The industry vertical that the attorney practices in.\nPrimary Sector: The industry vertical that, based on the language of the attorney's bio, they spend the most time in." },
  { label: "Practices V1 (Primary)", key: "primaryPractices", comment: "Primary Practice/s: The practice/s that are significantly overrepresented in the attorney's bio, in comparison to the other classifications.\nPractice: The mode of attorney classification, this represents attorney's specific (actual) area/s of expertise.\nPractice Structure: Comprised of two parts: [Umbrella/Niche] [actual function]. For example: \"M&A Regulatory/Compliance\" = [M&A] [regulatory/compliance]." },
  { label: "Practices V1 (Secondary)", key: "secondaryPractices", comment: "Secondary Practice/s: The practice that, based on the language of the attorney's bio, they spend some, but a smaller percentage of their time in.\nPractice: The mode of attorney classification, this represents attorney's specific (actual) area/s of expertise.\nPractice Structure: Comprised of two parts: [Umbrella/Niche] [actual function]. For example: \"M&A Regulatory/Compliance\" = [M&A] [regulatory/compliance]." },
  { label: "Degrees (Processed)", key: "classified_degree", comment: "This is the formal categorization of the degree names." },
  { label: "Practices V2 (Primary)", key: "jobs_practice_primary", comment: "Practice V2: An algorithmically different way of classifying the bio (from V1), where the grouping is based on the Umbrella/Niche (specifically) as opposed to the classification on the whole. And, based on the language of the attorney's bio, they spend the most time in.\nFor example, a classification is comprised of two parts [Umbrella/Niche] [actual function]. For example: M&A Regulatory/Compliance - [M&A] [regulatory/compliance]. This grouping would be based on \"M&A\", rather than the whole." },
  { label: "Practices V2 (Secondary)", key: "jobs_practice_secondary", comment: "Practice V2: An algorithmically different way of classifying the bio (from V1), where the grouping is based on the Umbrella/Niche (specifically) as opposed to the classification on the whole. And, based on the language of the attorney's bio, they spend a smaller portion of their time in.\nFor example, a classification is comprised of two parts [Umbrella/Niche] [actual function]. For example: M&A Regulatory/Compliance - [M&A] [regulatory/compliance]. This grouping would be based on \"M&A\", rather than the whole." },
];
export const numberToExcelLetters = (num) => {
    let letters = ''
    while (num >= 0) {
        letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[num % 26] + letters
        num = Math.floor(num / 26) - 1
    }
    return letters
  };


  export const addExcelComment = (cell, comment) => {
    if(!(cell.c)) 
        cell.c = [];
    
    cell.c.hidden = true;
    cell.c.push({t:comment, T: true});

  };