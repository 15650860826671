// eslint-disable-next-line import/prefer-default-export
export const periods = [
  {
    periodCode: 'H',
    periodName: 'Hour',
  },
  {
    periodCode: 'D',
    periodName: 'Day',
  },
  {
    periodCode: 'W',
    periodName: 'Week',
  },
  {
    periodCode: 'M',
    periodName: 'Month',
  },
  {
    periodCode: 'Y',
    periodName: 'Year',
  },
];
