import React from 'react';
import { TableBody, TableRow } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';
import TableNoData from '../../reusableComponents/TableNoData';
import useStyleHelpers from '../../../styles/styleHelpers';

const LogReportTableBody = ({ logReportList, page, rowsPerPage }) => {
  const { textAlignCenter } = useStyleHelpers();

  return (
    <TableBody>
      {(!!logReportList &&
        !!logReportList.length &&
        logReportList
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map(({ eventDate, eventCd, eventMessage, errLevel }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <TableRow key={`log-report${index}`}>
              <TableCell>{new Date(eventDate).toLocaleString('en-US')}</TableCell>
              <TableCell>{eventCd}</TableCell>
              <TableCell>{eventMessage}</TableCell>
              <TableCell className={textAlignCenter}>{errLevel}</TableCell>
            </TableRow>
          ))) || <TableNoData colSpan={3} />}
    </TableBody>
  );
};

LogReportTableBody.propTypes = {
  logReportList: PropTypes.arrayOf(PropTypes.object),
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
};

LogReportTableBody.defaultProps = {
  logReportList: [],
  page: 0,
  rowsPerPage: 5,
};
export default LogReportTableBody;
