import React from 'react';
import { Checkbox, TableBody, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TableNoData from '../../reusableComponents/TableNoData';
import useStatusBarsStyles from '../../reusableComponents/StatusBars/StatusBars.styles';
import useStyleHelpers from '../../../styles/styleHelpers';
import useStyles from '../../../styles/styles';
import useTableStyles from '../../../styles/tableStyles';
import TablePopup from '../JobSearchResults/TablePopup';

const CreatedCallsResultsTableBody = ({
  createdCallListSize,
  createdCallList,
  page,
  isSelected,
  handleClick,
}) => {
  const { merged, uploaded, error } = useStatusBarsStyles();
  const { statusWidth, textCenter } = useStyleHelpers();
  const { outlinedButton } = useStyles();
  const { checkedRow } = useTableStyles();

  return (
    <TableBody>
      {(!!createdCallListSize &&
        !!createdCallList[page] &&
        createdCallList[page].map((item) => {
          const isItemSelected = isSelected(item);
          const { id, name, firmName, add2Queue, errCtx, errCd } = item;

          return (
            <TableRow
              key={id.fpId}
              hover
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              selected={isItemSelected}
              className={checkedRow}
            >
              <TableCell padding="checkbox" onClick={(event) => handleClick(event, item)}>
                <Checkbox checked={isItemSelected} disabled={errCtx}/>
              </TableCell>
              <TableCell>{id.fpId}</TableCell>
              <TableCell>{name}</TableCell>
              <TableCell>{firmName}</TableCell>
              <TableCell className={textCenter}>
                <TablePopup content={errCtx || 'No data'} />
              </TableCell>
              <TableCell>
                {!errCd ? (
                  add2Queue ? (
                  <div className={`${merged} ${statusWidth}`}>Ready</div>
                ) : (
                  <div className={`${uploaded} ${statusWidth}`}>Waiting</div>
                ))
                  :(
                    <div className={`${error} ${statusWidth}`}>{errCd}</div>
                  )
                }
              </TableCell>
              <TableCell>
                <Link
                  to={{
                    pathname: `/attorneysList/${id.fpId}`,
                  }}
                >
                  <Button className={outlinedButton} fullWidth variant="outlined" color="primary">
                    VIEW
                  </Button>
                </Link>
              </TableCell>
            </TableRow>
          );
        })) || <TableNoData colSpan={7} />}
    </TableBody>
  );
};

CreatedCallsResultsTableBody.propTypes = {
  createdCallList: PropTypes.arrayOf(PropTypes.any),
  createdCallListSize: PropTypes.number,
  page: PropTypes.number,
  handleClick: PropTypes.func,
  isSelected: PropTypes.func,
};

CreatedCallsResultsTableBody.defaultProps = {
  createdCallList: [],
  createdCallListSize: 0,
  page: 0,
  handleClick: () => {},
  isSelected: () => {},
};

export default CreatedCallsResultsTableBody;
