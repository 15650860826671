import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useStyleHelpers from '../../../styles/styleHelpers';
import useStyles from '../../../styles/styles';
import { getAttorneyListByFirmName } from '../../../thunks/attorneyThunks';
import { useStableDispatch } from '../../../utils/hooks';

const AttorneyChips = ({ firmName }) => {
  const {
    subTitle,
    attorneyChip,
    boldSimpleString,
    smallSimpleString,
    outlinedButton,
    attorneyChipBorder,
  } = useStyles();
  const {
    padding,
    largestMarginLeft,
    upperText,
    mediumMarginRight,
    mediumButtonWidth,
    fullWidth,
    mediumMarginBottom,
    mediumMargin,
    marginAuto,
    block,
    boxShadow,
    smallMargin,
    mediumFormMarginRight,
  } = useStyleHelpers();

  const dispatch = useStableDispatch();
  const { firmAttorneyList, firmAttorneyListSize } = useSelector((store) => store.defaultReducer);

  const listIsemptyOrAttorneyIsInFirm =
    !firmAttorneyList || !firmAttorneyList[0] || firmName !== firmAttorneyList[0].firm;

  useEffect(() => {
    (async () => {
      if (listIsemptyOrAttorneyIsInFirm) {
        await dispatch(getAttorneyListByFirmName({ name: firmName }));
      }
    })();
  }, [dispatch, listIsemptyOrAttorneyIsInFirm, firmName]);

  return (
    <div className={`${padding} ${largestMarginLeft} ${mediumFormMarginRight}`}>
      <div className={`${subTitle} ${upperText} ${mediumMarginBottom}`}>
        {`${firmAttorneyListSize} `}
        Employees found
      </div>
      <Grid className={`${boxShadow} ${attorneyChipBorder}`} container>
        {!!firmAttorneyList &&
          !!firmAttorneyList.length &&
          firmAttorneyList.slice(0, 6).map(({ id, name, firm }, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid key={`Employee_${index}`} item xs={12} xl={4} lg={4} md={4}>
              <Chip
                icon={
                  <div className={fullWidth}>
                    <div>
                      <div className={boldSimpleString}>{name}</div>
                      <div className={smallSimpleString}>{firm}</div>
                    </div>
                    <div className={`${padding} ${smallMargin}`}>
                      <Link
                        to={{
                          pathname: `/attorneysList/${id}`,
                        }}
                      >
                        <Button
                          className={`${outlinedButton} ${mediumButtonWidth}`}
                          fullWidth
                          variant="outlined"
                          color="primary"
                        >
                          View
                        </Button>
                      </Link>
                    </div>
                  </div>
                }
                /* eslint-disable-next-line react/no-array-index-key */
                key={index}
                className={`${attorneyChip} ${mediumMarginRight} ${fullWidth}`}
              />
            </Grid>
          ))}
      </Grid>
      {!!firmAttorneyList && !!firmAttorneyList.length && firmAttorneyList.length > 6 && (
        <div className={`${mediumMargin}`}>
          <Link to={`/firmsList/${firmName}/employees`}>
            <Button
              variant="outlined"
              color="primary"
              className={`${outlinedButton} ${marginAuto} ${block}`}
            >
              View More
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
};

AttorneyChips.propTypes = {
  firmName: PropTypes.string,
};

AttorneyChips.defaultProps = {
  firmName: '',
};
export default AttorneyChips;
