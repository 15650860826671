import React from 'react';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import useStyles from '../../../styles/styles';
import { useSignInStyles } from './SignIn.styles.js';

const SignInForm = ({ onSubmit }) => {
  const { button, inputFocused } = useStyles();
  const { margin } = useSignInStyles();

  return (
    <Formik
      initialValues={{
        userName: '',
        password: '',
      }}
      onSubmit={onSubmit}
    >
      {() => (
        <Form>
          <Field
            className={inputFocused}
            variant="filled"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Username"
            name="userName"
            autoComplete="username"
            autoFocus
            component={TextField}
          />

          <Field
            className={inputFocused}
            variant="filled"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            component={TextField}
          />
          <Button
            className={`${button} ${margin}`}
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
          >
            Sign In
          </Button>
        </Form>
      )}
    </Formik>
  );
};

SignInForm.propTypes = {
  onSubmit: PropTypes.func,
};

SignInForm.defaultProps = {
  onSubmit: () => {},
};

export default SignInForm;
