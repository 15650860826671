import React from 'react';
import TableBody from '@material-ui/core/TableBody';
import { TableRow } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import Delete from '../../../../assets/icons/buttonIcons/Delete.svg';
import TableNoData from '../../../reusableComponents/TableNoData';

const FiltersList = ({ handleClickOpen, filterList, page, rowsPerPage, filterTypeList }) => {
  const formatType = (type) => {
    const filter = filterTypeList.find((item) => item.id === type);
    return filter.type;
  };

  return (
    <TableBody>
      {(!!filterList &&
        !!filterList.length &&
        filterList
          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          .map(({ id = '', name = '', type = '' }) => (
            <TableRow key={id}>
              <TableCell>{name}</TableCell>
              <TableCell>{formatType(type)}</TableCell>
              <TableCell />
              <TableCell>
                <IconButton
                  onClick={() => {
                    handleClickOpen(id);
                  }}
                  aria-label="delete"
                >
                  <img src={Delete} alt="delete" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))) || <TableNoData colSpan={3} />}
    </TableBody>
  );
};

FiltersList.propTypes = {
  handleClickOpen: PropTypes.func,
  filterList: PropTypes.arrayOf(PropTypes.object),
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  filterTypeList: PropTypes.arrayOf(PropTypes.object),
};

FiltersList.defaultProps = {
  handleClickOpen: () => {},
  filterList: [],
  page: 0,
  rowsPerPage: 0,
  filterTypeList: [],
};

export default FiltersList;
