import { Redirect, Route } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

const PrivateRoute = ({ component: Component, path, exact }) => {
  const { auth } = useSelector((store) => store.defaultReducer);

  return (
    <Route
      path={path}
      exact={exact}
      render={() => (auth ? <Component /> : <Redirect to="/signIn" />)}
    />
  );
};

export default PrivateRoute;

PrivateRoute.propTypes = {
  component: PropTypes.func,
  path: PropTypes.string,
  exact: PropTypes.bool,
};

PrivateRoute.defaultProps = {
  component: undefined,
  path: '',
  exact: false,
};
