import React from 'react';
import PropTypes from 'prop-types';
import { TableRow } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import { StylesProvider } from '@material-ui/styles';
import useStyleHelpers from '../../../styles/styleHelpers';
import useTableStyles from '../../../styles/tableStyles';

const TableHeader = ({ headers, checkbox }) => {
  const { whiteSpace } = useStyleHelpers();
  const { tableHeaderFontBold } = useTableStyles();

  return (
    <TableHead>
      <TableRow>
        {!!checkbox && checkbox}
        {headers &&
          headers.length &&
          headers.map((name) => (
            <StylesProvider key={name} injectFirst>
              <TableCell className={`${tableHeaderFontBold} ${whiteSpace}`}>{name}</TableCell>
            </StylesProvider>
          ))}
      </TableRow>
    </TableHead>
  );
};

TableHeader.propTypes = {
  checkbox: PropTypes.element,
  headers: PropTypes.arrayOf(PropTypes.string),
};

TableHeader.defaultProps = {
  checkbox: React.fragment,
  headers: [],
};

export default TableHeader;
