import React from 'react';
import PropTypes from 'prop-types';
import useMakeCallsStyles from '../../../styles/callsStyles.js';

const Stick = ({ title, value, color }) => {
  const {
    stick,
    stickShadow,
    stickTitle,
    stickNumber,
    redStick,
    redStickShadow,
    blackStick,
    blackStickShadow,
  } = useMakeCallsStyles();

  return (
    <div
      className={`${stick} ${(color === 'red' && redStick) || (color === 'black' && blackStick)}`}
    >
      <div className={stickTitle}>{title}</div>
      <div className={stickNumber}>{value}</div>
      <div
        className={`${stickShadow} ${
          (color === 'red' && redStickShadow) || (color === 'black' && blackStickShadow)
        }`}
      >
        {' '}
      </div>
    </div>
  );
};

Stick.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  color: PropTypes.oneOf(['red', 'black']),
};

Stick.defaultProps = {
  title: ' ',
  value: 0,
  color: 'red',
};

export default Stick;
