import { getRequest, uploadRequest } from '../services/fetchUtils';

export const getUploadedAttorneyFiles = () =>
  getRequest('/v1/files?type=ATT').then(({ files }) => files);

export const getExcludeJOBUploaded = () =>
  getRequest('/v1/files?excludeJOB=true').then(({ files }) => files);

export const uploadAttorneyList = (file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', 'ATT');
  return uploadRequest('/v1/file/upload', formData);
};

export const uploadFileWithType = (file, type) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('type', type);
  return uploadRequest('/v1/file/upload', formData);
};
