import React from 'react';
import { TableBody, TableRow } from '@material-ui/core';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';
import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import useStyles from '../../../styles/styles';
import TableNoData from '../../reusableComponents/TableNoData';
import TablePopup from '../JobSearchResults/TablePopup';
import useStyleHelpers from '../../../styles/styleHelpers';
import { toLocalTimeFormated } from '../../../utils/datesManagement.js';

const InterestedAttorneysResultsTableBody = ({
  interestedAttorneysList,
  page,
  interestedAttorneysCount,
}) => {
  const { outlinedButton } = useStyles();
  const { textCenter } = useStyleHelpers();

    return (
    <TableBody>
      {(!!interestedAttorneysCount &&
        !!interestedAttorneysList[page] &&
        interestedAttorneysList[page].map(
          ({ fpId, firmName, location, previousCallTime, note, userName, name }) => (
            <TableRow key={fpId}>
              <TableCell>{name}</TableCell>
              <TableCell>{firmName}</TableCell>
              <TableCell>{location}</TableCell>
              <TableCell>{userName || '-'}</TableCell>
              <TableCell>{toLocalTimeFormated(previousCallTime) || '-'}</TableCell>
              <TableCell className={textCenter}>
                <TablePopup content={note || '-'} />
              </TableCell>
              <TableCell>
                <Link
                  to={{
                    pathname: `/attorneysList/${fpId}`,
                  }}
                >
                  <Button className={outlinedButton} fullWidth variant="outlined" color="primary">
                    VIEW
                  </Button>
                </Link>
              </TableCell>
            </TableRow>
          )
        )) || <TableNoData colSpan={7} />}
    </TableBody>
  );
};
InterestedAttorneysResultsTableBody.propTypes = {
  interestedAttorneysList: PropTypes.arrayOf(PropTypes.any),
  page: PropTypes.number,
  interestedAttorneysCount: PropTypes.number,
};

InterestedAttorneysResultsTableBody.defaultProps = {
  interestedAttorneysList: [],
  page: 0,
  interestedAttorneysCount: 0,
};

export default InterestedAttorneysResultsTableBody;
