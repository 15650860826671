import {
  SET_FILTER_LIST_SIZE,
  SET_FILTER_LIST,
  SET_APP_PARAMS,
  SET_QUEUE_CONFIG,
  SET_CALL_ACTIONS,
  SET_CALL_RESULTS,
} from '../constants/reduxConstants.js';

export const setFilterList = (list) => ({
  type: SET_FILTER_LIST,
  list,
});

export const setAppParams = (list) => ({
  type: SET_APP_PARAMS,
  list,
});

export const setQueueConfigs = (list) => ({
  type: SET_QUEUE_CONFIG,
  list,
});

export const setCallActions = (list) => ({
  type: SET_CALL_ACTIONS,
  list,
});

export const setCallResults = (list) => ({
  type: SET_CALL_RESULTS,
  list,
});

export const setFilterListSize = (size) => ({ type: SET_FILTER_LIST_SIZE, size });
