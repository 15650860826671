import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useStyles from '../../../styles/styles.js';
import MainHeader from '../../reusableComponents/TableToolbar';
import LastCall from './LastCall.js';
import StatsInformation from './StatsInformation.js';
import TextFieldInformation from './TextFieldInformation.js';
import ActionsBlock from './ActionsBlock.js';
import NotesBlock from './NotesBlock.js';
import Chips from '../../reusableComponents/Profile/Chips';
import Description from '../../reusableComponents/Profile/Description';
import useStyleHelpers from '../../../styles/styleHelpers.js';
import ColdCallsPopUp from './ColdCallsPopUp';
import { useStableDispatch } from '../../../utils/hooks';
import { getCurrentCall, getQueueList } from '../../../thunks/callThunks';
import NoDataFound from '../../reusableComponents/NoDataFound';
import { saveCallRequest } from '../../../requests/callRequests';
import notification from '../../reusableComponents/notification';
import ChooseQueue from '../../reusableComponents/ChooseQueue';
import BackButton from '../../reusableComponents/BackButton';

const MakeCalls = () => {
  const { content } = useStyles();
  const { padding, chipMarginRight, marginLeftAuto } = useStyleHelpers();

  const { phase } = useParams();

  const [currentQueueName, setCurrentQueueName] = useState('');
  const [currentQueue, setCurrentQueue] = useState({});
  const [open, setOpen] = useState(false);
  const [noteValue, setNoteValue] = useState('');
  const [resultCode, setResultCode] = useState('');
  const [currentPhase] = useState(+phase || 1);
  const [isButtonsDisabled, setIsButtonsDisabled] = useState(false);

  const dispatch = useStableDispatch();
  const {
    user: { userId },
  } = useSelector((store) => store.defaultReducer);
  const { currentQueueList, currentCall } = useSelector((store) => store.callsReducer);

  const handleClickOpen = (code) => {
    setOpen(true);
    setResultCode(code);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNoteChange = ({ target }) => {
    setNoteValue(target.value);
  };

  const handleChange = async (event) => {
    setCurrentQueueName(event.target.value);
    const queue = currentQueueList.find(({ queueName }) => queueName === event.target.value);
    setCurrentQueue(queue);
    if (!queue) return;
    if ((currentQueueList.length && !currentCall) || currentCall.listId !== queue.id) {
      await dispatch(getCurrentCall(queue.id, userId, currentPhase));
    }
  };

  const onSaveCall = async (resultCd, dateTime = null) => {
    const body = {
      plogId: currentCall.id,
      pnewCallTime: dateTime,
      presultCd: resultCd,
      presultCommand: noteValue,
    };
    setIsButtonsDisabled(true);
    try {
      await saveCallRequest(body);
      notification({ message: 'The call was saved!', type: 'success' });
      setIsButtonsDisabled(false);
    } catch (e) {
      notification({ message: 'Something went wrong!', type: 'error' });   
      setIsButtonsDisabled(false);
    }
    await dispatch(getCurrentCall(currentQueue.id, userId, currentPhase));
    setNoteValue('');
  };

  useEffect(() => {
    (async () => {
      if (!userId) return;
      if (
        !currentQueueList.length ||
        currentQueueList[0].phaseNo !== currentPhase ||
        currentQueueList[0].userId !== userId
      ) {
        await dispatch(getQueueList(userId, currentPhase));
        return;
      }
      setCurrentQueueName(currentQueueList[0].queueName);
      setCurrentQueue(currentQueueList[0]);
      const queue = currentQueueList.find(
        ({ queueName }) => queueName === currentQueueList[0].queueName
      );
      if (!queue) return;
      await dispatch(getCurrentCall(queue.id, userId, currentPhase));
    })();
  }, [dispatch, userId, currentQueueList, currentPhase]);

  return (
    <main className={content}>
      <MainHeader
        prefix={<BackButton />}
        justify="flex-start"
        header="Make Calls"
        suffix={
          <div className={marginLeftAuto}>
            <ChooseQueue
              queueList={currentQueueList}
              currentQueueName={currentQueueName}
              handleChange={handleChange}
            />
          </div>
        }
      />
      <StatsInformation
        userId={userId}
        listId={currentQueue && currentQueue.id}
        attorney={currentCall && currentCall.attorney}
      />
      {!currentQueueList || !currentQueueList.length || !currentCall.attorney ? (
        <NoDataFound
          title={
            currentQueueName ? `The "${currentQueueName}" list is empty` : `You have no calls assigned`
          }
        />
      ) : (
        <>
          <LastCall name={currentCall.attorney.name} callLog={currentCall.callLog} />
          <div className={padding}>
            <Grid container spacing={3}>
              <Grid item lg={8} xl={8} className={chipMarginRight}>
                <TextFieldInformation attorney={currentCall.attorney} />
                <Chips
                  title="Primary Practice"
                  arrOfChips={
                    currentCall.attorney.primaryPractices &&
                    currentCall.attorney.primaryPractices.split(',')
                  }
                />
                <Chips
                  title="Primary Sector"
                  arrOfChips={
                    currentCall.attorney.primarySectors &&
                    currentCall.attorney.primarySectors.split(',')
                  }
                />
                <Chips
                  title="Specialties"
                  arrOfChips={
                    currentCall.attorney.specialties && currentCall.attorney.specialties.split(',')
                  }
                />
                <Description value={currentCall.attorney.fullBio} title="Biography" />
              </Grid>
              <Grid item lg={4} xl={4}>
                <ActionsBlock
                  handleClickOpen={handleClickOpen}
                  onSaveCall={onSaveCall}
                  phase={currentPhase}
                  isButtonsDisabled={isButtonsDisabled}
                />
                <NotesBlock
                  handleNoteChange={handleNoteChange}
                  noteValue={noteValue}
                  callLog={currentCall.callLog}
                />
              </Grid>
            </Grid>
          </div>
          <ColdCallsPopUp
            note={noteValue}
            attorneyName={currentCall.attorney.name}
            state={open}
            handleNoteChange={handleNoteChange}
            onCancelAction={handleClose}
            onConfirmAction={onSaveCall}
            resultCode={resultCode}
          />
        </>
      )}
    </main>
  );
};

export default MakeCalls;
