export const headers = ['Name', 'Firm name', 'Title', 'Location', ''];

export const printHeader = [
  { label: 'FP ID', key: 'fpId' },
  { label: 'Firm name', key: 'firmName' },
  { label: 'Job location', key: 'location' },
  { label: 'Job title', key: 'jobTitle' },
  { label: 'Title', key: 'title' },
  { label: 'Description', key: 'jobDesc' },
];
