import { makeStyles } from '@material-ui/core/styles';
import theme from './theme';

const useStyles = makeStyles(
  (material) => ({
    button: {
      background: theme.palette.main,

      '&:hover': { background: theme.palette.mainLight },
      fontFamily: 'PT Sans',
      fonWeight: 'bold',
    },
    outlinedButton: {
      color: theme.palette.main,

      borderColor: theme.palette.gray,
      fontFamily: 'PT Sans',
      fontWeight: 'bold',
      '&:hover': { color: theme.palette.main, borderColor: theme.palette.gray },
      '& MuiTouchRipple-root': { color: theme.palette.main, borderColor: theme.palette.gray },
    },
    linkButton: {
      color: theme.palette.main,
      '&:hover': { background: theme.palette.pink },
      fontFamily: 'PT Sans',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: 18,
    },
    buttonHeight: { height: 36 },
    uploadInput: {
      display: 'none',
    },
    buttonFocused: {
      '&:focus': { background: 'rgba(214, 27, 12, 0.28)' },
    },
    inputFocused: {
      '& label.Mui-focused': {
        color: theme.palette.main,
      },
      '& .MuiFilledInput-underline:after': {
        borderBottomColor: theme.palette.main,
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: theme.palette.main,
      },
    },
    inputReadOnly: {
      '& .MuiInput-underline:after': {
        borderBottom: 'none',
      },
    },
    tabPanel: {
      '& span.MuiTabs-indicator': {
        backgroundColor: theme.palette.main,
      },
      '& button': {
        fontFamily: 'PT Sans',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 18,
        opacity: 1,
        color: theme.palette.black,
      },
      '& button:hover': {
        color: theme.palette.main,
      },
      '& button$selected, & button:focus, & .Mui-selected': {
        color: theme.palette.main,
        background: '#FAE3E1',
      },
    },
    checkedIcon: {
      '& .MuiCheckbox-colorSecondary.Mui-checked': {
        color: theme.palette.main,
        backgroundImage: theme.palette.main,
      },
    },
    spin: {
      color: theme.palette.main,
      top: '50%',
      left: '50%',
      position: 'absolute',
    },
    backdrop: {
      zIndex: material.zIndex.drawer + 1,
      color: theme.palette.white,
    },
    menu: {
      background: `${theme.background.gradient}, ${theme.background.image} no-repeat`,
      backgroundSize: 'cover',
      width: 300,
      height: '100vh',
      '& .MuiListItemIcon-root': {
        opacity: '0.5',
      },
      overflowX: 'auto',
      '& .MuiListItem-root:focus, & .highlight': {
        '& .MuiListItemIcon-root, & .MuiListItemText-root': {
          opacity: 1,
        },
      },
    },
    menuItem: {
      color: theme.palette.white,
      textTransform: 'uppercase',
      opacity: '0.5',
      '& span': { fontFamily: "'PT Sans', sans-serif" },
    },
    nested: {
      paddingLeft: theme.spacing[8],
    },
    menuLogo: { display: 'flex', margin: 'auto', padding: theme.spacing[4] },
    layout: { display: 'flex' },
    title: {
      fontWeight: 'bold',
      fontSize: '24px',
      lineHeight: '31px',
      textTransform: 'uppercase',
      color: theme.palette.black,
    },
    subTitle: {
      fontFamily: 'PT Sans',
      fontSize: '24px',
      color: theme.palette.black,
    },
    smallTitle: {
      fontFamily: 'PT Sans Bold',
      fontWeight: 'bold',
      fontSize: '18px',
      lineHeight: '23px',
      textTransform: 'capitalize',
      color: theme.palette.black,
    },
    grayTitle: {
      fontFamily: 'PT Sans',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '18px',
      textTransform: 'uppercase',
      color: theme.palette.gray,
    },
    errorTitle: {
      fontFamily: 'PT Sans',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '18px',
      textTransform: 'uppercase',
      color: theme.palette.stickRed,
    },
    blackTittle: {
      fontFamily: 'PT Sans',
      fontStyle: 'normal',
      fontSize: '18px',
      color: theme.palette.black,
    },
    simpleString: {
      fontFamily: 'PT Sans',
      fontSize: 16,
      color: theme.palette.black,
    },
    dialogTitle: {
      '&, &>h2': {
        fontFamily: 'PT Sans',
        fontSize: 16,
        fontWeight: 'bold',
        textTransform: 'uppercase',
      },
    },
    smallSimpleString: {
      fontFamily: 'PT Sans',
      fontSize: 14,
      color: theme.palette.black,
    },
    boldSimpleString: {
      fontFamily: 'PT Sans Bold',
      fontSize: 16,
      color: theme.palette.black,
    },
    grayString: {
      fontFamily: 'PT Sans',
      fontSize: 16,
      color: theme.palette.gray,
    },
    content: {
      flexGrow: 1,
      height: '100vh',
      width: 'calc(100vw - 300px)',
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
    toolbar: ({ justifyContent }) => ({
      display: 'flex',
      justifyContent,
      marginTop: theme.spacing[4],
    }),
    paper: { margin: theme.spacing[4] },
    contentLayout: {
      margin: theme.spacing[4],
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'start',
    },
    rotateImg: { '& img': { transform: 'rotate(180deg)' } },
    chip: { borderRadius: 4 },
    attorneyChip: {
      '&': {
        height: 'fit-content',
        background: 'none',
        borderRadius: '0',
      },
      '& .MuiChip-icon': {
        display: 'flex',
        padding: '10px',
        justifyContent: 'space-between',
      },
      '& .MuiChip-label': {
        padding: '0',
      },
    },
    attorneyChipBorder: {
      '&': { borderRadius: 4 },
      '&>div': {
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.15)',
        borderRadius: 2,
      },
    },
  }),
  { index: 1 }
);

export default useStyles;
