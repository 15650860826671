export const SET_USER = 'SET_USER';

export const SET_AUTH = 'SET_AUTH';

export const SET_FIRM_LIST = 'SET_FIRM_LIST';

export const SET_FIRM_LIST_SIZE = 'SET_FIRM_LIST_SIZE';

export const SET_ATTORNEY_LIST = 'SET_ATTORNEY_LIST';

export const SET_ATTORNEY_LIST_SIZE = 'SET_ATTORNEY_LIST_SIZE';

export const SET_JOB_SEARCH_CONFIG = 'SET_JOB_SEARCH_CONFIG';

export const SET_ATTORNEY_SEARCH_CONFIG = 'SET_ATTORNEY_SEARCH_CONFIG';

export const SET_APP_PARAMS = 'SET_APP_PARAMS';

export const SET_QUEUE_CONFIG = 'SET_QUEUE_CONFIG';

export const SET_ATTORNEY_FILTERS_LIST = 'SET_ATTORNEY_FILTERS_LIST';

export const SET_ATTORNEY_PROFILE = 'SET_ATTORNEY_PROFILE';

export const SET_JOB_FILTERS_LIST = 'SET_JOB_FILTERS_LIST';

export const SET_ACCOUNT_LIST = 'SET_ACCOUNT_LIST';

export const SET_ROLE_LIST = 'SET_ROLE_LIST';

export const SET_FILTER_LIST = 'SET_FILTER_LIST';

export const SET_FILTER_LIST_SIZE = 'SET_FILTER_LIST_SIZE';

export const SET_TEMPLS_LIST = 'SET_TEMPLS_LIST';

export const SET_LOAD_ERROR_LIST = 'SET_LOAD_ERROR_LIST';

export const SET_FILTER_NAME = 'SET_FILTER_NAME';

export const SET_JOB_FILTER_YEARS = 'SET_JOB_FILTER_YEARS';

export const SET_FIRM_ATTORNEY_LIST = 'SET_FIRM_ATTORNEY_LIST';

export const SET_FIRM_ATTORNEY_LIST_SIZE = 'SET_FIRM_ATTORNEY_LIST_SIZE';

export const SET_FIRM_PROFILE = 'SET_FIRM_PROFILE';

export const SET_CALL_ACTIONS = 'SET_CALL_ACTIONS';

export const SET_CALL_RESULTS = 'SET_CALL_RESULTS';

export const SET_FIRMS_TOTAL_LIST = 'SET_FIRMS_TOTAL_LIST';

export const SET_CURRENT_QUEUE_LIST = 'SET_CURRENT_QUEUE_LIST';

export const SET_CURRENT_CALL = 'SET_CURRENT_CALL';

export const SET_CALLS_PER_DAY = 'SET_CALLS_PER_DAY';

export const SET_CALLS_PER_WEEK = 'SET_CALLS_PER_WEEK';

export const SET_CALLS_TO_ATTORNEY = 'SET_CALLS_TO_ATTORNEY';

export const SET_LEFT_CALLS = 'SET_LEFT_CALLS';

export const SET_INTERESTED_ATTORNEYS_COUNT = 'SET_INTERESTED_ATTORNEYS_COUNT';

export const SET_SEARCH_INTERESTED_ATTORNEYS_COUNT = 'SET_SEARCH_INTERESTED_ATTORNEYS_COUNT';

export const SET_INTERESTED_ATTORNEYS_LIST = 'SET_INTERESTED_ATTORNEYS_LIST';

export const SET_CALL_LIST_STATS = 'SET_CALL_LIST_STATS';

export const SET_CREATED_CALL_LIST = 'SET_CREATED_CALL_LIST';

export const SET_CREATED_CALL_LIST_SIZE = 'SET_CREATED_CALL_LIST_SIZE';

export const SET_QUEUE_LISTS = 'SET_QUEUE_LISTS';

export const SET_ATTORNEYS_READY_TO_WORK = 'SET_ATTORNEYS_READY_TO_WORK';

export const SET_ATTORNEYS_READY_TO_WORK_SIZE = 'SET_ATTORNEYS_READY_TO_WORK_SIZE';

export const SET_ATTORNEY_CALLS_LOG = 'SET_ATTORNEY_CALLS_LOG';

export const SET_NEXT_CALL = 'SET_NEXT_CALL';

export const SET_CURRENT_ACCOUNT = 'SET_CURRENT_ACCOUNT';

export const SET_COLD_CALLERS = 'SET_COLD_CALLERS'

export const SET_CALLS_COUNT_IN_QUEUE = 'SET_CALLS_COUNT_IN_QUEUE'

export const SET_CALLS_COUNT_ACTIVE = 'SET_CALLS_COUNT_ACTIVE'

export const SET_CALLS_COUNT_DORMANT = 'SET_CALLS_DORMANT'

export const SET_CALLS_COUNT_DORMANT_BY_IDLE = 'SET_CALLS_COUNT_DORMANT_BY_IDLE'