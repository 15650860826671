import { deleteRequest, getRequest, postRequest, putRequest } from '../services/fetchUtils';

export const getAccountRequest = () => {
  return getRequest(`/users`);
};

export const addAccountRequest = (account) => {
  return postRequest(`/users`, account);
};

export const updateAccountRequest = (account, token) => {
  return putRequest(`/users?token=${token}`, account);
};

export const deleteAccountRequest = (id) => {
  return deleteRequest(`/users?id=${id}`);
};
