import React from 'react';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import useSearchStyles from '../../../styles/searchStyles.js';
import useStyles from '../../../styles/styles.js';

const LeftSideSection = ({ lessThan, moreThan, onLessThanChange, onMoreThanChange }) => {
  const {
    bottomSectionLeftNested,
    bottomSectionLeftNestedString,
    bottomSectionLeftNestedP,
  } = useSearchStyles();

  const { simpleString, inputFocused } = useStyles();
  return (
    <div className={bottomSectionLeftNested}>
      <div className={bottomSectionLeftNestedString}>
        <div className={`${bottomSectionLeftNestedP} ${simpleString}`}>
          Only include firms with more than
        </div>
        <TextField
          id="standard-number"
          type="number"
          variant="filled"
          inputProps={{ min: 0 }}
          value={moreThan}
          onChange={(event) => {
            onMoreThanChange(event);
          }}
          className={inputFocused}
        />
        <div className={simpleString}>results.</div>
      </div>
      <div className={bottomSectionLeftNestedString}>
        <div className={`${bottomSectionLeftNestedP} ${simpleString}`}>
          Only include firms with less than
        </div>
        <TextField
          id="standard-number"
          type="number"
          variant="filled"
          inputProps={{ min: 0 }}
          className={inputFocused}
          value={lessThan}
          onChange={(event) => {
            onLessThanChange(event);
          }}
        />
        <div className={simpleString}>results.</div>
      </div>
    </div>
  );
};

LeftSideSection.propTypes = {
  lessThan: PropTypes.string,
  moreThan: PropTypes.string,
  onLessThanChange: PropTypes.func,
  onMoreThanChange: PropTypes.func,
};

LeftSideSection.defaultProps = {
  lessThan: '',
  moreThan: '',
  onLessThanChange: () => {},
  onMoreThanChange: () => {},
};

export default LeftSideSection;
