import React from 'react';
import PropTypes from 'prop-types';
import useStyles from '../../../../styles/styles.js';
import useStyleHelpers from '../../../../styles/styleHelpers.js';

const Description = ({ value, title }) => {
  const { simpleString, smallTitle } = useStyles();
  const { mediumMargin } = useStyleHelpers();

  return (
    <div>
      <div className={`${smallTitle} ${mediumMargin}`}>{title}</div>
      <div className={`${simpleString}`}>{value}</div>
    </div>
  );
};

Description.propTypes = {
  value: PropTypes.string,
  title: PropTypes.string,
};

Description.defaultProps = {
  value: 'No content...',
  title: '',
};

export default Description;
