import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import PropTypes from 'prop-types';
import useLoaderStyles from './Loader.styles.js';

const Loader = ({ loading, children }) => {
  const { loader } = useLoaderStyles();

  return <>{loading ? <CircularProgress className={loader} /> : children}</>;
};

Loader.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.element,
};

Loader.defaultProps = {
  loading: false,
  children: undefined,
};

export default Loader;
