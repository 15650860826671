import {
  addAccountRequest,
  deleteAccountRequest,
  getAccountRequest,
  updateAccountRequest,
} from '../requests/accountRequests';
import { setAccountList, setCurrentAccount } from '../actions/accountActions';

export const getAccountList = (userId) => {
  return async (dispatch) => {
    const { usersList } = await getAccountRequest();
    if (!usersList) return;
    if (userId) {
      const currentAccount = usersList.find((item) => item.userId === userId);
      dispatch(setCurrentAccount(currentAccount));
    }
    dispatch(setAccountList(usersList));
  };
};

export const addAccount = (account) => {
  return async (dispatch, getState) => {
    const {
      defaultReducer: { accountList },
    } = getState();
    const response = await addAccountRequest(account);
    dispatch(setAccountList([account, ...accountList]));
    return response;
  };
};

export const updateAccount = (body, token) => {
  return async (dispatch, getState) => {
    const {
      defaultReducer: { accountList },
    } = getState();

    const account = { ...body };
    account.confirmationToken = token;

    const newList = accountList.map((item) => {
      return item.confirmationToken === token ? account : item;
    });
    dispatch(setAccountList(newList));
    await updateAccountRequest(body, token);
  };
};

export const deleteAccount = (id) => {
  return async (dispatch, getState) => {
    const {
      defaultReducer: { accountList },
    } = getState();

    const resp = await deleteAccountRequest(id);
    if (resp.status !== 204) {
      throw new Error('Something went wrong');
    }
    const newList = accountList.filter((item) => {
      return item.userId !== id;
    });
    dispatch(setAccountList(newList));
  };
};
