import React, { useRef, useState } from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Search from '../Search';
import useStyles from '../../../styles/styles';
import Upload from '../../../assets/icons/buttonIcons/Upload.svg';
import Dropdown from '../../../assets/icons/buttonIcons/Dropdown.svg';

const MainHeader = ({
  header,
  searchPlaceholder,
  onUpload,
  onDropDownUpload,
  dropDownList,
  onSearch,
  onSearchSubmitted,
  exportButton,
  exportButtonSecondary,
  totalButton,
  prefix,
  suffix,
  justify,
}) => {
  const { title, toolbar, uploadInput, linkButton, buttonFocused } = useStyles({
    justifyContent: justify,
  });
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    prevOpen.current = open;
  }, [open]);

  return (
    <div>
      <Toolbar className={toolbar}>
        {!!prefix && prefix}
        <Typography className={title} variant="h6" id="tableTitle" component="div">
          {header}
        </Typography>
        {!!onUpload && (
          <>
            <input
              type="file"
              accept=".xlsx, .xls "
              id="contained-button-file"
              className={uploadInput}
              onChange={onUpload}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="contained-button-file">
              <Button
                className={linkButton}
                component="span"
                id="contained-button-file"
                startIcon={<img src={Upload} alt="upload" />}
              >
                Upload
              </Button>
            </label>
          </>
        )}

        {!!onDropDownUpload && (
          <div>
            <Button
              className={`${linkButton} ${buttonFocused}`}
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
              startIcon={<img src={Upload} alt="upload" />}
              endIcon={<img src={Dropdown} alt="dropDown" />}
            >
              Upload file
            </Button>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  /* eslint-disable-next-line react/jsx-props-no-spreading */
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        {!!dropDownList &&
                          !!dropDownList.length &&
                          dropDownList.map(({ templCd, comm }) => (
                            <div key={templCd}>
                              <input
                                type="file"
                                accept=".xlsx, .xls "
                                id={`menu-list-item${templCd}`}
                                className={uploadInput}
                                onChange={(event) => {
                                  handleClose();
                                  return onDropDownUpload(event, templCd);
                                }}
                              />
                              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                              <label htmlFor={`menu-list-item${templCd}`}>
                                <MenuItem id={`menu-list-item${templCd}`}>{comm}</MenuItem>
                              </label>
                            </div>
                          ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        )}

        {!!exportButton && exportButton}
        {!!exportButtonSecondary && exportButtonSecondary}
        {!!totalButton && totalButton}
        {!!searchPlaceholder && (
          <Search
            name={searchPlaceholder}
            onChange={onSearch}
            onSearchSubmitted={onSearchSubmitted}
          />
        )}
        {!!suffix && suffix}
      </Toolbar>
    </div>
  );
};

MainHeader.propTypes = {
  header: PropTypes.string,
  searchPlaceholder: PropTypes.string,
  onUpload: PropTypes.func,
  onDropDownUpload: PropTypes.func,
  dropDownList: PropTypes.arrayOf(PropTypes.object),
  onSearch: PropTypes.func,
  onSearchSubmitted: PropTypes.func,
  exportButton: PropTypes.element,
  totalButton: PropTypes.element,
  prefix: PropTypes.element,
  suffix: PropTypes.element,
  justify: PropTypes.string,
};

MainHeader.defaultProps = {
  header: '',
  searchPlaceholder: '',
  onUpload: undefined,
  onDropDownUpload: undefined,
  dropDownList: [],
  onSearch: undefined,
  onSearchSubmitted: undefined,
  exportButton: React.fragment,
  totalButton: React.fragment,
  prefix: React.fragment,
  suffix: React.fragment,
  justify: 'space-between',
};

export default MainHeader;
