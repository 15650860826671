import React, { useEffect, useState } from 'react';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import { useHistory } from 'react-router-dom';
import MainHeader from '../../reusableComponents/TableToolbar';
import useStyles from '../../../styles/styles.js';
import MyTablePagination from '../../reusableComponents/MyTablePagination';
import TableHeader from '../../reusableComponents/TableHeader';
import { getJobSearch } from '../../../requests/jobRequests.js';
import { headers } from './JobSearchResults.utils.js';
import ExportToXlsx from '../../reusableComponents/ExportToXlsx';
import TableLoader from '../../reusableComponents/TableLoader';
import ResultsTableBody from './ResultsTableBody.js';
import BackButton from '../../reusableComponents/BackButton';
import useStyleHelpers from '../../../styles/styleHelpers';
import { numberToExcelLetters, addExcelComment, jobsExcelTitles } from '../AttorneysSearchResults/ExcelTitles';
import * as moment from 'moment';

const JobSearchResults = () => {
  const { content, paper } = useStyles();
  const { marginAuto } = useStyleHelpers();
  const [list, setList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setLoading(true);
    getJobSearch(history.location.state.search)
      .then((newList) => {
        setList(newList);
        setFilteredList(newList);
      })
      .finally(() => setLoading(false));
  }, [history.location.state.search]);

  const onSearch = (value) => {
    setFilteredList(
      list.filter(({ firmName }) =>
        value ? firmName && firmName.toLowerCase().includes(value.toLowerCase()) : true
      )
    );
    setPage(0);
  };
  const onBeatifyJobsXlsx = (Sheets, SheetNames) => {
    jobsExcelTitles.forEach( (item, i) => {
      if (item.comment){
        let cell = numberToExcelLetters(i)+"1";
        addExcelComment(Sheets["Current Jobs"][cell], item.comment)
      }
    });
  }
  return (
    <main className={content}>
      <MainHeader
        header="jobs result list"
        searchPlaceholder="Search by firm name"
        onSearch={onSearch}
        prefix={<BackButton />}
        justify="flex-start"
        exportButton={
          <div className={marginAuto}>
            <ExportToXlsx data={filteredList} 
            beautifyXlsx={onBeatifyJobsXlsx}
            sheetName="Current Jobs"
            titles={jobsExcelTitles.map(t => t.label)}
            fileName={'CurrentJobs_'+ moment().format('L')}
            />
          </div>
        }
      />

      <div className={paper}>
        <TableContainer component={Paper}>
          <Table>
            <TableHeader headers={headers} />
            {loading ? (
              <TableLoader rowsPerPage={rowsPerPage} cols={5} />
            ) : (
              <ResultsTableBody filteredList={filteredList} page={page} rowsPerPage={rowsPerPage} />
            )}
            {!!filteredList && !!filteredList.length && (
              <MyTablePagination
                colSpan={6}
                count={filteredList.length}
                page={page}
                rowsPerPage={rowsPerPage}
                onChangePage={setPage}
                onChangeRowsPerPage={(newRowPerPage) => {
                  setRowsPerPage(newRowPerPage);
                  setPage(0);
                }}
              />
            )}
          </Table>
        </TableContainer>
      </div>
    </main>
  );
};

export default JobSearchResults;
