import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@material-ui/core/Chip';
import useStyles from '../../../../styles/styles.js';
import useStyleHelpers from '../../../../styles/styleHelpers.js';

const Chips = ({ title, arrOfChips }) => {
  const { chip, smallTitle } = useStyles();
  const { mediumMargin, smallMargin, mediumMarginRight } = useStyleHelpers();

  return (
    <>
      <div className={`${smallTitle} ${mediumMargin}`}>{title}</div>
      {!!arrOfChips &&
        !!arrOfChips.length &&
        arrOfChips.map((item, index) => (
          <Chip
            variant="outlined"
            /* eslint-disable-next-line react/no-array-index-key */
            key={index}
            label={item}
            className={`${chip} ${smallMargin} ${mediumMarginRight}`}
          />
        ))}
    </>
  );
};

Chips.propTypes = {
  title: PropTypes.string,
  arrOfChips: PropTypes.arrayOf(PropTypes.string),
};

Chips.defaultProps = {
  title: '',
  arrOfChips: [],
};

export default Chips;
