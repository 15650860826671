import { getLoadErrorRequestById } from '../requests/loadErrorRequests';
import { setLoadErrorList } from '../actions/loadErrorActions';

// eslint-disable-next-line import/prefer-default-export
export const getLoadErrorListById = (id) => {
  return async (dispatch) => {
    const { loadErrorList } = await getLoadErrorRequestById(id);
    dispatch(setLoadErrorList(loadErrorList));
  };
};
