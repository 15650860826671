import {
  setJobFiltersList,
  setJobFilterYears,
  setJobSearchConfig,
  setFilterName,
} from '../actions/jobActions.js';
import {
  getJobFilterByName,
  getJobFilters,
  getJobSearchCriteria,
  saveJobFilterRequest,
} from '../requests/jobRequests.js';

export const getSearchConfig = () => {
  return async (dispatch) => {
    const searchCriteria = await getJobSearchCriteria();
    dispatch(setJobSearchConfig(searchCriteria));
  };
};

export const getSavedJobFiltersList = () => {
  return async (dispatch) => {
    const filters = await getJobFilters();
    dispatch(setJobFiltersList(filters));
  };
};

export const getSavedJobFilter = (name = '', years = null) => {
  return async (dispatch) => {
    const filter = await getJobFilterByName(name);
    dispatch(setJobSearchConfig(filter));
    dispatch(setFilterName(name));
    dispatch(setJobFilterYears(years));
  };
};

export const saveJobFilter = ({ name, jobYears, filterConfig, condition }) => {
  return async (dispatch, getState) => {
    const {
      defaultReducer: { jobFilterList },
    } = getState();

    await saveJobFilterRequest({ filterConfig, name, jobYears, condition });

    const newList = jobFilterList ? [{ name, jobYears }, ...jobFilterList] : [{ name, jobYears }];

    dispatch(setJobFiltersList(newList));
  };
};
