import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useStyles from '../../../styles/styles.js';
import useMakeCallsStyles from '../../../styles/callsStyles.js';
import useStyleHelpers from '../../../styles/styleHelpers.js';
import { toLocalTimeFormated } from '../../../utils/datesManagement.js';

const LastCall = ({ name, callLog }) => {
  const { title, blackTittle } = useStyles();
  const { simpleRow } = useMakeCallsStyles();
  const { padding } = useStyleHelpers();
  const { lastCallMargin } = useMakeCallsStyles();

  return (
    <div className={`${simpleRow} ${padding} ${lastCallMargin}`}>
      <div className={title}>{name}</div>
      <div className={`${blackTittle} ${padding}`}>
        <strong>Last Call Attempt:</strong>
        {callLog && callLog.callTime ? ` ${toLocalTimeFormated(callLog.callTime)}` : ' -'}
      </div>
      <div className={`${blackTittle} ${padding}`}>
        <strong>Last Short Note:</strong>
        {callLog && callLog.resultName ? ` ${callLog.resultName}` : ' -'}
      </div>
    </div>
  );
};

LastCall.propTypes = {
  name: PropTypes.string,
  callLog: PropTypes.objectOf(PropTypes.any),
};

LastCall.defaultProps = {
  name: '',
  callLog: {},
};

export default LastCall;
