import { sortBy } from 'lodash';
import {
  setAppParams,
  setCallActions,
  setCallResults,
  setFilterList,
  setQueueConfigs,
} from '../actions/settingsActions';
import {
  deleteFilterRequest,
  getAppParamsRequest,
  getCallActionsRequest,
  getCallResultsRequest,
  getFiltersRequest,
  getQueueConfigsRequest,
  updateAppParamRequest,
  updateQueueConfigRequest,
} from '../requests/settingsRequests';

export const getFilterList = () => {
  return async (dispatch) => {
    const { filters } = await getFiltersRequest();
    dispatch(setFilterList(filters));
  };
};

export const getAppParamsList = () => {
  return async (dispatch) => {
    const { appParams } = await getAppParamsRequest();
    dispatch(setAppParams(appParams));
  };
};

export const getQueueConfigsList = () => {
  return async (dispatch) => {
    const { queueConfigs } = await getQueueConfigsRequest();
    const sortedQueueConfigs = sortBy(queueConfigs, (item) => [item.id.phaseNo, item.id.attemptNo]);
    dispatch(setQueueConfigs(sortedQueueConfigs));
  };
};

export const getCallActions = () => {
  return async (dispatch) => {
    const { callActions } = await getCallActionsRequest();
    dispatch(setCallActions(callActions));
  };
};

export const getCallResults = () => {
  return async (dispatch) => {
    const { callResults } = await getCallResultsRequest();
    dispatch(setCallResults(callResults));
  };
};

export const updateAppParam = (body) => {
  return async (dispatch, getState) => {
    const {
      defaultReducer: { appParams },
    } = getState();
    const newList = appParams.map((item) => (item.paramCode === body.paramCode ? body : item));
    dispatch(setAppParams(newList));
    await updateAppParamRequest(body);
  };
};

export const updateQueueConfig = (body) => {
  return async (dispatch, getState) => {
    const {
      defaultReducer: { queueConfigs },
    } = getState();
    const newList = queueConfigs.map((item) => (item.id === body.id ? body : item));
    dispatch(setQueueConfigs(newList));
    await updateQueueConfigRequest(body);
  };
};

export const deleteFilter = (id) => {
  return async (dispatch, getState) => {
    const {
      defaultReducer: { filterList },
    } = getState();

    const newList = filterList.filter((item) => {
      return item.id !== id;
    });
    dispatch(setFilterList(newList));
    await deleteFilterRequest(id);
  };
};
