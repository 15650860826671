import styled from 'styled-components/macro';
import { Avatar } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import theme from '../../../styles/theme';
import background from '../../../assets/background.png';
import PTSansBold from '../../../assets/fonts/PTSans-Bold.ttf';
import logo from '../../../assets/truenorth-logo.svg';

export const StyledPaper = styled.div`
  padding: ${theme.spacing[8]}px ${theme.spacing[2]}px ${theme.spacing[2]}px ${theme.spacing[2]}px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledAvatar = styled(Avatar)`
  margin: ${theme.spacing[1]}px;
  background-color: ${theme.palette.main};
`;

export const StyledForm = styled.form`
  width: 100%;
  margin-top: ${theme.spacing[1]}px;
`;

export const StyledBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;

  background: ${theme.background.gradient}, url(${background}) no-repeat;
  background-size: cover;
`;

export const StyledContainer = styled(Container)`
  height: 100%;
  background: ${theme.palette.white};
`;

export const StyledLogo = styled.div`
  width: 100%;
  height: 172px;
  background: url(${logo}) no-repeat center;
`;

export const StyledTypography = styled.div`
  color: ${theme.palette.main};
  font-family: src(${PTSansBold});
  font-size: 36px;
  line-height: 47px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
`;

export const useSignInStyles = makeStyles({ margin: { margin: '5vh 0' } });
