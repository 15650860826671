import React from 'react';
import 'fontsource-roboto';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import routes from './routes';
import PrivateRoute from './components/PrivateRoute';
import './index.css';
import Menu from './components/main/Menu';
import AuthWrapper from './components/AuthWrapper';

function App() {
  return (
    <Router>
      <AuthWrapper>
        <Menu />
        <Switch>
          {routes.map(({ auth, component, path, exact }, index) => {
            return auth ? (
              // eslint-disable-next-line react/no-array-index-key
              <PrivateRoute key={index} component={component} path={path} exact={exact} />
            ) : (
              // eslint-disable-next-line react/no-array-index-key
              <Route key={index} component={component} />
            );
          })}
        </Switch>
      </AuthWrapper>
    </Router>
  );
}

export default App;
