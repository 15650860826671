import background from '../assets/background.png';

const theme = {
  spacing: [2, 4, 8, 16, 24, 32, 40, 48, 56, 72, 96],
  palette: {
    main: '#D61B0C',
    mainLight: '#FB2311',
    pink: '#F6CFCB',
    lightPink: '#FFE5E3',
    white: '#fefefe',
    black: '#131313',
    gray: 'rgba(19, 19, 19, 0.3)',
    success: 'rgba(13, 214, 96, 0.3)',
    pending: 'rgba(214, 184, 34, 0.3)',
    fail: 'rgba(214, 27, 12, 0.3)',
    stickRed: '#E85348',
    stickBlack: '#3C3D3C',
    statusBar: {
      error: ' #FFC4C4',
      uploaded: ' #FFE9B4',
      loaded: ' #FFF9C4',
      converted: ' #C0F0FF',
      ready: ' #CDFBB3',
    },
  },
  background: {
    gradient: 'linear-gradient(0deg, rgba(39, 41, 38, 0.85), rgba(39, 41, 38, 0.85))',
    image: `url(${background})`,
  },
};

export default theme;
